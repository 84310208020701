import { Component, OnInit } from '@angular/core';
import { MdnModalRef } from 'src/app/shared/mondrian-components/mdn-modal/mdn-modal-ref';
import { MdnModalConfig } from 'src/app/shared/mondrian-components/mdn-modal/mdn-modal-config';
import { UtilsService } from '../../../../services/utils.service';
import { MdnModalService } from 'src/app/shared/mondrian-components/mdn-modal/mdn-modal.service';
import { IdentificacaoMovelService } from 'src/app/services/monte-sua-combinacao/modals/identificacao-movel.service';
import { Router } from '@angular/router';
import { CarrinhoService } from 'src/app/services/carrinho.service';
import { ConfiraOQueGanhouComponent } from 'src/app/shared/dialog/confira-o-que-ganhou/confira-o-que-ganhou.component';
import { MobileClientValidationService } from 'src/app/shared/dialog/mobile-client-authenticator/service/mobile-client-validation/mobile-client-validation.service';

@Component({
  selector: 'app-ja-e-cliente',
  templateUrl: './ja-e-cliente.component.html',
  styleUrls: ['./ja-e-cliente.component.scss']
})
export class JaEClienteComponent implements OnInit {
  cookieRedirect: any = {};
  urlBase: string = 'https://minhaclaroresidencial.claro.com.br/mude-seu-plano/';
  windows: any = window;
  gtmCategory: string;
  public titulo: string;
  public keyToGetInStorage: string[];
  flow: string;

  constructor(
    public config: MdnModalConfig,
    public dialog: MdnModalRef,
    public modal: MdnModalService,
    public utils: UtilsService,
    public identificacaoMovelService: IdentificacaoMovelService,
    public router: Router,
    public carrinho: CarrinhoService,
    public mobileClientValidationService: MobileClientValidationService
  ) {
    this.keyToGetInStorage = [
      'celularId',
      'internetId',
      'foneId',
      'tvId',
      'additionalIds',
      'subprodutosIds'
    ];
  }

  ngOnInit(): void {
    this.titulo = this.config.data?.titulo;
    this.gtmCategory = this.utils.getGtmCategory();
    this.createBaseURL();
    this.resolveFlow();
  }

  public fecharDialog(): void {
    sessionStorage.setItem('outro-endereco@JaSouCliente', 'true');
    this.dialog.close();

    if (this.flow === 'default') {
      if (this.config.data.origin === 'cliente-movel') {
        if (this.config.data.modalAction !== 'validates-plan-addition') {
          if (this.carrinho.updatedMobileCustomer === true) {
            this.utils.storageSetItem('ModalConfiraOQueGanhouShowed', 'true');

            const mobile = this.carrinho.getCart()?.selection?.celular;

            this.modal.open(ConfiraOQueGanhouComponent, {
              data: {
                size: 'lg',
                titulo: 'Identificação do cliente',
                sunWaterMark: true,
                planName: mobile?.nome || '',
                planValue: mobile?.preco || '',
              },
            });
          } else {
            this.identificacaoMovelService.openBanner();
          }

        } else {
          if (this.router.url.includes('/checkout/dados-pessoais')) {
            this.mobileClientValidationService.updatePersonalData$.next();
          }
          this.router.navigate(['/checkout']);
        }
      }
    }
  }

  createBaseURL(): void {
    this.keyToGetInStorage.map((key, index) => {
      this.keyToGetInStorage[index] = this.utils.storage.getItem(key) === '' ? null : `${key}=${this.utils.storage.getItem(key)}`;
    });
  }

  redirectClient(): void {
    let affiliateId = '';

    affiliateId = sessionStorage.getItem('affiliateId') ?? 'rywhqLduv';

    this.keyToGetInStorage.push('affiliateId='+affiliateId);
    this.keyToGetInStorage.push('origin=planos');
    this.keyToGetInStorage.push('CA=1');
    this.windows.location.href = this.urlBase + '?' + this.keyToGetInStorage.join('&');
  }

  resolveFlow(): void {
    this.flow = this.config.data?.flow;
    if (this.config.data?.flow === 'ja-sou-cliente') {
      this.flow = 'ja-sou-cliente';
    } else if (this.config.data?.flow === 'verified-mobile-customer-by-originid') {
      this.flow = 'verified-mobile-customer-by-originid';
    } else {
      this.flow = 'default';
    }
  }
}
