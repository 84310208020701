import { Injectable, EventEmitter } from '@angular/core';

import { Address } from '../models/address';
import { StateManager } from '../models/state-manager.model';

@Injectable({
    providedIn: 'root'
})

export class StateManagerService {

    private state: StateManager = new StateManager();

    constructor(
    ) {
        if (window.sessionStorage) {
            const state = window.sessionStorage.getItem('state');
            if (state) {
                this.state = JSON.parse(state);
            }
        }
    }

    private gravarStateManagerInSessionStorage(): void {
        window.sessionStorage.setItem('state', JSON.stringify(this.state));
    }

    setEndereco(endereco: Address): void {
        this.state.endereco = endereco;
    }

    getEndereco(): Address {
        return this.state.endereco;
    }

    setQuantidadeCarrinho(quantidateCarrinho: number): void {
        this.state.quantidadeCarrinho = quantidateCarrinho;
    }

    getQuantidadeCarrinho(): number {
        return this.state.quantidadeCarrinho;
    }

    setProdutosCarrinho(tecnologia: string, carrinho: object): void {
        this.state.produtosCarrinho[tecnologia] = carrinho;
        this.gravarStateManagerInSessionStorage();
    }

    getProdutosCarrinho(tecnologia?: string): object {
        return tecnologia ? this.state.produtosCarrinho[tecnologia] : this.state.produtosCarrinho;
    }
}
