import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccordionComponent } from './accordion.component';
import { SafeHtmlPipeModule } from '../../../pipes/safe-html.pipe.module';

@NgModule({
  declarations: [
    AccordionComponent
  ],
  imports: [
    CommonModule,
    SafeHtmlPipeModule
  ],
  exports: [
    AccordionComponent
  ]
})
export class DisclaimerModule { }
