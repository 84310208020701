import { ReactiveFormsModule } from '@angular/forms';
import { InputSelectModule } from './../../shared/mondrian-components/input-select/input-select.module';
import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewAdicionaisComponent } from './new-adicionais.component';
import { AdditionalCardComponent } from './additional-card/additional-card.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EntretenimentoTabComponent } from './entretenimento-tab/entretenimento-tab.component';
import { ConectividadeTabComponent } from './conectividade-tab/conectividade-tab.component';
import { TvTabComponent } from './tv-tab/tv-tab.component';
import { ConfigCardComponent } from './config-card/config-card.component';

@NgModule({
  declarations: [
    NewAdicionaisComponent,
    AdditionalCardComponent,
    EntretenimentoTabComponent,
    ConectividadeTabComponent,
    TvTabComponent,
    ConfigCardComponent,
  ],
  imports: [CommonModule, BrowserAnimationsModule, InputSelectModule, ReactiveFormsModule],
  providers: [{provide: LOCALE_ID, useValue: 'pt-BR'}]
})
export class NewAdicionaisModule {}
