import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes, ExtraOptions } from '@angular/router';

// components
import { NavegacaoGuard } from './shared/controle-navegacao/navegacao.guard';

const routes: Routes = [
  {
    path: 'monte-sua-combinacao',
    loadChildren: () => import('./modules/monte-sua-combinacao/monte-sua-combinacao.module').then(m => m.MonteSuaCombinacaoModule),
    data: {
      title: 'Claro Combo: plano residencial e móvel | Site Claro Oficial',
      description: 'CLARO | Monte sua Combinação com TV, Internet, Fixo e Celular.'
        + 'Conheça os produtos, ofertas e serviços exclusivos!'
    }
  }, 
  {
    path: 'checkout',
    loadChildren: () => import('./modules/checkout/checkout.module').then(m => m.CheckoutModule),
    data: {
      title: 'Claro Combo: plano residencial e móvel | Site Claro Oficial',
      description: 'CLARO | Monte sua Combinação com TV, Internet, Fixo e Celular.'
        + 'Conheça os produtos, ofertas e serviços exclusivos!'
    }
  },
  {
    path: '500',
    loadChildren: () => import('./modules/page-error/500/500.module').then(m => m.Error500Module),
    data: {
      title: 'Claro Combo: plano residencial e móvel | Site Claro Oficial',
      description: 'CLARO | Monte sua Combinação com TV, Internet, Fixo e Celular.'
        + 'Conheça os produtos, ofertas e serviços exclusivos!'
    }
  },
  {
    path: '404',
    loadChildren: () => import('./modules/page-error/404/404.module').then(m => m.Error404Module),
    data: {
      title: 'Claro Combo: plano residencial e móvel | Site Claro Oficial',
      description: 'CLARO | Monte sua Combinação com TV, Internet, Fixo e Celular.'
        + 'Conheça os produtos, ofertas e serviços exclusivos!'
    }
  },
  {
    path: 'error-cep',
    loadChildren: () => import('./modules/page-error/cep/cep.module').then(m => m.ErrorCEPModule),
    data: {
      title: 'Claro Combo: plano residencial e móvel | Site Claro Oficial',
      description: 'CLARO | Monte sua Combinação com TV, Internet, Fixo e Celular.'
        + 'Conheça os produtos, ofertas e serviços exclusivos!'
    }
  },
];

const routerOptions: ExtraOptions = {
  useHash: false,
  anchorScrolling: 'enabled',
  // scrollPositionRestoration: 'top'
};
@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, routerOptions)
  ],
  exports: [
    RouterModule
  ],
  declarations: []
})

export class AppRouting { }
