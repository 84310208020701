<div>
    <ng-container  *ngIf="step === 'send-code'">
        <app-send-code
            [croOrigin]="service.croOrigin"
            (returnData)="returnSendCode($event)"
            (closeModal)="closeModal()"
        ></app-send-code>
    </ng-container>
    <ng-container  *ngIf="step === 'mobile-client-validation'">
        <app-mobile-client-validation
            [msisdn]="service.msisdn"
            [croOrigin]="service.croOrigin"
            (returnData)="returnMobileClientValidation($event)"
            (closeModal)="closeModal()"
            (changeStep)="changeStep($event)"
            (showPage500)="showPage500()"
        ></app-mobile-client-validation>
    </ng-container>
    <ng-container  *ngIf="step === 'not-mobile-customer'">
        <app-not-mobile-customer
            (returnData)="returnNotMobileCustumer()"
            (closeModal)="closeModal()"
        ></app-not-mobile-customer>
    </ng-container>
    <ng-container  *ngIf="step === 'api-error'">
        <app-api-error
            [croOrigin]="service.croOrigin"
            (closeModal)="closeModal()"
        ></app-api-error>
    </ng-container>
</div>
