import { UtilsService } from 'src/app/services/utils.service';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AssineJaParserService } from './assine-ja-parser.service';
import { HttpClient, HttpHandler } from '@angular/common/http';
import { BucketS3Service } from 'src/app/services/bucket-s3.service';

@Injectable({
  providedIn: 'root'
})
export class MonteSuaCombinacaoService {

  public carrosselOrderConfigsUrl = `${environment.ecommerceConfig}ecommerce-responsivo/config/config.json`;

  constructor(
    public assine: AssineJaParserService,
    public http: HttpClient,
    public httpHandler: HttpHandler,
    public bucketS3Service: BucketS3Service,
    public utils: UtilsService
  ) { }

  getProductsPeriodSelections(assine?: AssineJaParserService, tipo?: string, nodeProductId?: number): Array<object> {
    const assineParse = assine;

    let produtos = this.assine && this.assine.parser &&
      this.assine.parser.data ? this.assine.parser.data.filterProduct(tipo) : [];
    if (this.assine.parser && this.assine.parser.cart && this.assine.parser.cart.selection) {
      const cartLength = Object.keys(this.assine.parser.cart.selection);
      produtos = this.assine.parser.cart.getPeriodosSelecao(produtos, cartLength.length);
    }

    if (tipo !== undefined && nodeProductId !== undefined) {
      produtos.splice(0, 0, nodeProductId);
    }
  
    this.assine.getPeriodosOferta(produtos, assineParse.parser, tipo);
    this.assine.recursosIcons(produtos);

    if (tipo === 'internet') {
      this.assine.getDetalhesInternet(produtos, tipo);
    }

    if (this.assine.accordionActive) {
      const TechnologyTranslator = this.assine.accordionActive === 'cabo' ? 'Cable' : 'Satellite';
      const gpon = this.assine.technologiesAvailable.find(tech => tech.name === TechnologyTranslator);
      if (gpon !== undefined && (tipo === 'internet' || tipo === 'tv' || tipo === 'fone') && TechnologyTranslator === 'Cable') {
        if (gpon.gpon) {
          const newProducts = [];
          produtos.forEach((element) => {
            if (element.tags && element.tags.indexOf('gpon') >= 0) {
              newProducts.push(element);
            }
          });
          produtos = newProducts;
        } else {
          const newProducts = [];
          produtos.forEach((element) => {
            if (!element.tags || (element.tags && element.tags.indexOf('gpon') === -1)) {
              newProducts.push(element);
            }
          });
          produtos = newProducts;
        }
      }
    }

    if (produtos.length > 0 && (tipo === 'internet' || tipo === 'tv')) {
      produtos.forEach(produto => {
        
        Object.keys(produto.recursos).map((item) => {
          produto.recursos[item].iconSanitizer = this.utils.uriSanitazer(produto.recursos[item].icon)
        });
  
      });
    }
    
    return produtos;
  }

  mostraCombinacao(produto?: any): boolean {
    const periodoOferta = produto && produto.periodicidadeSelecao && produto.periodicidadeSelecao.periodoOferta;
    const periodoSingle = produto && produto.individual && produto.individual.periodoOferta;
    const precoIndividual = produto && produto.individual && produto.individual.preco;
    const precoCombo = produto && produto.preco;

    return (precoCombo !== precoIndividual ||
      (precoCombo === precoIndividual && (!!periodoOferta || periodoOferta === '')
        && periodoOferta !== periodoSingle && periodoOferta !== '-'));
  }

  reorderByTag(arrayTo, valueToCompare?: string) {
    const withElements = [];
    arrayTo.forEach((element, index) => {
      if (!!element.tags && element.tags.indexOf(valueToCompare) >= 0) {
        withElements.push(index);
      }
    });
    withElements.reverse().forEach((element, index) => {
      arrayTo.splice(0, 0, arrayTo.splice(element + index, 1)[0]);
    });
    return arrayTo;
  }

  getProductsbById(assine: AssineJaParserService, tipo: string, IDs: Array<number>): Array<object> {
    const products = this.getProductsPeriodSelections(assine, tipo);
    const filteredProducts = products.filter(product => IDs.includes(product['id']));
    return filteredProducts;
  }

  public getCarrosselOrderConfigUrl() {
    return this.http.get(this.carrosselOrderConfigsUrl);
  }
  
}
