<div class="alert-container" [ngClass]="isClosing ? 'isClosing' : 'isOpening'">
    <div class="mdn-Alert {{settings.type}} {{settings.theme}} mdn-isOpen">
        <div class="mdn-Alert-content">
            <p class="mdn-Text mdn-Text--body">
                <span *ngIf="settings.showIcon" class="mdn-Icon-{{settings.type === 'mdn-Alert--success' ? 'verificado' : 'informacao' }} mdn-Icon--md"></span> {{settings.text}}
            </p>
            <p *ngIf="settings.linklabel && settings.link" class="mdn-Text mdn-Text--body" [class.icon-padding] = "settings.showIcon">
                <a class="mdn-Link mdn-Link-anchor" href={{settings.link}}>{{settings.linklabel}}</a>
            </p>
            <p *ngIf="settings.action && settings.actionText && settings.action === 'openAdicionais'" class="mdn-Text mdn-Text--body" [class.icon-padding] = "settings.showIcon">
                <button class="mdn-Link mdn-Link-anchor" (click)="openAdicionais()">{{settings.actionText}}</button>
            </p>
        </div>
        <button
            (click)="close()"
            aria-label="Close"
            class="mdn-Alert-close mdn-Button mdn-Button--icon mdn-Button--secondary"
        ><em class="mdn-Icon-fechar mdn-Icon--sm"></em>
        </button>
    </div>
</div>