import { AfterViewInit, ChangeDetectorRef, Component, ComponentFactoryResolver, ComponentRef, HostListener, OnDestroy, OnInit, Type, ViewChild } from '@angular/core';
import { trigger, state, style, animate, transition, AnimationEvent } from '@angular/animations';
import { Drawer2Config } from './drawer2-config';
import { Drawer2Ref } from './drawer2-ref';
import { Drawer2Directive } from './drawer2.directive';

import { Subject } from 'rxjs';

const ModalAnimation = trigger('modalAnimation', [
  state('closed-right', style({
    right: '-638px',
  })),
  state('opened-right', style({
    right: '0px',
  })),
  state('closed-left', style({
    left: '-638px',
  })),
  state('opened-left', style({
    left: '0px',
  })),
  transition('* => closed-right', [
    animate('0.4s')
  ]),
  transition('* => opened-right', [
    animate('0.4s')
  ]),
  transition('* => closed-left', [
    animate('0.4s')
  ]),
  transition('* => opened-left', [
    animate('0.4s')
  ])
]);

@Component({
  selector: 'app-drawer2',
  styleUrls: ['./drawer2.component.scss'],
  templateUrl: './drawer2.component.html',
  animations: [
    ModalAnimation
  ],
})
export class Drawer2Component implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(Drawer2Directive) public insertionPoint: Drawer2Directive;

  public componentRef: ComponentRef<any>;
  public childComponentType: Type<any>;
  public readonly _onClose = new Subject<any>();
  public onClose = this._onClose.asObservable();
  public size: string = 'sm';
  public titulo: string;
  public type: string;
  public nomeGtm: string = 'modal';
  public animation: string = '';

  constructor(
    public config: Drawer2Config,
    public dialogRef: Drawer2Ref,
    private componentFactoryResolver: ComponentFactoryResolver,
    private cd: ChangeDetectorRef,
  ) { }

  public ngOnInit(): void {
    if (!this.config.data.side) {
      this.config.data.side = 'right';
    };
    this.handleAnimation();
  }

  public ngAfterViewInit(): void {
    this.loadChildComponent(this.childComponentType);
    this.cd.detectChanges();
  }

  public ngOnDestroy(): void {
    if (this.componentRef) { this.componentRef.destroy(); }
  }

  public loadChildComponent(componentType: Type<any>): void {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);
    const viewContainerRef = this.insertionPoint.viewContainerRef;
    viewContainerRef.clear();
    this.componentRef = viewContainerRef.createComponent(componentFactory);
  }

  public close(): void {
    this.handleAnimation('close');
  }

  public closed(event: AnimationEvent): void {
    const prefix = event.toState.split('-');
    if (prefix && prefix[0] && prefix[0] === 'closed') {
      this._onClose.next();
    }
  }

  public onOverlayClicked(evt: MouseEvent): void {
    if (!this.config.data.disableClose) { this.close(); }
  }

  public onDialogClicked(evt: MouseEvent): void {
    evt.stopPropagation();
  }

  @HostListener('document:keydown.escape')
  public onKeydownHandler(event: KeyboardEvent): void {
    if (!this.config.data.disableClose) { this.close(); }
  }

  handleAnimation(close?: string): void {
    this.animation =  close === 'close' ? `closed-${this.config.data.side.toLowerCase()}` : `opened-${this.config.data.side.toLowerCase()}`;
  }
}
