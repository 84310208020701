import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})

export class AffiliateService {
  public get affiliateIdsToHidePortability(): string[] {
    const affiliateIds = sessionStorage.getItem(
      'affiliateIdsToHidePortability'
    );
    return affiliateIds ? affiliateIds.split(',') : [];
  }

  public set affiliateIdsToHidePortability(ids: string[]) {
    const idsString = ids.join(',');
    sessionStorage.setItem('affiliateIdsToHidePortability', idsString);
  }

  public get affiliateIdsToHideTrash(): string[] {
    const affiliateIds = sessionStorage.getItem('affiliateIdsToHideTrash');
    return affiliateIds ? affiliateIds.split(',') : [];
  }

  public set affiliateIdsToHideTrash(ids: string[]) {
    const idsString = ids.join(',');
    sessionStorage.setItem('affiliateIdsToHideTrash', idsString);
  }

  public checkAffiliateAndClearCart(cart: any): void {
    const affiliateId = sessionStorage.getItem('affiliateId');
    const affiliateIdsToHideTrash = this.affiliateIdsToHideTrash;
    if (affiliateId && affiliateIdsToHideTrash.includes(affiliateId) && cart.quantidadeCarrinho > 0) {
      cart.limparCarrinho();
      sessionStorage.setItem('shouldShowTrash', 'true');
      sessionStorage.removeItem('affiliateId');
    }
  }
}
