export class PersonalData {
  constructor(
    public name?: string,
    public email?: string,
    public phone?: string,
    public motherName?: string,
    public cpf?: string,
    public rg?: string,
    public birthdate?: string,
    public cpf_cnpj?: string,
    public goToNext?: boolean,
    public origin?: string,
    public other?: any,
    public additionalPhone?: string
  ) {
  }
}