import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IdentificationsService {

  constructor(
    private http: HttpClient
  ) { }

  getResidentialInfo(cpf: string, fingerprint_: string, cep?: string): Observable<any> {
    const query = this.mountGetResidentialInfoQuery(cpf, cep)

    const httpOptions = {
      headers: new HttpHeaders({
        'x-ecomm-fingerprint': fingerprint_
      }),
    };

    return this.http.get(
      `${environment.ecomBff}/identifications/customer/residential?${query}`,
      httpOptions
    );
    // return of(
    //   {
    //     "data": {
    //       "data": {
    //         "data": {
    //           "status": "CONECTADO"
    //         },
    //         "statusCode": 200,
    //         "meta": {
    //           "code": "200",
    //           "message": "OK"
    //         }
    //       }
    //     },
    //     "error": null
    //   }
    // );
  }

  mountGetResidentialInfoQuery(cpf: string, cep: string): string {
    const document = cpf.replace(/\D+/g, '')
    let zipcode = ''
    if(cep) {
      zipcode = cep.replace(/\D+/g, '')
    }

    let query = `document=${document}`
    if(zipcode != ''){
      query += `&zipcode=${zipcode}`
    }
    
    return query
  }
}
