import {
  Injectable,
  ComponentFactoryResolver,
  Injector,
  ApplicationRef,
  EmbeddedViewRef,
  Type,
  ComponentRef,
} from '@angular/core';
import { Drawer2Config } from './drawer2-config';
import { Drawer2Component } from './drawer2.component';
import { ModuloInjector } from './drawer2-injector';
import { Drawer2Ref } from './drawer2-ref';

@Injectable({
  providedIn: 'root',
})
export class Drawer2Service {
  dialogComponentRef: ComponentRef<Drawer2Component>;

  constructor(
    public componentFactoryResolver: ComponentFactoryResolver,
    public injector: Injector,
    public appRef: ApplicationRef
  ) {}

  public open(componentType: Type<any>, config: Drawer2Config): any {
    this.closingExistingDrawer(componentType);
    this.appendModalComponentToBody(config);
    this.dialogComponentRef.instance.childComponentType = componentType;
    this.listenToCloseEvent();
    this.handlePageScrolling(false);
  }

  public appendModalComponentToBody(config: Drawer2Config): any {
    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(Drawer2Component);
    const map = new WeakMap();
    const modalRef = new Drawer2Ref();
    map.set(Drawer2Config, config);
    map.set(Drawer2Ref, modalRef);

    const moduloInjector = new ModuloInjector(this.injector, map);
    const componentRef = componentFactory.create(moduloInjector);

    this.appRef.attachView(componentRef.hostView);

    const hostView = componentRef.hostView as EmbeddedViewRef<any>;
    const domElem = hostView.rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);
    this.dialogComponentRef = componentRef;

    const sub = modalRef.afterClosed.subscribe(() => {
      this.removeDialogComponentFromBody();
      sub.unsubscribe();
    });
  }

  public removeDialogComponentFromBody(): void {
    this.appRef.detachView(this.dialogComponentRef.hostView);
    this.dialogComponentRef.destroy();
    this.handlePageScrolling(true);
  }

  public closingExistingDrawer(componentType) {
    if (this.dialogComponentRef) {
      if (
        componentType.prototype.constructor.name ===
        this.dialogComponentRef.instance.childComponentType.name
      ) {
        this.removeDialogComponentFromBody();
      }
    }
  }

  public listenToCloseEvent() {
    this.dialogComponentRef.instance.onClose.subscribe(() => {
      this.removeDialogComponentFromBody();
    });
  }

  public handlePageScrolling(enableScroll: boolean) {
    if (!enableScroll) {
      const body = document.getElementsByTagName('body');
      body[0].style.overflowY = 'hidden';
    } else {
      const body = document.getElementsByTagName('body');
      body[0].style.overflowY = 'visible';
    }
  }
}
