import { ApplicationRef, ComponentFactoryResolver, ComponentRef, EmbeddedViewRef, Injectable, Injector } from '@angular/core';

import { AlertConfig } from './alert-config';
import { AlertComponent } from './alert.component';
import { ModuloInjector } from './alert-injector';
import { AlertRef } from './alert-ref';

export interface ArrayComponentRef {
  id: number;
  componentRef: ComponentRef<AlertComponent>;
}

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  alertComponentRef: ArrayComponentRef[] = [];
  counter: number = 0;

  constructor(
    public componentFactoryResolver: ComponentFactoryResolver,
    public appRef: ApplicationRef,
    public injector: Injector,
  ) { }

  public open(config: AlertConfig): void {
    const id = this.counter;
    const alertRef = this.appendAlertComponentToBody(id, config);
    this.counter++;

    const index = this.alertComponentRef.findIndex(x => x.id === id);
    const ref = this.alertComponentRef[index].componentRef;
    ref.instance.onClose.subscribe({
      next: (v) => {
        this.removeDialogComponentFromBody(v);
      }
    });
  }

  public appendAlertComponentToBody(id: number, config: AlertConfig): void {
    config.id = id;
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(AlertComponent);

    const map = new WeakMap();
    const alertRef = new AlertRef();

    map.set(AlertConfig, config);
    map.set(AlertRef, alertRef);

    const sub = alertRef.afterClosed.subscribe(() => {
      this.removeDialogComponentFromBody(id);
      sub.unsubscribe();
    });

    const componentRef = componentFactory.create(new ModuloInjector(this.injector, map));
    this.appRef.attachView(componentRef.hostView);
    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

    const targetNode: HTMLElement = config.target ? document.getElementById(config.target) : document.getElementById('alert-root');
    if (targetNode) {
      targetNode.appendChild(domElem);
    }

    this.alertComponentRef.push({id, componentRef});
  }

  public removeDialogComponentFromBody(id: number): void {
    const index = this.alertComponentRef.findIndex(x => x.id === id);
    const ref = this.alertComponentRef[index].componentRef;

    this.appRef.detachView(ref.hostView);
    ref.destroy();

    this.alertComponentRef.splice(index, 1);
  }
}
