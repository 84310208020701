export class Payment {
  constructor(
    public paymentDay?: number,
    public directDebit?: boolean,
    public bankNumber?: string,
    public agencyNumber?: string,
    public accountNumber?: string,
    public accountDigit?: string,
    public goToNext?: boolean,
    public origin?: string,
    public invoiceType?: string,
    public billingType?: string,
    public billingAddress?: string,
    public billingNumber?: string,
    public billingDistrict?: string,
    public billingComplement?: string,
    public billingCity?: string,
    public billingState?: string,
    public billingZipcode?: string,
    public invoiceEmail?: string,
    public other?: any,
  ) { }

  appendOther(value: any): void {
    if(!!this.other){
      this.setOther(Object.assign(this.other, value));
    } else{
      this.setOther(value);  
    }
  }

  setOther(value: any): void {
    this.other = value;
  }

  setOrigin(value: string): void {
    this.origin = value;
  }

  setDirectDebit(value: boolean): void {
    this.directDebit = value;
  }

  setPaymentDay(value: number): void {
    this.paymentDay = value;
  }

  setBankNumber(value: string): void {
    this.bankNumber = value;
  }

  setAgencyNumber(value: string): void {
    this.agencyNumber = value;
  }

  setAccountNumber(value: string): void {
    this.accountNumber = value
  }

  setAccountDigit(value: string): void {
    this.accountDigit = value;
  }

  setInvoiceEmail(value: string): void {
    this.invoiceEmail = value;
  }

  setInvoiceType(value: string): void {
    this.invoiceType = value;
  }

  setBillingZipcode(value: string): void {
    this.billingZipcode = value;
  }

  setBillingType(value: string): void {
    this.billingType = value;
  }

  setBillingState(value: string): void {
    this.billingState = value;
  }

  setBillingNumber(value: string): void {
    this.billingNumber = value;
  }

  setBillingDistrict(value: string): void {
    this.billingDistrict = value;
  }

  setBillingComplement(value: string): void {
    this.billingComplement = value;
  }

  setBillingCity(value: string): void {
    this.billingCity = value;
  }

  setBillingAddress(value: string): void {
    this.billingAddress = value;
  }

  getOrigin(): string {
    return this.origin;
  }

  getPaymentDay(): number {
    return this.paymentDay;
  }

  getDirectDebit(): boolean {
    return this.directDebit;
  }

  getBankNumber(): string {
    return this.bankNumber;
  }

  getAgencyNumber(): string {
    return this.agencyNumber;
  }

  getAccountNumber(): string {
    return this.accountNumber;
  }

  getAccountDigit(): string {
    return this.accountDigit;
  }

  getInvoiceEmail(): string {
    return this.invoiceEmail;
  }

  getInvoiceType(): string {
    return this.invoiceType;
  }

  getBillingZipcode(): string {
    return this.billingZipcode;
  }

  getBillingType(): string {
    return this.billingType;
  }

  getBillingState(): string {
    return this.billingState;
  }

  getBillingNumber(): string {
    return this.billingNumber;
  }

  getBillingDistrict(): string {
    return this.billingDistrict;
  }

  getBillingComplement(): string {
    return this.billingComplement;
  }

  getBillingCity(): string {
    return this.billingCity;
  }

  getBillingAddress(): string {
    return this.billingAddress;
  }

  getOther(): any {
    return this.other;
  }
}
