import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';

export interface FlowType {
  stage: any;
  previous: any;
  next: any;
}

@Injectable({
  providedIn: 'root'
})
export class CommonNavigationService {

  step: any;
  stepAmount: number;
  flows: FlowType[];

  constructor(
    public utils: UtilsService,
    public router: Router
  ) {
    this.flows = [
      {
        stage: '/monte-sua-combinacao',
        previous: '/',
        next: '/checkout'
      },
      {
        stage: '/checkout',
        previous: 'monte-sua-combinacao',
        next: null
      },
      {
        stage: '/checkout/personalizacao-movel',
        previous: '/monte-sua-combinacao',
        next: '/checkout/dados-pessoais'
      },
      {
        stage: '/checkout/dados-pessoais',
        previous: '/monte-sua-combinacao',
        next: '/checkout/endereco'
      },
      {
        stage: '/checkout/endereco',
        previous: '/checkout/dados-pessoais',
        next: '/checkout/pagamento'
      },
      {
        stage: '/checkout/address',
        previous: '/checkout/dados-pessoais',
        next: '/checkout/pagamento'
      },
      {
        stage: '/checkout/pagamento',
        previous: '/checkout/endereco',
        next: '/checkout/confirmacao'
      },
      {
        stage: '/checkout/retentativa',
        previous: null,
        next: '/checkout/confirmacao'
      },
      {
        stage: '/checkout/confirmacao',
        previous: '/checkout/pagamento',
        next: null
      }
    ];
  }

  sendToNext() {
    if (!!this.utils.lastRouteUrlBeforeRedirectToHome) {
      this.router.navigateByUrl(this.utils.lastRouteUrlBeforeRedirectToHome);
      this.utils.lastRouteUrlBeforeRedirectToHome = undefined;
    } else {
      const flow = this.findFlow(window.location.pathname);
      if (flow && flow.next) {
        this.navigateTo( flow.next === '/checkout/endereco' && this.utils.storageGetItem('address') === 'true' ?  '/checkout/address' : flow.next );
      }
    }
  }

  sendToPrevious() {
    const flow = this.findFlow(window.location.pathname);
    if (flow) {
      this.navigateTo(flow.previous === '/checkout/endereco' && this.utils.storageGetItem('address') === 'true' ?  '/checkout/address' : flow.previous);
    }
  }

  navigateTo(route: any) {
    let calculatedRoute;
    if (route instanceof Function) {
      calculatedRoute = route.apply(this);
    }

    calculatedRoute = calculatedRoute || route;
    this.router.navigate([calculatedRoute.toString()]);
  }

  findFlow(stage) {
    if (this.flows && this.flows.length > 0) {
      return this.flows.find(x => x.stage === stage);
    }
    return null;
  }

  offset() {
    const top = 0;
    return top;
  }
}
