import { Injector, Type, InjectionToken, InjectFlags } from '@angular/core';

export class ModuloInjector implements Injector {
    constructor(
        private parentInjector: Injector,
        private additionalTokens: WeakMap<any, any>
    ) {}

    get<T>(
        token: Type<T> | InjectionToken<T>,
        notFoundValue?: T
    ): T {
        const value = this.additionalTokens.get(token);

        if (value) {
            return value;
        }

        // retorna o config e modalRef para o componente criado
        return this.parentInjector.get<any>(token, notFoundValue);
    }
}