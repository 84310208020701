import { Component, Input, OnInit } from '@angular/core';
import { Disclaimer } from '../../../models/disclaimer.model';

import accordionFn from 'mondrian-vanilla/core/components/Accordion/accordion';
import executeEach from 'mondrian-vanilla/core/util/execute-each';
import { Router } from '@angular/router';
import { CroService } from 'src/app/services/cro.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss']
})
export class AccordionComponent implements OnInit {

  @Input() list: Disclaimer[];
  @Input() id: string;

  textoLegal: Disclaimer[] = [];

  constructor(
    public router: Router,
    public cro: CroService,
    public utils: UtilsService,
  ) { }

  ngOnInit(): void {    
    setTimeout(() => { executeEach(`#${this.id}`, accordionFn); }, 500);
  }

  filterDisclaimer(): void {
    const filteredList = this.list.filter((_item, index) => index === 0 || index === 1);
    this.list = filteredList;
  }

  tagAccordion(event:any, title: string):void{
    const element = event.target;
    const parent = element.parentElement;
    const classList = parent.classList;
    
    if(!classList.contains("is-expanded")){
      this.cro.genericGtm('clique:link','abrir:'+this.utils.convertToSanitizer(title), 'texto-legal')
    }
  }
}
