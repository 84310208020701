<div class="mdn-Modal-header">
    <h1 class="mdn-Heading mdn-Heading--md">
        {{titulo}}
    </h1>
    <button (click)="fecharDialog()" class="mdn-Button-header-close mdn-Button mdn-Button--icon mdn-Button--secondary" (keydown.enter)="fecharDialog()"
    [attr.data-gtm-event-category]="'planos-claro-res:monte-sua-combinacao'"
    [attr.data-gtm-event-action]="'clique:botao:modal:'+gtmTitle" data-gtm-event-label="fechar-subproduto" >
        <em class="mdn-Icon-fechar mdn-Icon--md"></em>
    </button>
</div>

<div class="mdn-Divider mdn-Divider--primary"></div>

<div class="mdn-Modal-body">

    <div class="mdn-Container">
        
        <div class="mdn-Row atributo-gratuito" *ngIf="produto.recursos">
            <div class="mdn-Col-xs-12">
                <div class="mdn-Container">
                    <div class="mdn-Row atributo" *ngFor="let recurso of produto.recursos">
                        <div *ngIf="recurso.icon !== ''" class="mdn-Col-sm-1 mdn-Col-xs-3 imagemRecurso">                          
                            <img class="icon" [src]="env.staticPath+'assets/icons/beneficios/'+recurso.icon+'.svg'" aria-hidden="true">
                        </div>
                        <div *ngIf="recurso.icon !== '' " class="mdn-Col-sm-8 mdn-Col-xs-5">
                            {{ recurso.descricao }}
                        </div>
                        <div *ngIf="recurso.icon === '' " class="mdn-Col-sm-9 mdn-Col-xs-4">
                            {{ recurso.descricao }}
                        </div>
                        <div class="mdn-Col-xs-3 text-center">
                            Incluso
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="mdn-Row atributo-pago" *ngIf="keys.length > 0">
            <div class="mdn-Col-md-12 atributo" *ngFor="let key of keys; let i = index;">
                <div *ngIf="subprodutos[0][key].multipleChoice === 0">
                    <div class="mdn-Input {{ selectedAdditionalsList[key]['selecionado'] ? 'mdn-Input--activatedField' : ''  }}">
                        <select 
                            id="search" 
                            class="mdn-Input-field" 
                            type="text" 
                            data-mdn-type="select"
                            (change)="$event.target.value !== '-1' ? adicionalSelecionado($event, $event.target.value) : removeTodosAdicionaisDoKey(key);onFocus('subproduto-adicional-selecionado');"
                            tabindex="-1"
                        >
                            <option *ngIf="selectedAdditionalsList[key]['hasFree'] !== true" value="-1">Selecione</option>
                            <option 
                                *ngFor="let opcao of subprodutos[0][key].opcoes" 
                                [value]="opcao.id"
                                [selected]="selectedAdditionalsList[key]['selecionado'] == opcao.id"
                            >{{opcao.nome}} - {{opcao.preco/100 | currency:'BRL'}}
                            </option>
                        </select>
                        <label for="search" class="mdn-Input-label">{{subprodutos[0][key].nome}}</label>
                        <small class="mdn-Input-errorFeedback">Por favor, preencha este campo corretamente.</small>
                    </div>
                </div>

                <div 
                    class="table-pontos"
                    *ngIf="subprodutos[0][key].multipleChoice === 1"
                >
                    <p class="mdn-Text mdn-Text--body">{{subprodutos[0][key].nome}}</p>
                    <div
                        class="subproduto"
                        *ngFor="let opcao of subprodutos[0][key].opcoes" 
                        [ngClass]="opcao.nome.indexOf('4K') > -1 ? 'highlight-add' : ''"
                    >
                        <div class="mdn-Row">
                            <div class="mdn-Col-xs-8">
                                <h3 class="mdn-Subtitle mdn-Subtitle--sm">{{opcao.nome}}</h3>
                                <p class="mdn-Text mdn-Text--body" [innerHTML]="opcao.descricao">Microsoft 365 Business</p>
                            </div>
                            <div class="mdn-Col-xs-4">
                                <div class="mdn-Heading mdn-Heading--xs" style="text-align: right;">{{opcao.preco/100 | currency:'BRL'}}</div>
                            </div>
                        </div>
                        <div class="mdn-Row">
                            <div class="mdn-Col-xs-12">
                                <button 
                                    class="mdn-Button mdn-Button--primary add gtm-element-event"
                                    *ngIf="!opcao.selecionado; else elseAdicionalSelecionado"
                                    (click)="adicionalSelecionado($event, opcao.id, opcao.nome)"
                                    (keydown.enter)="adicionalSelecionado($event, opcao.id, opcao.nome)"
                                    [attr.data-gtm-event-category]="'planos-claro-res:monte-sua-combinacao'"
                                    [attr.data-gtm-event-action]="'clique:botao:modal:'+gtmTitle"
                                    data-gtm-event-label="adicionar"
                                    [id]="opcao.id"
                                >Adicionar</button>
                                <ng-template #elseAdicionalSelecionado>
                                <button 
                                    class="mdn-Button remove gtm-element-event"
                                    (click)="adicionalRemover(opcao.id)"
                                    (keydown.enter)="adicionalRemover(opcao.id)"
                                    [attr.data-gtm-event-category]="'planos-claro-res:monte-sua-combinacao'"
                                    [attr.data-gtm-event-action]="'clique:botao:modal:'+gtmTitle"
                                    data-gtm-event-label="remover"
                                    [id]="opcao.id"
                                >Remover</button>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="mdn-Row tv_adicional" *ngIf="subprodutos[1] != undefined && subprodutos[1].length > 0">
            <div class="canais mdn-Col-xs-12">
                <p class="mdn-Text mdn-Text--body titulo">Quer turbinar sua TV? Selecione os canais desejados</p>
                <div *ngIf="canAdd" class="more-channels-notification">VOCÊ PODE ADICIONAR MAIS CANAIS.</div>
                <div 
                    id="swiper" 
                    class="mdn-Swiper--inverse mdn-Swiper" 
                    data-mdn-slider-per-view-mobile="auto" 
                    data-mdn-slider-per-view-tablet="auto" 
                    data-mdn-slider-per-view-small-desktop="auto" 
                    data-mdn-slider-per-view="auto"
                    data-mdn-auto-play="false"
                >
                    <div class="mdn-Swiper-wrapper">
                        <div class="mdn-Swiper-slide" *ngFor="let subproduto of subprodutos[1]; let i = index" style="margin-right: 16px">
                            <div class="table product" id="{{subproduto.id}}-table" [ngClass]="{'mostraBefore': subproduto.selecionado}">
                                <div class="container-capa" id="{{subproduto.id}}-capa" [ngClass]="{'mostraBorda': subproduto.selecionado}">
                                    <div class="img">
                                        <img [src]="env.subProductsPath+subproduto.imagens?.capa" alt="{{subproduto.nome}}">
                                    </div>
                                    <div class="description">
                                        <div class="text">
                                            <img [src]="env.subProductsPath+subproduto.imagens?.canal" alt="{{subproduto.nome}}">
                                            <div [innerHTML]="subproduto.descricaoCapa" class="mdn-Text mdn-Text--caption"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="content">
                                    <div class="mdn-Tag mdn-Tag--highlight">
                                        <p>{{subproduto.nome}}</p>
                                    </div>
                                    <div class="price">
                                        <dl class="mdn-Price" >
                                            <dt class="mdn-Price-prefix" >
                                                {{ subproduto.prazo || "&nbsp;"}}
                                            </dt>
                                            <dd class="mdn-Price-price" >
                                                {{ subproduto.preco/100 | currency:'BRL' }}
                                            </dd>
                                            <dt class="mdn-Price-suffix" >
                                                <ng-template [ngIf]="subproduto.precoDe !== subproduto.preco" [ngIfElse]="elsePrecoDe">
                                                    após, {{ subproduto.precoDe/100 | currency:'BRL' || "&nbsp;" }}
                                                </ng-template>
                                                <ng-template #elsePrecoDe>
                                                    &nbsp;
                                                </ng-template>
                                            </dt>
                                        </dl>
                                    </div>
                                    <div class="select">
                                        <button 
                                            class="mdn-Button mdn-Button--primary add gtm-element-event"
                                            *ngIf="!subproduto.selecionado; else elseProdutoSelecionado"
                                            (click)="subprodutoSelecionado(subproduto.id)" 
                                            (keydown.enter)="subprodutoSelecionado(subproduto.id)"
                                            [attr.data-gtm-event-category]="'planos-claro-res:monte-sua-combinacao'"
                                            [attr.data-gtm-event-action]="'clique:botao:modal:'+gtmTitle"
                                            data-gtm-event-label="adicionar"
                                            title="Selecionar"
                                        >Adicionar</button>
                                        <ng-template #elseProdutoSelecionado>
                                            <button 
                                                class="mdn-Button remove gtm-element-event"
                                                (click)="subprodutoRemover(subproduto.id)"
                                                [id]="subproduto.id"
                                                (keydown.enter)="subprodutoRemover(subproduto.id)"
                                                [attr.data-gtm-event-category]="'planos-claro-res:monte-sua-combinacao'"
                                                [attr.data-gtm-event-action]="'clique:botao:modal:'+gtmTitle"
                                                data-gtm-event-label="remover"
                                                title="Remover"
                                            >Remover</button>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Add Arrows -->
                    <div class="mdn-Swiper-button-prev">
                        <em class="mdn-Icon-esquerda mdn-Icon--lg"></em>
                    </div>
                    <div class="mdn-Swiper-button-next">
                        <em class="mdn-Icon-direita mdn-Icon--lg"></em>
                    </div>
                </div>

                <!-- Snippet end -->

                <div *ngIf="hasSubProduct" id="msg-add-subP" class="added-channel-notification">CANAL
                    ADICIONADO COM SUCESSO!
                </div>
            </div>
        </div>
    </div>

</div>

<div class="mdn-Modal-footer" 
    *ngIf="router.url.indexOf('/assine-empresas') === -1 && obj.tipo === 'tv' && router.url.indexOf('/monte-sua-combinacao') > -1"
>
    <div class="mdn-Modal-footer-start">
        <button
            id="voltar-vitrine"
            class="mdn-Button mdn-Button--secondary gtm-element-event"
            (click)="fecharDialog()"
            (keydown.enter)="fecharDialog()"
            [attr.data-gtm-event-category]="'planos-claro-res:monte-sua-combinacao'"
            [attr.data-gtm-event-action]="'clique:botao:modal:'+gtmTitle"
            data-gtm-event-label="voltar-vitrine"
        >Voltar<span>&nbsp;para vitrine</span></button>
    </div>
    <div class="mdn-Modal-footer-end">
        <button
            id="preencher-dados"
            class="mdn-Button mdn-Button--primary gtm-element-event"
            (click)="continuaCompra()"
            (keydown.enter)="continuaCompra()"
            [attr.data-gtm-event-category]="'planos-claro-res:monte-sua-combinacao'"
            [attr.data-gtm-event-action]="'clique:botao:modal:'+gtmTitle"
            data-gtm-event-label="preencher-dados"
        >Preencher dados</button>
    </div>
</div>