import { Observable, Subject } from 'rxjs';

export class Drawer2Ref {
  // assinatura das função que serão chamadas pelos filhos no modal
  public readonly _afterClosed = new Subject<any>();

  afterClosed: Observable<any> = this._afterClosed.asObservable();

  close(result?: any): void {
    this._afterClosed.next(result);
  }
}
