<div *ngIf="show === true" class="mdn-CardInstitutional mdn-CardInstitutional--image">
    <div
      class="mdn-CardInstitutional--imageThumb"
      [class.pos]="reguaTransacionalOfertaPlanoMovel.type === 'POS'"
      [class.controle]="reguaTransacionalOfertaPlanoMovel.type === 'CONTROLE'"
    >
        <img class="desk" *ngIf="reguaTransacionalOfertaPlanoMovel.type === 'POS'"
            src="https://mondrian.claro.com.br/brands/vertical/inverse/claro-pos.svg" alt="Claro Pós">
        <img class="desk" *ngIf="reguaTransacionalOfertaPlanoMovel.type === 'CONTROLE'"
            src="https://mondrian.claro.com.br/brands/vertical/inverse/claro-controle.svg" alt="Claro Controle">

        <img class="mobile" *ngIf="reguaTransacionalOfertaPlanoMovel.type === 'POS'"
            src="https://mondrian.claro.com.br/brands/horizontal/inverse/claro-pos.svg" alt="Claro Pós">
        <img class="mobile" *ngIf="reguaTransacionalOfertaPlanoMovel.type === 'CONTROLE'"
            src="https://mondrian.claro.com.br/brands/horizontal/inverse/claro-controle.svg" alt="Claro Controle">
    </div>
    <div class="mdn-CardInstitutional-content">
        <div class="left">
            <div class="first-line">
                <h3 class="mdn-Heading mdn-Heading--sm">{{reguaTransacionalOfertaPlanoMovel.name}}</h3>
                <ng-container *ngFor="let extra of reguaTransacionalOfertaPlanoMovel.extras">
                    <div class="mdn-Text mdn-Text--body extra">{{extra}}</div>
                </ng-container>
            </div>
            <div class="second-line">
                <ng-container *ngFor="let benefit of reguaTransacionalOfertaPlanoMovel.benefits">
                    <div class="mdn-Text mdn-Text--body benefits-list">
                        <div class="benefit-name" [innerHTML]="benefit.name"></div>
                        <ng-container *ngFor="let logo of benefit.list">
                            <div class="benefit-icon">
                                <img [attr.src]="logo.icon" [attr.alt]="logo.name">
                                <div class="mdn-Tooltip mdn-Tooltip--bottom">
                                    <p class="mdn-Text mdn-Text--caption mdn-Text--inverse">{{logo.name}}</p>
                                </div>
                            </div>
                        </ng-container>
                        <div *ngIf="benefit.moreIcons" class="quantity" [innerHTML]="benefit.moreIcons"></div>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="right">
            <dl class="mdn-Price">
                <dd class="mdn-Price-price">
                    {{ reguaTransacionalOfertaPlanoMovel.price / 100 | currency:'BRL' }}
                    <span class="mdn-Price-price-period">
                        / mês
                    </span>
                </dd>
            </dl>
            <p class="mdn-Text mdn-Text--caption">No débito automático + fatura digital com fidelidade de 12 meses</p>
            <button (click)="goToClaroMovel()" class="mdn-Button mdn-Button--global">Contratar</button>
        </div>
    </div>
</div>