import { Component, OnInit, Inject, ViewChild, ChangeDetectorRef, AfterViewInit, OnDestroy } from '@angular/core';

import { NguCarouselConfig, NguCarousel } from '@ngu/carousel';
import { SubprodutoSelecaoService } from '../../../../services/subproduto-selecao.service';
import { CroService } from '../../../../services/cro.service';
import { UtilsService } from '../../../../services/utils.service';
import { Router } from '@angular/router';
import { RotasEnum } from '../../../../shared/enums/rotas-enum';
import { environment } from '../../../../../environments/environment';
import { NotificationService } from '../../../../shared/messages/notification.service';
import { CommonNavigationService } from 'src/app/services/navigation.service';
import { MdnModalConfig } from '../../../../shared/mondrian-components/mdn-modal/mdn-modal-config';
import { MdnModalRef } from '../../../../shared/mondrian-components/mdn-modal/mdn-modal-ref';

import swiperFn from 'mondrian-vanilla/core/components/Swiper/swiper';

@Component({
  selector: 'app-subprodutos-selecao',
  templateUrl: './subprodutos-selecao.component.html',
  styleUrls: ['./subprodutos-selecao.component.scss']
})

export class SubprodutosSelecaoComponent implements OnInit, AfterViewInit, OnDestroy {

  public currentSlide: number = 0;
  @ViewChild('subprodutosOpcoes', { static: false }) carousel: NguCarousel<any>;
  public carouselSubprodutos: NguCarouselConfig = {
    grid: { xs: 1, sm: 2, md: 3, lg: 3, all: 0 },
    slide: 1,
    point: {
      visible: false,
      hideOnSingleSlide: true
    },
    load: 3,
    loop: false,
    touch: true
  };
  subprodutos: any = [];
  keys: any = [];
  mostraNavegacaoPontos: boolean = false;
  canAdd: boolean;
  hasSubProduct: boolean;
  additionalSelected: number;
  env: any = environment;
  produto: any = {};
  goToCheckout: boolean = false;
  public gtmCategory: string;
  public gtmTitle: string;
  public obj: any;
  public selectedAdditionalsList: object = {};
  public titulo: string;

  constructor(
    public subprodutoService: SubprodutoSelecaoService,
    public cdr: ChangeDetectorRef,
    public cro: CroService,
    public utils: UtilsService,
    public router: Router,
    public notificationService: NotificationService,
    public navigationService: CommonNavigationService,
    public config: MdnModalConfig,
    public dialog: MdnModalRef,
  ) {
    this.obj = config.data;
  }

  ngOnInit(): void {
    this.titulo = this.obj.titulo;

    if (this.obj.tipo === 'tv') {
      this.subprodutos.push(this.obj.parser.data.getProductById(this.obj.tipo, this.obj.parser.cart.selection.tv.id).adicionais);
      this.subprodutos.push(this.obj.parser.cart.subprodutos.permitidos(this.obj.parser.cart.selection.tv.id));
      this.setAdditionalsKeys();
      this.produto = this.recursosIcons(this.obj.parser.cart.selection.tv);


    } else if (this.obj.tipo === 'internet' && this.obj.parser.cart.selection.internet) {
      this.subprodutos.push(this.obj.parser.data.getProductById(this.obj.tipo, this.obj.parser.cart.selection.internet.id).adicionais);
      this.setAdditionalsKeys();
    }
    this.additionalFree();

    this.checaAdicionaisSelecionados();
    this.checaSubprodutosSelecionados();

    this.subprodutoService.novoParser$.subscribe((parser) => {
      this.subprodutos = [];

      if (this.obj.tipo === 'tv') {
        this.subprodutos.push(parser.data.getProductById(this.obj.tipo, parser.cart.selection.tv.id).adicionais);
        this.subprodutos.push(parser.cart.subprodutos.permitidos(parser.cart.selection.tv.id));
        this.setAdditionalsKeys();

      } else if (this.obj.tipo === 'internet' && this.obj.parser.cart.selection.internet) {
        this.subprodutos.push(parser.data.getProductById(this.obj.tipo, parser.cart.selection.internet.id).adicionais);
        this.setAdditionalsKeys();
      }

      this.checaAdicionaisSelecionados();
      this.checaSubprodutosSelecionados();
      this.subprodutos.forEach(subproduto => {
        subproduto.gtmLabelSanitized = subproduto.nome ? this.utils.convertToSanitizer(subproduto.nome) : 'sem-label';
      });

      this.listAditionalsSelected();
    });

    this.gtmCategory = this.utils.getGtmCategory();
    this.gtmTitle = this.utils.convertToSanitizer(this.obj.titulo) || 'subproduto';

    this.listAditionalsSelected();
  }

  recursosIcons(produto: any) {
    if (produto.recursos && produto.recursos.length > 0) {
      for (const key of Object.keys(produto.recursos)) {
        produto.recursos[key].icon = this.utils.replaceCharacters(produto.recursos[key].nome.toLowerCase());
      }

    }
    return produto;
  }

  private listAditionalsSelected(): void {
    interface Adicional {
      opcoes: Array<object>;
      id: number;
    }

    interface Opcoes {
      id: number;
      preco: number;
    }

    interface Detail {
      selecionado?: number;
      hasFree: boolean;
    }

    this.selectedAdditionalsList = {};
    const selecionados = this.obj.parser.cart.selection ? this.obj.parser.cart.selection[this.obj.tipo].selecionados : [];
    const adicionais = this.subprodutos[0];

    if (adicionais) {
      Object.values(adicionais).forEach((adicional: Adicional) => {
        const key: number = adicional.id;
        const detail: Detail = { hasFree: false };

        if (key) {
          const opcoes = adicional.opcoes;
          if (opcoes) {
            opcoes.forEach((opcao: Opcoes) => {
              const id = opcao.id;
              if (opcao.preco === 0) {
                detail.hasFree = true;
              }
              if (selecionados) {
                selecionados.forEach(selecionado => {
                  if (selecionado.id === id) {
                    detail.selecionado = selecionado.id;
                  }
                });
              }
            });
          }
          this.selectedAdditionalsList[key] = detail;
        }
      });
    }
  }

  public setAdditionalsKeys(): void {
    if (this.subprodutos[0]) {
      this.keys = Object.keys(this.subprodutos[0]);
    }
  }

  public additionalFree(): void {
    const additionalIds = this.utils.storage.getItem('additionalIds');

    if (!!additionalIds) { return; }
    try {
      this.subprodutos.filter(add => !Array.isArray(add)).forEach(item => {
        Object.values(item).forEach(value => {

          const { opcoes } = <any>value;
          setTimeout(() => {
            opcoes.filter(opcao => opcao.preco === 0).forEach(({ id }) => {
              this.additionalSelected = id;
              this.adicionalSelecionado({
                'type': 'change',
                'value': id,
                'id': ''
              });
            }, 1);
          });
        });
      });
    } catch (error) {}

  }

  ngAfterViewInit() {
    this.mostraNavegacaoPontos = true;
    this.cdr.detectChanges();

    if (document.getElementById('swiper')) {
      swiperFn(document.getElementById('swiper'));
    }
  }

  ngOnDestroy() {
    if (this.goToCheckout) {
      this.navigationService.sendToNext();
    }
  }

  moveSlide(i) {
    if (this.carousel !== undefined) {
      this.currentSlide = i;
      this.carousel.moveTo(i);
    }
  }

  fecharDialog() {
    this.dialog.close();
  }

  checaAdicionaisSelecionados() {
    if (this.obj.parser.cart.adicionais.length > 0) {
      const selecionados = this.obj.parser.cart.adicionais.keys;
      for (const i in this.keys) {
        if (this.keys[i]) {
          const opcoes = this.subprodutos[0][this.keys[i]].opcoes;
          for (const x in opcoes) {
            if (selecionados.indexOf(opcoes[x].id.toString()) > -1) {
              opcoes[x].selecionado = true;
            } else {
              opcoes[x].selecionado = false;
            }
          }
        }
      }
    }
  }

  public checaAdicionaisSelecionadosPorKeyId(key: number): number {
    let result: number = 0;
    this.subprodutos[0][key].opcoes.forEach(el1 => {
      this.obj.parser.cart.adicionais.keys.forEach(el2 => {
        if (el1.id === Number(el2)) {
          result = el2;
        };
      })
    });

    return result;
  }

  public removeTodosAdicionaisDoKey(key: number): void {
    const opcional = this.checaAdicionaisSelecionadosPorKeyId(key)
    if (opcional) {
      this.adicionalRemover(opcional);
    }
  }

  checaSubprodutosSelecionados() {
    if (this.obj.parser.cart.subprodutos.length > 0) {
      const selecionados = this.obj.parser.cart.subprodutos.keys;
      for (const i in this.subprodutos[1]) {
        if (selecionados.indexOf(this.subprodutos[1][i].id) > -1) {
          this.subprodutos[1][i].selecionado = true;
        } else if (this.subprodutos[1][i].id) {
          this.subprodutos[1][i].selecionado = false;
        }
      }
    }
    if (this.obj.parser.cart.subprodutos.length === 0 || (this.subprodutos[1] && this.obj.parser.cart.subprodutos.length === this.subprodutos[1].length)) {
      this.canAdd = false;
    } else {
      this.canAdd = true;
    }
  }

  adicionalSelecionado(e, id?, nome?) {
    if (id) {
      this.obj.parser.cart.adicionais.add(id);
      this.checaAdicionaisSelecionados();
      this.subprodutoService.setAddCookie(this.obj.parser.cart.adicionais.keys);
      this.subprodutoService.subprodutosAtualizados(this.obj.parser);

      if (this.router.url.indexOf(RotasEnum.CHECKOUT.toString()) > -1) {
        this.subprodutoService.montaCroAdicionais({ id: Number(id), tipo: this.obj.tipo, checkout: true });
      } else {
        this.subprodutoService.montaCroAdicionais({ id: Number(id), tipo: this.obj.tipo, checkout: false });
      }
    } else {
      this.obj.parser.cart.adicionais.add(e.value);
      this.checaAdicionaisSelecionados();
      this.subprodutoService.setAddCookie(this.obj.parser.cart.adicionais.keys);
      this.subprodutoService.subprodutosAtualizados(this.obj.parser);

      if (this.router.url.indexOf(RotasEnum.CHECKOUT.toString()) > -1) {
        this.subprodutoService.montaCroAdicionais({ id: parseInt(e.value, 10), tipo: this.obj.tipo, checkout: true });
      } else {
        this.subprodutoService.montaCroAdicionais({ id: parseInt(e.value, 10), tipo: this.obj.tipo, checkout: false });
      }
    }
  }

  adicionalRemover(id) {
    // tslint:disable-next-line: forin
    for (const i in this.keys) {
      const opcoes = this.subprodutos[0][this.keys[i]].opcoes;
      for (const x in opcoes) {
        if (opcoes[x].id === id) {
          opcoes[x].selecionado = false;

          const croObjeto = {
            name: opcoes[x].nome,
            id: opcoes[x].id,
            price: this.utils.formataDinheiroCro(opcoes[x].preco.toString()),
            brand: this.utils.getBrandTag(),
            category: this.obj.tipo,
            quantity: 1
          };
          this.cro.RemoveFromCart(croObjeto);
        }
      }
    }
    this.obj.parser.cart.adicionais.remove(id);
    this.subprodutoService.setAddCookie(this.obj.parser.cart.adicionais.keys);
    this.subprodutoService.subprodutosAtualizados(this.obj.parser);
  }

  removerMsg() {
    setTimeout(() => {
      this.hasSubProduct = false;
    }, 5000);
  }

  subprodutoSelecionado(id) {
    this.hasSubProduct = true;
    this.obj.parser.cart.subprodutos.add(id);
    this.checaSubprodutosSelecionados();
    this.subprodutoService.setSubCookie(this.obj.parser.cart.subprodutos.keys);
    this.removerMsg();

    this.subprodutoService.subprodutosAtualizados(this.obj.parser);
    const el = document.getElementById('msg-add-subP');
    if (el) { el.scrollIntoView(); }

    if (this.router.url.indexOf(RotasEnum.CHECKOUT.toString()) > -1) {
      this.subprodutoService.montaCroAdicionais({ id: id, tipo: this.obj.tipo, checkout: true });
    } else {
      this.subprodutoService.montaCroAdicionais({ id: id, tipo: this.obj.tipo, checkout: false });
    }
  }

  subprodutoRemover(id) {
    for (const i in this.subprodutos[1]) {
      if (this.subprodutos[1][i].id === id) {
        this.subprodutos[1][i].selecionado = false;
        const croObjeto = {
          name: this.subprodutos[1][i].nome,
          id: this.subprodutos[1][i].id,
          price: this.utils.formataDinheiroCro(this.subprodutos[1][i].preco.toString()),
          brand: this.utils.getBrandTag(),
          category: this.obj.tipo,
          quantity: 1
        };
        this.cro.RemoveFromCart(croObjeto);
      }
    }
    this.hasSubProduct = false;
    this.obj.parser.cart.subprodutos.remove(id);
    this.subprodutoService.setSubCookie(this.obj.parser.cart.subprodutos.keys);
    this.subprodutoService.subprodutosAtualizados(this.obj.parser);
  }

  public continuaCompra() {
    this.goToCheckout = true;
    this.fecharDialog();
    setTimeout(() => {
      this.cro.dimensaoPaginas();
    }, 100);
  }

  onFocus(value?: string) {
    if (!!value) {
      this.cro.focusModal(value, 'subprodutos');
    }
  }
}
