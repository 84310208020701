import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RecursoSelecaoService {

  public dobroVelocidadeCelular$: Subject<any> = new Subject<any>();
  public dobroVelocidadeInternet$: Subject<any> = new Subject<any>();
  public recursoTv$: Subject<any> = new Subject<any>();


  constructor(
  ) { }

  possuiRecursoGratis(tipo, descricao) {

    if (tipo === 'celular') {
      this.dobroVelocidadeCelular$.next(descricao);
    } else if (tipo === 'internet') {
      this.dobroVelocidadeInternet$.next(descricao);
    } else if (tipo === 'tv') {
      this.recursoTv$.next(descricao);
    } else {
      this.dobroVelocidadeCelular$.next(false);
      this.dobroVelocidadeInternet$.next(false);
      this.recursoTv$.next(false);

    }
  }

}
