import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Components
import { ReguaTransacionalOfertaPlanoMovelComponent } from './regua-transacional-oferta-plano-movel.component';
import { CarrinhoService } from 'src/app/services/carrinho.service';
import { BucketS3Service } from 'src/app/services/bucket-s3.service';


@NgModule({
  declarations: [
    ReguaTransacionalOfertaPlanoMovelComponent
  ],
  imports: [
    CommonModule
  ],
  providers: [
    CarrinhoService,
    BucketS3Service,
    ReguaTransacionalOfertaPlanoMovelComponent
  ],
  exports: [
    ReguaTransacionalOfertaPlanoMovelComponent
  ]
})
export class ReguaTransacionalOfertaPlanoMovelModule { }
