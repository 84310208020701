export const environment = {
  production              : true,
  company                 : 'net',
  staticPath              : '/',
  orderPath               : 'https://api.amxrest.net/',
  apiPath                 : 'https://api.amxrest.net/v2/',
  apiNewPath              : 'https://api.amxrest.net/',
  coberturaViability      : 'https://planos3.claro.com.br/cobertura/api/available-technologies',
  coberturaHomepassed     : 'https://planos3.claro.com.br/cobertura/api/hps',
  coberturaContacts       : 'https://planos3.claro.com.br/cobertura/api/contacts',
  coberturaLeads          : 'https://planos3.claro.com.br/cobertura/api/leads',
  coberturaCaixa          : 'https://planos3.claro.com.br/cobertura/api/caixa',
  coberturaAccValidator   : 'https://planos3.claro.com.br/cobertura/api/acc-validator',
  coberturaSchedule       : 'https://planos3.claro.com.br/cobertura/api/schedule',
  coberturaFingerprint    : 'https://planos3.claro.com.br/cobertura/api/fingerprint',
  coberturaTotp           : 'https://planos3.claro.com.br/cobertura/api/totp',
  coberturaOrders         : 'https://planos3.claro.com.br/cobertura/api/orders',
  coberturaRiskAnalysis   : 'https://planos3.claro.com.br/cobertura/api/riskanalysis',
  ecomBff                 : 'http://localhost:3000/ecom-bff',
  apiAddress              : 'https://api-prd.clarobrasil.mobi/address/',
  gradeCanais             : 'https://planos.claro.com.br/gradedecanaisv2/',
  dataPath                : 'https://static.net.com.br/json/assine/',
  selosPath               : 'https://static.net.com.br/json/assine/selos/',
  subProductsPath         : 'https://static.net.com.br/json/assine/tvAdditional/',
  mind                    : 'https://planos.claro.com.br/',
  logosPath               : '/assets/img/logos/claro/',
  bucketS3Disclaimer      : 'https://assets.clarobrasil.mobi/ecommerce-config/texto-legal/disclaimer.json',
  faviconPath             : '/assets/icons/favicons/favicon.png',
  linkRegulamento         : 'https://cdn.celulardireto.com.br/Claro/Regulamento/Regulamento_Oferta_Claro_Controle.pdf',
  linkContrato            : 'https://www.claro.com.br/files/104379/x/a9c75118e9/internetposcontratodeprestacaodoservico_2022-10-27_registrado-1.pdf',
  linkPortabilidade       : 'https://assets.clarobrasil.mobi/ecommerce-config/regulamentos/Regra_de_Portabilidade.pdf',
  gtmAmbiente             : 'planos-claro-res',
  ordersTrack             : 'https://api.amxrest.net/orders/details',
  googleTrackingId        : 'UA-28840052-11',
  ecommerceConfig         : 'https://assets.clarobrasil.mobi/ecommerce-config/',
  bucketS3Host            : 'https://assets.clarobrasil.mobi',
  bucketS3Folder          : '/ecommerce-config',
  ecommerceResponsivo     : 'https://assets3.clarobrasil.mobi/ecommerce-config/ecommerce-responsivo/config/',
  claroBrasilMobi         : 'https://api-prd.clarobrasil.mobi/',
  mobileInfo              : 'https://api-prd.clarobrasil.mobi/identification/customer/mobile/info',
  // apiPromotions           : 'https://planos.claro.com.br/cobertura/api/promotions?city=',
  apiPromotions           : 'https://sit-ecom-bff-planos.clarobrasil.mobi/ecom-bff/promotions?city=',
  apiAnaliseCredito       : 'https://api.amxrest.net/credit-analysis',
  urlCobertura            : 'https://planos3.claro.com.br/cobertura',
  dynatraceScriptPath     : '/assets/js/dynatrace_script_prod.js',
  cmsUrl                  : 'https://www.claro.com.br',
  storyBlock: {
    api                   : 'https://api.storyblok.com/v2/cdn/stories/',
    // token                 : 'JXsCdOZFhoROI0wbzQbaNgtt', // Prod
    token                 : 'PP1FL5OznEAZUdDziBYDKwtt', // Dev
    cv                    : '',
    version               : 'draft',
  },
  coupons                 : 'https://api.amxrest.net/coupons/',
  jornadaNova             : 'https://planos3.claro.com.br/novo/',
  jwtPrivateKey                  : `-----BEGIN PRIVATE KEY-----
MIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQDFV7o86BTaGopK
E/eGBRa/Qnq1Z3aqyHvD7FvChxI1ZumnPjAdjcxaW+0d8tF1aiYWEPKZpIe+5CcV
2KlCeklXpZToUHudZC8/a8EHtOD8xHpRcXGU3qxUSNoAoAw7PkPT/Ph/TQhQI+5o
PmFpvPrr4DZjt0J/zlyhWUzpzuBHLaCFVDSLwuHbsLSN7dgeftRivWlA93MOzFRo
2LLzXMdQYlvO+pK0ePZ03ZFqrr9/XV03kuvylmB4C/pqFMnCaLk1SbEZAsB00Ab7
yv/Gp9B9wfFLQAh5/5gOr1W4znTZMiXDPJqEFKi1uJNTxTPzKMRaNUXiWTq61b03
wLyT0T6PAgMBAAECggEAHip0246WcWiRLxWyRphMi9v7qfMw3pZa5O4icK/5BpS+
rsQA0uO0EzPuIR/XCN27CLB6hSXzYii6GMrPxMuwnmc/RevXmY2Q817jWOw7YCgg
koaG2DzZluC0Alh0xjGUaLlIuxzpYma7sM+oUGOE772MUt3WmnbPDhL6gNsQyZBY
/+W2LUmOlk35IWSjQJ1t6K/XRsPBn8USJClzCM5mABhGlMevO3paetjRbM8sId90
T/u5fIm88I5ounyu21oAMG1H9VdVsIm3rIKT0YmGGsMzxHpnLSWEqdmhjoIYUMgi
v4FWLt92jos0WDhD/cnQ1MXn9qE/s88hC49uWDK94QKBgQD0GiXU2EdEf0zTz0Jp
PVQIkT6zJbmVuSopC+cf4pnbKU+ZcE9cYGlpbAW/DbyXIxn7n6yqDiXJWZ4wNST3
0mq3Sc1Ns+utKoaysZIhDV8D0sgxACk3s0ApE8x79lCEPFUAzifzzroLs+fmKtFP
U6h/jVCCUMhdQLSWTe895WoPaQKBgQDO9iAp9ghEYqmAXw1xGKct/mzgJx+ZRU/w
Wlj8i73kraIzwGFpdTB3LkWlWLBOddPo8RT70QngZnTpg1n8+DLdavZ5tpMJew8C
dc+UQahHRMb8wyMxR5/ahwRKWNxQuXkJKeyXKo28YyGBroiPk/SgN7DEreZcP74v
YTHI5kmXNwKBgHdJF9etYRZOByMyk0eOWrRdFgDBnLFX7Qxl7qocOAdrZqSax41i
5+BGk4BLnC9XTekynFP12e1NSApSzh/0yYWMITGpjK/wqiZEtB5GxHm/1Whspbe8
dswcCr9BHROh0AA2vjtceHojjl1h6ysSvLKoQgGMrH01k4onx5O9uliRAoGBAJ0c
POh9zGLVnyVDgznC5E7HITG8oYFCG+AzRfBZyQsLQOIWfhyHiQZAi0BtJlI5omfo
c+yCEQRX4xLN0wd2B28M3xUgo1iEIa3Vb4J1oWCQ9po1LMchP1muO3SRJpblZduu
BtvuwvArNOl0bFjKkd76x+dWuFR5U1A2cYu4Pk9bAoGBALkREQQUjRV0ByZcv14H
1rtEHcQLyKOG528VqMMi9n4EpreJeZBmLUgodqUpsZSy7OKkZ7c8U6pN3evGLNca
qFp6be1F2xyJ2i2SHJv/HZcfUvsNsa5JzeoagePHVguuwc4Ki2k0dbseLnlgZQMp
QpkMGO0YNUrbwXMDYE98td3e
-----END PRIVATE KEY-----`
};



// export const environment = {
//   production              : true,
//   company                 : 'net',
//   staticPath              : '/',
//   orderPath               : 'https://api.amxrest.net/',
//   apiPath                 : 'https://api3.amxrest.net/v2/',
//   apiNewPath              : 'https://api3.amxrest.net/',
//   coberturaViability      : 'https://planos3.claro.com.br/cobertura/api/available-technologies',
//   coberturaHomepassed     : 'https://planos3.claro.com.br/cobertura/api/hp-api',
//   coberturaContacts       : 'https://planos3.claro.com.br/cobertura/api/contacts',
//   coberturaCaixa          : 'https://planos3.claro.com.br/cobertura/api/caixa',
//   apiAddress              : 'https://api-prd.clarobrasil.mobi/address/',
//   gradeCanais             : 'https://planos.claro.com.br/gradedecanaisv2/',
//   dataPath                : 'https://static.net.com.br/json/assine/',
//   selosPath               : 'https://static.net.com.br/json/assine/selos/',
//   subProductsPath         : 'https://static.net.com.br/json/assine/tvAdditional/',
//   mind                    : 'https://planos.claro.com.br/',
//   logosPath               : '/assets/img/logos/claro/',
//   bucketS3Disclaimer      : 'https://assets3.clarobrasil.mobi/ecommerce-config/texto-legal/disclaimer.json',
//   faviconPath             : '/assets/icons/favicons/favicon.png',
//   linkRegulamento         : 'https://cdn.celulardireto.com.br/Claro/Regulamento/Regulamento_Oferta_Claro_Controle.pdf',
//   linkContrato            : 'https://www.claro.com.br/files/104379/x/a9c75118e9/internetposcontratodeprestacaodoservico_2022-10-27_registrado-1.pdf',
//   linkPortabilidade       : 'https://assets3.clarobrasil.mobi/ecommerce-config/regulamentos/Regra_de_Portabilidade.pdf',
//   gtmAmbiente             : 'planos-claro-res',
//   ordersTrack             : 'https://api.amxrest.net/orders/track',
//   googleTrackingId        : 'UA-28840052-11',
//   ecommerceConfig         : 'https://assets3.clarobrasil.mobi/ecommerce-config/',
//   bucketS3Host            : 'https://assets3.clarobrasil.mobi',
//   bucketS3Folder          : '/ecommerce-config',
//   ecommerceResponsivo     : 'https://assets3.clarobrasil.mobi/ecommerce-config/ecommerce-responsivo/config/',
//   claroBrasilMobi         : 'https://api-prd.clarobrasil.mobi/',
//   apiPromotions           : 'https://planos3.claro.com.br/cobertura/api/promotions?city=',
//   apiAnaliseCredito       : 'https://api.amxrest.net/credit-analysis',
//   urlCobertura            : 'https://planos3.claro.com.br/cobertura',
//   dynatraceScriptPath     : '/assets/js/dynatrace_script_prod.js',
//   cmsUrl                  : 'https://www.claro.com.br',
//   storyBlock: {
//     api                   : 'https://api.storyblok.com/v2/cdn/stories/',
//     token                 : 'JXsCdOZFhoROI0wbzQbaNgtt', // Prod
//     // token                 : 'PP1FL5OznEAZUdDziBYDKwtt', // Dev
//     cv                    : '',
//     version               : 'draft',
//   },
//   coupons               : 'https://api.amxrest.net/coupons/',
// };


// export const environment = {
//   production              : false,
//   company                 : 'net',
//   staticPath              : '/',
//   orderPath               : 'https://api.amxrest.net/',
//   apiPath                 : 'https://api.amxrest.net/v2/',
//   apiNewPath              : 'https://api3.amxrest.net/',
//   apiAddress              : 'https://api-pet.clarobrasil.mobi/address/',
//   // apiAddress              : 'https://api-dev.mindteam.me/address/',
//   gradeCanais             : 'https://planos.claro.com.br/gradedecanaisv2/',
//   dataPath                : 'https://static.net.com.br/json/assine/',
//   selosPath               : 'https://static.net.com.br/json/assine/selos/',
//   subProductsPath         : 'https://static.net.com.br/json/assine/tvAdditional/',
//   mind                    : 'https://planos.claro.com.br/',
//   logosPath               : '/assets/img/logos/claro/',
//   bucketS3Disclaimer      : 'https://assets.clarobrasil.mobi/ecommerce-config/texto-legal/disclaimer.json',
//   faviconPath             : '/assets/icons/favicons/favicon.png',
//   linkRegulamento         : 'https://cdn.celulardireto.com.br/Claro/Regulamento/Regulamento_Oferta_Claro_Controle.pdf',
//   linkContrato            : 'https://www.claro.com.br/files/104379/x/a9c75118e9/internetposcontratodeprestacaodoservico_2022-10-27_registrado-1.pdf',
//   linkPortabilidade       : 'https://assets.clarobrasil.mobi/ecommerce-config/regulamentos/Regra_de_Portabilidade.pdf',
//   gtmAmbiente             : 'planos-claro-res',
//   ordersTrack             : 'https://api3.amxrest.net/orders/track',
//   googleTrackingId        : 'UA-28840052-11',
//   ecommerceConfig         : 'https://assets.clarobrasil.mobi/ecommerce-config/',
//   bucketS3Host            : 'https://assets.clarobrasil.mobi',
//   bucketS3Folder          : '/ecommerce-config',
//   ecommerceResponsivo     : 'https://assets.clarobrasil.mobi/ecommerce-config/ecommerce-responsivo/config/',
//   claroBrasilMobi         : 'https://api-prd.clarobrasil.mobi/',
//   apiPromotions           : 'https://planos3.claro.com.br/cobertura/api/promotions?city=',
//   apiAnaliseCredito       : 'https://api.amxrest.net/credit-analysis',
//   urlCobertura            : 'https://planos3.claro.com.br/cobertura',
//   dynatraceScriptPath     : '/assets/js/dynatrace_script_pet.js',
//   storyBlock: {
//     api                   : 'https://api.storyblok.com/v2/cdn/stories/',
//     token                 : 'JXsCdOZFhoROI0wbzQbaNgtt', // Prod
//     // token                 : 'PP1FL5OznEAZUdDziBYDKwtt', // Dev
//     cv                    : '',
//     version               : 'draft',
//   }
// };
