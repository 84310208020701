<footer id="main-footer" class="mdn-Footer mdn-Footer--simple mdn-Footer--signature
                            {{
                                (router.url?.indexOf('/checkout') === -1
                                && router.url?.indexOf('/monte-sua-combinacao') === -1)
                                && isSafari ? 'footer-isSafari-true' : ''
                            }}"
        role="contentinfo"
        [class.footer-confirmacao]="router.url?.indexOf('/checkout/confirmacao') !== -1 ||
                                    router.url?.indexOf('/500') !== -1 ||
                                    router.url?.indexOf('/404') !== -1
                                    "
        [class.footer-monte-sua-combinacao]="router.url?.indexOf('/monte-sua-combinacao') !== -1"
                                    >
    <div class="mdn-Container">
        <div class="mdn-Footer-content {{router.url}}">
            <div class="mdn-Footer-social-and-disclaimer">
                <ul class="mdn-Footer-list" [class.hide]="!(router.url?.indexOf('/cobertura') >= 0)">
                    <li class="mdn-Footer-list-item" *ngFor="let socialLink of arraySocialLinks">
                        <a
                            class="mdn-Footer-list-item-socialLink gtm-link-event"
                            [attr.href]="(socialLink.href && socialLink.target !== '_blank') ? socialLink.href : null"
                            [attr.target]="socialLink.target"
                            [attr.title]="socialLink.title"
                            [attr.rel]="socialLink.rel"
                            data-gtm-event-category=gtmCategory+-+socialLink.name
                            data-gtm-event-action="socialLink.dataGtmEventAction"
                            data-gtm-event-label="socialLink.name"
                            (click)="openMdnModalSocialLinks(socialLink)"
                            >
                            
                            <i aria-hidden="true" aria-label="socialLink.title" [attr.class]="'mdn-Icon-'+socialLink.name+' mdn-Icon--sm'"></i>
                        </a>
                        <div [attr.id]="'modal-target-blank-'+socialLink.name" class="mdn-Modal mdn-Modal--sm modal-target-blank" *ngIf="socialLink.target === '_blank'">
                            <div class="mdn-Modal-content">
                            <div class="mdn-Modal-header">
                                <button class="mdn-Button-header-close mdn-Button mdn-Button--icon mdn-Button--secondary gtm-link-event" aria-label="Fechar modal"  data-gtm-event-category="'planos-claro-res:modal-target-blank'" data-gtm-event-action="clique:botao" data-gtm-event-label="fechar-modal" >
                                <em class="mdn-Icon-fechar mdn-Icon--md"></em>
                                </button>
                            </div>
                            <div class="mdn-Modal-body">
                                <p class="mdn-Subtitle mdn-Subtitle--sm">
                                    Você será redirecionado para o {{ socialLink.title }}.
                                </p>
                            </div>
                            <div class="mdn-Modal-footer">
                                <div class="mdn-Modal-footer-end">
                                <a [attr.href]="socialLink.href ? socialLink.href : null" target="_blank" class="mdn-Button mdn-Button--primary mdn-Button-close gtm-link-event"  data-gtm-event-category="'planos-claro-res:modal-target-blank'" data-gtm-event-action="clique:botao" [attr.data-gtm-event-label]="socialLink.name" >Ok</a>
                                </div>
                            </div>
                            </div>
                        </div>
                    </li>
                </ul>
                <div class="mdn-Footer-disclaimer">
                    <p class="mdn-Text mdn-Text--caption mdn-Text--inverse">
                        <a class="mdn-Footer-disclaimer-link gtm-link-event"
                            href="https://www.claro.com.br/privacidade/politica-de-privacidade" rel="noopener" target="_blank"
                            [attr.data-gtm-event-category]="gtmCategory+':rodape'"
                            data-gtm-event-action="clique:link" 
                            data-gtm-event-label="politica-de-privacidade">
                            Política de Privacidade
                        </a>
                        <a class="mdn-Footer-disclaimer-link gtm-link-event"
                            href="https://www.claro.com.br/privacidade" rel="noopener" target="_blank"
                            [attr.data-gtm-event-category]="gtmCategory+':rodape'"
                            data-gtm-event-action="clique:link" 
                            data-gtm-event-label="politica-de-privacidade">
                            Portal de Privacidade
                        </a>
                        <a class="mdn-Footer-disclaimer-librasLink gtm-link-event"
                            (click)="showHandTalkAndOpenLibrasContainer(); cro.clickButtonNew('geral', 'libras', '', ':')"
                            aria-label="libras" title="Assistente de Libras">
                        <span class="mdn-Icon-libras mdn-Icon--sm"></span>
                        </a>
                    </p>
                    <p class="mdn-Text mdn-Text--caption mdn-Text--inverse">
                        <strong>© {{yearFooter | date: "yyyy"}} Claro - CNPJ: 40.432.544/0001- 47 <br/> Rua Henri Dunant, 780 - São Paulo - SP</strong>
                    </p>
                </div>
            </div>            
            <div class="mdn-Footer-logo"></div>
        </div>
    </div>
</footer>
