<div>
  <div
    class="drawer {{ config.data.side | lowercase }}"
    (click)="onOverlayClicked($event)"
  >
    <div
      [@modalAnimation]="animation"
      (@modalAnimation.done)="closed($event)"
      class="drawer-content"
      (click)="onDialogClicked($event)"
    >
      <div id="alert-drawer"></div>
      <div class="btn-close-row">
        <button
          class="
            mdn-Button-header-close
            mdn-Button
            mdn-Button--icon
            mdn-Button--secondary
          "
          (click)="close()"
          [attr.data-gtm-event-category]="'planos-claro-res:' + nomeGtm"
          data-gtm-event-action="clique:botao"
          data-gtm-event-label="fechar-drawer"
        >
          <em class="mdn-Icon-fechar mdn-Icon--md"></em>
        </button>
      </div>
      <div>
        <ng-template appModalHost></ng-template>
      </div>
    </div>
  </div>
</div>
