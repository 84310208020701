import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SmsEcareService {
  public ENV = environment;
  private SMS_ECARE_URL: string = `${this.ENV.claroBrasilMobi}sms-ecare`;

  constructor(
    public http: HttpClient
  ) { }

  sendSmsAndGetToken(msisdn: string): Observable<any> {
    return this.http.post<any>(`${this.SMS_ECARE_URL}/${msisdn}`, msisdn);

    // Mock return
    // return of(
    //   {
    //     "data": {
    //         "token": "Ox6L+GCqaiL10u8s3TxCIAwLq/v3/iqXRloB9f7M/JMt6B4hFAhroMrrM89ZSNdYcVwUsnTSug/tGfkJlbf1BltpCLm5OutIH/L2E1Yhu48y7FY9U6Mc+v8nlN2Z7bNg+VsTC9tIDrbwfm6QNFaZqA0in05mPY2QAGSaZbAPEcP6GoLsHTwhSMOr+Xijx9BJ/TfUDYAGCiqgNinMaV1iuC+7SluFIuFxTX/FOtQST9qHXdjH116olLZkm72q0fVbGpfAQAqkTgJhft0QXDddW9FXHZAGlBYsyrKfMa50MOwQo27LJKS1lY63UjSOucUTXpikaNdncWzuStbkVYjhdpkUJ/W+M0FZlHXsdH86u6/G4x2Vzk2q7yx2tIRChTVkfWOn0aG7ZSzPSV6n5YojEc9Zuw/ExOAS/zLeLutd/+TjAgbf/tFyeNT2S3/hmQxB"
    //     },
    //     "headers": {
    //         "Access-Control-Allow-Origin": "*"
    //     },
    //     "statusCode": 200,
    //     "meta": {
    //         "code": "200",
    //         "message": "OK"
    //     }
    //   }
    // )
  }

  verifyCode(code: string, smstoken: string): Observable<any> {
    const encodedSmsToken = encodeURIComponent(smstoken)
    return this.http.post<any>(`${this.SMS_ECARE_URL}/verify/${code}?token=${encodedSmsToken}`, null);

    // Mock return
    // return of(
    //   {
    //     "data": {
    //       "messageType": "1",
    //       "tenantKey": "ecommerce",
    //       "to": "63992930308",
    //       "msisdn": "63992930308",
    //       "message": "Claro: Seu codigo e 142812",
    //       "exp": "1729187331",
    //       "token": "142812"
    //     },
    //     "headers": {
    //       "Access-Control-Allow-Origin": "*"
    //     },
    //     "statusCode": 200,
    //     "meta": {
    //       "code": "200",
    //       "message": "OK"
    //     }
    //   }
    // )
  }
}
