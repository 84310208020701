import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.dev';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {
  constructor(private http: HttpClient) { }

  getUserIP(): Observable<any> {
    return this.http.get('https://api.ipify.org?format=json')
  }

  getASOFromApi(ip: string): Observable<any> {
    return this.http.get(`${environment.cmsUrl}/api/segments/network?ip=${ip}`)
  }
}