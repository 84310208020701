import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { ListUpdatedPlansModel } from '../../models/mobile-client-authenticator.model';
import { environment } from 'src/environments/environment';
import { CarrinhoService } from 'src/app/services/carrinho.service';
import { BannerPromocionalService } from 'src/app/modules/monte-sua-combinacao/modals/banner-promocional/banner-promocional.service';
import { CroService } from 'src/app/services/cro.service';
import { MdnModalService } from 'src/app/shared/mondrian-components/mdn-modal/mdn-modal.service';

import { JaEClienteComponent } from 'src/app/modules/checkout/modals/ja-e-cliente/ja-e-cliente.component'

export enum storageNamesEnum {
  CPF = 'claro-movel-cpf',
  MSISDN = 'claro-movel-phone',
  SMS_ECARE_TOKEN = 'sms-ecare-token',
  DE_PARA = 'cliente-movel-de-para-mobile-plan',
  MOBILE_INFO_RETURN = 'customer-mobile-info-api-return',
  CLIENTE_CLARO_MOVEL = 'cliente-claro-movel',
  MODAL_CONFIRA_O_QUE_GANHOU_SHOWED = 'ModalConfiraOQueGanhouShowed',
  CUSTOMER_NAME = 'customerName',
  SHOULD_SHOW_ALERT = 'shouldShowAlert'
}

export enum stepsNamesEnum {
  SEND_CODE = 'send-code',
  MOBILE_CLIENT_VALIDATION = 'mobile-client-validation',
  NOT_MOBILE_CUSTOMER = 'not-mobile-customer',
  API_ERROR = 'api-error'
}

@Injectable({
  providedIn: 'root'
})
export class MobileClientValidationService {
  updateCustomerName$ = new Subject<any>();
  isClienteMovelChanged$ = new Subject<boolean>();
  changeStep$ = new Subject<string>();
  setCodeError$ = new Subject<any>();
  closeDialog$ = new Subject<void>();
  customerValidated$ = new Subject<void>();
  updatePersonalData$ = new Subject<void>();
  
  croOrigin = 'cliente-claro-movel-beneficios';
  
  listUpdatedPlans: any;
  private isClienteMovel: boolean = false;
  private match: boolean = false;
  private cpf: string;
  private msisdn: string;
  private token: string;
  private code: string;
  private customerPlanData: any;
  private usuarioDaBase: string;
  private upgradeDePara: any;
  private modalConfiraOQueGanhouShowed: boolean = false;
  private verifiedMobileCustomerByOriginid: boolean = false;
  private shouldShowAlert: boolean = false;
  private flow: string;
  private modalAction: string;
  private isModalOpen = false;

  constructor(
    private http: HttpClient,
    private carrinho: CarrinhoService,
    private bannerPromocionalService: BannerPromocionalService,
    private cro: CroService,
    public modal: MdnModalService
  ) {
    const customerPlanDataStore = sessionStorage.getItem(storageNamesEnum.MOBILE_INFO_RETURN)
    if (!!customerPlanDataStore) {
      this.setCustomerPlanData(JSON.parse(customerPlanDataStore));
    }
  }

  setFlow(flow_: string) {
    this.flow = flow_;
  }
  getFlow(): string {
    return this.flow;
  }

  setIsModalOpen(isModalOpen_: boolean) {
    this.isModalOpen = isModalOpen_;
  }
  getIsModalOpen(): boolean {
    return this.isModalOpen;
  }

  getIsClienteMovel(): boolean {
    return this.isClienteMovel
  }
  setIsClienteMovel(value_: boolean) {
    this.isClienteMovel = value_
    sessionStorage.setItem(storageNamesEnum.CLIENTE_CLARO_MOVEL, value_.toString());
  }

  getMatch(): boolean {
    return this.match
  }
  setMatch(value_: boolean) {
    this.match = value_
  }

  getCpf(): string {
    return this.cpf
  }
  setCpf(cpf_: string) {
    this.cpf = cpf_
  }
  deleteCpf() {
    this.cpf = undefined;
    sessionStorage.removeItem(storageNamesEnum.CPF)
  }

  getMsisdn(): string {
    return this.msisdn
  }
  setMsisdn(msisdn_: string) {
    this.msisdn = msisdn_
  }
  deleteMsisdn() {
    this.msisdn = undefined;
    sessionStorage.removeItem(storageNamesEnum.MSISDN);
  }

  getToken(): string {
    return this.token
  }
  setToken(token_: string) {
    this.token = token_
    sessionStorage.setItem(storageNamesEnum.SMS_ECARE_TOKEN, token_);
  }

  getCode(): string {
    return this.code
  }
  setCode(code_: string) {
    this.code = code_
  }

  getCustomerPlanData(): any {
    return this.customerPlanData
  }
  setCustomerPlanData(data: any) {
    this.customerPlanData = data;
    sessionStorage.setItem(storageNamesEnum.MOBILE_INFO_RETURN, JSON.stringify(data));
  }
  deleteCustomerPlanData() {
    this.customerPlanData = undefined;
    sessionStorage.removeItem(storageNamesEnum.MOBILE_INFO_RETURN);
  }

  getUsuarioDaBase(): any {
    return this.usuarioDaBase
  }
  setUsuarioDaBase(data: string) {
    this.usuarioDaBase = data;
  }

  setVerifiedMobileCustomerByOriginid(value: boolean) {
    this.verifiedMobileCustomerByOriginid = value;
    sessionStorage.setItem('verified-mobile-customer-by-originid', value.toString())
  }

  getVerifiedMobileCustomerByOriginid() {
    return this.verifiedMobileCustomerByOriginid
  }

  getUpgradeDePara(): any {
    return this.upgradeDePara
  }
  setUpgradeDePara(data: any) {
    this.upgradeDePara = data;
    sessionStorage.setItem(storageNamesEnum.DE_PARA, JSON.stringify(data));
  }
  deleteUpgradeDePara() {
    this.upgradeDePara = undefined;
    sessionStorage.removeItem(storageNamesEnum.DE_PARA);
  }

  setModalConfiraOQueGanhouShowed(value: boolean) {
    this.modalConfiraOQueGanhouShowed = value;
    sessionStorage.setItem(storageNamesEnum.MODAL_CONFIRA_O_QUE_GANHOU_SHOWED, value.toString())
  }
  deleteModalConfiraOQueGanhouShowed() {
    this.modalConfiraOQueGanhouShowed = undefined;
    sessionStorage.removeItem(storageNamesEnum.MODAL_CONFIRA_O_QUE_GANHOU_SHOWED)
  }

  deleteSmsEcareToken() {
    sessionStorage.removeItem(storageNamesEnum.SMS_ECARE_TOKEN)
  }


  getMobileCustomerFirstName(): string {
    const fullName = this.getCustomerPlanData()?.customerName;
    if (!!fullName) {
      let firstName = fullName.split(' ')[0];
      return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    }
    return null;
  }

  setCustumerName(customerName: string) {
    sessionStorage.setItem(storageNamesEnum.CUSTOMER_NAME, customerName);
    this.updateCustomerName$.next(customerName);
  }
  deleteCustumerName() {
    sessionStorage.removeItem(storageNamesEnum.CUSTOMER_NAME);
  }

  getListUpdatedPlans(): Observable<ListUpdatedPlansModel[]> {
    if (this.listUpdatedPlans === undefined) {
      return this.http.get<ListUpdatedPlansModel[]>(
        environment.ecommerceResponsivo + 'mobile-customer-identification/listUpdatedPlans.json',
        { responseType: 'json' }
      );
    } else {
      return of(this.listUpdatedPlans);
    }
  }

  setShouldShowAlert(value: boolean) {
    this.shouldShowAlert = value;
    sessionStorage.setItem(storageNamesEnum.SHOULD_SHOW_ALERT, value.toString())
  }
  getShouldShowAlert(): boolean {
    return this.shouldShowAlert
  }

  openBanner(): void {
    this.setIsClienteMovel(true);
    const cart = this.carrinho.getCart();
    this.bannerPromocionalService.openBanner(cart);
  }

  abrirDialogClienteConectadoMobileClient(cpf: string, flow: string = ''): void {
    if (!this.getIsModalOpen()) {
      this.setIsModalOpen(true);
      this.cro.openModal(`ja-e-cliente`);
      const modalConfig = {
        data: {
          size: 'sm',
          titulo: 'Olá, notamos que você já é cliente Claro!',
          disableClose: true,
          type: 'ja-e-cliente',
          cpf: cpf,
          flow: flow,
          modalAction: this.modalAction === 'validates-plan-addition' ? 'validates-plan-addition' : '',
        }
      };
      this.modal.open(JaEClienteComponent, modalConfig);
    }
  }
}
