import { Injectable } from '@angular/core';

import { UtilsService } from './utils.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PortabilidadeService {

  public portabilidadeReady$: Subject<any> = new Subject<any>();

  constructor(
    public utils: UtilsService
  ) { }

  adicionaCookiePortability(obj, tipo) {
    tipo !== 'celular' ?
      this.utils.storage.setItem('cookiePortabilityFone', JSON.stringify(obj)) :
      this.utils.storage.setItem('cookiePortabilityCelular', JSON.stringify(obj));

    this.portabilidadeReady$.next(true);
  }

  removeCookiePortability(tipo) {
    tipo !== 'celular' ?
      this.utils.storage.removeItem('cookiePortabilityFone') :
      this.utils.storage.removeItem('cookiePortabilityCelular');

    this.portabilidadeReady$.next(true);
  }

  getCookiePortabilityFone() {
    let data = {};
    if (this.utils.storage.getItem('cookiePortabilityFone') !== '') {
      data = JSON.parse(this.utils.storage.getItem('cookiePortabilityFone'));
    }
    return data;
  }

  getCookiePortabilityCelular() {
    let data = { productId: null, area: '', number: '', carrier: 'claro', valid: true };
    if (this.utils.storage.getItem('cookiePortabilityCelular') !== '') {
      data = JSON.parse(this.utils.storage.getItem('cookiePortabilityCelular'));
    }
    return data;
  }
}
