import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SanitizeService {

  constructor() { }

  sanitizePhone(phone: string): string {
    return phone.replace(/[^0-9]+/g, '');
  }

  sanitizeCpf(cpf: string): string {
    return cpf.replace(/\D/g, '');
  }
}
