import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { StoryBlockService } from './storyblock.service';
import { ALLOWED_NETWORKS_REDE_MOVEL } from '../global-consts/allowed-networks-rede-movel';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  nothingToDo = [
    'Tab',
    'Shift',
    'ArrowRight',
    'ArrowLeft',
    'ArrowUp',
    'ArrowUp',
    'ArrowDown',
  ];
  createMask = (pattern: (string | RegExp)[]): (string | RegExp)[] => pattern.map(x => x instanceof RegExp ? /\d/ : x);

  maskCEP = this.createMask(['', '', '', '', '', '-', '', '', '']);
  maskOnlyNumbers = this.createMask(['', '', '', '', '', '', '', '']);
  maskCelPhone = this.createMask(['(', '', '', ')', ' ', '', '', '', '', '', '-', '', '', '', '']);
  maskPhone = this.createMask(['(', '', '', ')', ' ', '', '', '', '', '-', '', '', '', '', '']);
  maskCPF = this.createMask(['', '', '', '.', '', '', '', '.', '', '', '', '-', '', '']);
  maskRG = this.createMask(['', '', '', '', '', '', '', '', '', '', '', '', '', '']);
  maskCpfCnpj = this.createMask(['', '', '', '.', '', '', '', '.', '', '', '', '-', '', '', '']);
  maskCNPJ = this.createMask(['', '', '.', '', '', '', '.', '', '', '', '/', '', '', '', '', '-', '', '']);
  maskOnlyPhone = this.createMask(['', '', '', '', '', '-', '', '', '', '']);
  maskOnlyPhoneLand = this.createMask(['', '', '', '', '-', '', '', '', '']);

  telefoneMask = function (telefone: any) {
    if (telefone.length === 14) {
      return this.createMask(['(', '', '', ')', ' ', '', '', '', '', '-', '', '', '', '']);
    } else {
      return this.createMask(['(', '', '', ')', ' ', '', '', '', '', '', '-', '', '', '', '']);
    }
  };
  netResidencialPlanos = ['internet', 'celular', 'tv', 'fone'];
  netEmpresasPlanos = ['internet', 'celular', 'fone', 'tv'];
  public esconderContainerTextoLegal = true;
  public hasBanner = false;
  public storage: any;
  public brand = undefined;
  public cepConfirm = false;
  public numberConfirm = false;
  public origemClaro: any;
  public iconsUriPath = environment.ecommerceResponsivo + 'assets/img/icons/';
  public cacheIsRedeMovel: boolean | null = null;
  public token: string = null;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  lastRouteUrlBeforeRedirectToHome: string = '';
  lastRouteAdicionais: string = '';
  public consultarCobertura$: Subject<any> = new Subject<any>();
  public consultarCoberturaSubscription: Subscription;

  constructor(
    public cookieService: CookieService,
    private http: HttpClient,
    public router: Router,
    public sanitizer: DomSanitizer,
    public story: StoryBlockService
  ) {
    this.storage = {
      storageAvailable: this.storageAvailable,
      cookieService: this.cookieService,
      getItem: this.storageGetItem,
      setItem: this.storageSetItem,
      removeItem: this.storageRemoveItem,
      clear: this.storageClear,
      window: window,
      clearAddressData: this.clearAddressData,
      clearCatalogData: this.clearCatalogData,
      clearCookiesData: this.clearCookiesData,
      clearDadosDeIdentificacaoMovel: this.clearDadosDeIdentificacaoMovel,
    };
  }
  getHttpHeader(fingerprint_: string) {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-channel': 'ecommerce',
        'x-segment': 'pf',
        'x-partner': '',
        'x-customer-journey': 'prospect',
        'x-fingerprint': fingerprint_
      })
    }
  }

  fillScheduleDateValue(event) {
    const id = event.target.id;
    if (event.target.value.replace(/[/]/g, '').length >= 8) {
      (<HTMLInputElement>document.getElementById(id)).value =
        event.target.value;
      const name =
        id === 'scheduleDate1'
          ? 'schedulePeriod1Morning'
          : 'schedulePeriod2Morning';
      document.getElementById(name).focus();
    }
  }

  replaceCharacters(text) {
    const from = "áàãâéêíóôõúç '",
      to = 'aaaaeeiooouc__';

    for (let x = 0; x < text.length; x++) {
      const idx = from.indexOf(text[x]);
      if (idx > -1) {
        text = text.replace(text[x], to[idx]);
      }
    }

    return text;
  }

  generateToken(): Observable<any> {
    let text = '';
    const nameAplication = 'assine';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const myDate = new Date();
    const withOffset = myDate.getTime();
    const obj = { sessionId: '' };

    for (let i = 0; i < 12; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    obj.sessionId = window.btoa(nameAplication + '.' + text + '.' + withOffset);


    return this.http.post(
      `${environment.claroBrasilMobi}identification/credentials/token/generate`,
      obj,
      this.httpOptions
    );

    // return of (
    //   {
    //     "statusCode": 200,
    //     "meta": {
    //       "code": "200",
    //       "message": "OK"
    //     },
    //     "data": {
    //       "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwYXNzIjoiY0BQQSE1NW8iLCJpYXQiOjE2NTUxMjk4MzF9.WpQQsOffXcHImiss7w9-vbhc_3HC0zx3GmKgLafMpA8"
    //     }
    //   }
    // );
  }

  orderStatus(cpf: any, token: any, fingerprint_: string): Observable<any> {
    const obj = { document: cpf, token: token };
    const httpOptions = {
      headers: new HttpHeaders({
        'x-fingerprint': fingerprint_
      }),
    };
    return this.http.post(`${environment.ordersTrack}`, obj);
  }

  getUrlParameter(sParam) {
    return decodeURIComponent(window.location.search.substring(1))
      .split('&')
      .map((v: any) => {
        return v.split('=');
      })
      .filter((v: any) => {
        return v[0] === sParam ? true : false;
      })
      .reduce((_prev: any, curv: any) => {
        return curv[1];
      }, undefined);
  }

  ViewPort() {
    const w = Math.max(
      document.documentElement.clientWidth,
      window.innerWidth || 0
    );

    return w;
  }

  heightScreen() {
    return window.innerHeight;
  }

  storageAvailable(type: string) {
    const storage = window[type];
    try {
      const x = '__storage_test__';
      storage.setItem(x, x);
      storage.removeItem(x);
      return true;
    } catch (e) {
      return (
        e instanceof DOMException &&
        // everything except Firefox
        (e.code === 22 ||
          // Firefox
          e.code === 1014 ||
          // test name field too, because code might not be present
          // everything except Firefox
          e.name === 'QuotaExceededError' ||
          // Firefox
          e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
        // acknowledge QuotaExceededError only if there's something already stored
        storage.length !== 0
      );
    }
  }

  storageGetItem(name: string) {
    if (this.storageAvailable('sessionStorage')) {
      return sessionStorage.getItem(name) || '';
    } else {
      return this.cookieService.get(name) || '';
    }
  }

  storageSetItem(name: string, value: string) {
    if (this.storageAvailable('sessionStorage')) {
      sessionStorage.setItem(name, value);
    } else {
      this.cookieService.set(name, value);
    }
  }

  storageRemoveItem(name: string) {
    if (this.storageAvailable('sessionStorage')) {
      sessionStorage.removeItem(name);
    } else {
      this.cookieService.delete(name);
    }
  }

  storageClear() {
    if (this.storageAvailable('sessionStorage')) {
      sessionStorage.clear();
    } else {
      this.cookieService.deleteAll();
    }
  }
  repositionArray(data, id) {
    data.sort(function (x, y) {
      return x.id === id ? -1 : y.id === id ? 1 : 0;
    });

    data.reverse();
    return data;
  }
  stripTags(str: string) {
    return str !== undefined ? str.replace(/<[^>]+>/gim, '') : '';
  }

  validateCPF(cpf: string): boolean {
    cpf = cpf.padStart(11, '0');
    if (/^(\d)\1{10}$/.test(cpf)) return false;

    let sum = 0, remainder;
    for (let i = 1; i <= 9; i++) sum += parseInt(cpf.substring(i - 1, i)) * (11 - i);
    remainder = (sum * 10) % 11;
    if ((remainder == 10) || (remainder == 11)) remainder = 0;
    if (remainder != parseInt(cpf.substring(9, 10))) return false;

    sum = 0;
    for (let i = 1; i <= 10; i++) sum += parseInt(cpf.substring(i - 1, i)) * (12 - i);
    remainder = (sum * 10) % 11;
    if ((remainder == 10) || (remainder == 11)) remainder = 0;
    return remainder == parseInt(cpf.substring(10, 11));
  }

  formatNumbers(value: string): string {
    return value.replace(/\.|\-|\_|\/|\(|\)/g, '').replace(/([^\w]+|\s+)/g, '');
  }

  formataDinheiroCro(money: string) {
    return money.replace(/(\d{1,3})(\d{2})/, '$1.$2');
  }

  formataCepHeader(cep: string) {
    return cep.replace(/(\d{5})(\d{3})/, '$1-$2');
  }

  formataEmail(email: string): string {
    return email.toLowerCase();
  }

  formataElementoId(acao: string, tipo: string, nome: string, rota?: string): string {
    tipo = tipo != '' ? '-' + tipo : tipo;
    rota = rota ? '-' + rota : '';
    return acao + tipo + '-' + this.convertToSanitizer(nome) + rota;
  }

  kebabCase(str: string): string {
    // Remove acentuação
    let semAcentos = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

    // Substitui espaços por "-"
    let kebab = semAcentos.replace(/\s+/g, "-");

    // Converte todas as letras para minúsculas
    kebab = kebab.toLowerCase();

    return kebab;
  }
  public getBrandTag() {
    if (window.location.pathname.indexOf('/assine-empresas') !== -1) {
      return this.brand + '-empresas';
    } else {
      return this.brand;
    }
  }

  public setBrandTag(brand) {
    if (!brand) {
      this.brand = undefined;
    } else if (brand === 'satelite' || brand === 'clarotv') {
      this.brand = 'claro-satelite';
    } else if (brand === 'celular') {
      this.brand = 'claro-movel';
    } else {
      this.brand = 'claro-cabo';
    }
  }

  public cepToMonteSuaCombinacao(view) {
    if (
      window.location.pathname.indexOf('/assine-empresas') !== -1 &&
      view === '/modal-cep/inicial' &&
      this.storageGetItem('cep') &&
      this.storageGetItem('number')
    ) {
      this.router.navigate(['/assine-empresas/monte-sua-combinacao']);
    } else if (
      view === '/modal-cep/inicial' &&
      this.storageGetItem('cep') &&
      this.storageGetItem('number')
    ) {
      this.router.navigate(['/monte-sua-combinacao']);
    }
  }

  public goToHome() {
    if (!['/monte-sua-combinacao', '/checkout'].includes(this.router.url)) {
      if (this.storageGetItem('CA') === 'true') {
        this.router.navigate(['/checkout']);
      } else {
        this.router.navigate(['/monte-sua-combinacao']);
      }
    }
  }

  public goToAdicionais() {
    if (
      this.lastRouteAdicionais !== '' ||
      this.lastRouteAdicionais === undefined
    ) {
      this.router.navigate([this.lastRouteAdicionais]);
    } else {
      this.goToHome();
    }
  }

  public redirectIfZipCodeOrNumberIsEmpty(): boolean {
    const param = (this.router.url.includes('?')) ? this.router.url.slice(this.router.url.indexOf('?')) : '';

    if (
      (!this.storageGetItem('cep') || !this.storageGetItem('number')) &&
      (!this.storageGetItem('city') || !this.storageGetItem('uf'))
    ) {
      if (this.router.url?.includes('%2F')) {
        this.storageSetItem('lastRouteUrlBeforeRedirectToHome', this.router.url.replace('%2F', ''))
        this.lastRouteUrlBeforeRedirectToHome = this.router.url.replace('%2F', '')
      } else {
        this.storageSetItem('lastRouteUrlBeforeRedirectToHome', this.router.url)
        this.lastRouteUrlBeforeRedirectToHome = this.router.url;
      }

      if (window.location.hostname !=='localhost' || window.location.origin === 'http://localhost:4200') {
          window.location.href = environment.urlCobertura + param;
      }

      return true;
    } else {
      return false;
    }
  }

  public checkIfTvAppSingle(ids: any) {
    const params = new URLSearchParams(window.location.search);
    const tvId = params.get('tvId');
    const tvIdArray = tvId ? tvId.split(',') : [];
    const appIds = ids.split(/\s*,\s*/)
    const matchAppId = tvId !== null && appIds.find((item) => tvIdArray.includes(item));
    const hasOnlyTvId = tvId !== null && !params.has('celularId') && !params.has('foneId') && !params.has('internetId');

    if (hasOnlyTvId && matchAppId) {
      window.location.href = `${environment.jornadaNova}checkout-tv?tvId=${tvId.replace("%2C", ",")}`;
    }

    return hasOnlyTvId && matchAppId;
  }

  public isDesktop(): boolean {
    var check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor);
    return !check;
  }

  setAccordionCatalog(catalog: string) {
    this.storageSetItem('origem', catalog);
    this.storageSetItem('catalog', catalog);
  }

  toggleAdvantages(productType: any, productId: any) {
    const element = document.getElementById(`${productType}-${productId}`);
    const first = <any>element.getElementsByClassName('content-advantages');
    first[0].style.display =
      first[0].style.display === '' ? 'table-row-group' : '';
    first[0].classList.value =
      first[0].classList.value === 'content-advantages'
        ? 'content-advantages display'
        : 'content-advantages';

    const thead = <any>element.getElementsByTagName('thead');
    thead[0].style.display = thead[0].style.display !== 'none' ? 'none' : '';

    const tbody = <any>element.getElementsByClassName('content-details');
    tbody[0].style.display = tbody[0].style.display !== 'none' ? 'none' : '';
  }

  isCheckout() {
    return window.location.href.indexOf('checkout') !== -1;
  }

  checkGratuity(valor: any, precoBRL: any) {
    return valor === 0 ? 'GRÁTIS' : precoBRL;
  }

  isNewViability() {
    return this.getUrlParameter('ectcm1') || this.storageGetItem('ectcm1')
      ? true
      : false;
  }

  public getEnvironmentTag() {
    if (window.location.pathname.indexOf('/assine-empresas') !== -1) {
      return environment.company + '-empresas';
    } else {
      return environment.company;
    }
  }

  public getGtmCategory() {
    return environment.gtmAmbiente;
  }

  updatePageName(name: any) {
    if (name.charAt(0) === '/') {
      name = name.substr(1);
    }
    return name.replace(new RegExp('/', 'g'), '-');
  }
  mostraCombinacao(produto?: any, tipo?: any) {
    const periodoOferta =
      produto &&
      produto.periodicidadeSelecao &&
      produto.periodicidadeSelecao.periodoOferta;
    const periodoSingle =
      produto && produto.individual && produto.individual.periodoOferta;
    const precoIndividual =
      produto && produto.individual && produto.individual.preco;
    const precoCombo = produto && produto.preco;

    return (
      precoCombo !== precoIndividual ||
      (precoCombo === precoIndividual &&
        (!!periodoOferta || periodoOferta === '') &&
        periodoOferta !== periodoSingle &&
        periodoOferta !== '-')
    );
  }

  public isV2DadosPessoais() {
    return window.location.href.indexOf('v2') !== -1;
  }

  convertToSanitizer(value: string) {
    if (!!value) {
      const stringToConvert = value
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '') // Remove acentos
        .replace(/([^\w]+|\s+)/g, '-') // Substitui espaço e outros caracteres por hífen
        .replace(/\-\-+/g, '-') // Substitui multiplos hífens por um único hífen
        .replace(/(^-+|-+$)/, '') // Remove hífens extras do final ou do inicio da string
        .toLowerCase();
      return stringToConvert;
    } else {
      return 'sem-label';
    }
  }

  uriSanitazer(uri: string): string {
    if (uri !== undefined || uri !== null) {
      return encodeURIComponent(uri?.toLowerCase()
        .replace(/ /g, '_')
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, ''));
    }
  }

  focusMissingField(controls: any) {
    try {
      for (const name in controls) {
        if (controls[name].invalid) {
          document.getElementById(name).focus();
        }
      }
    } catch (error) { }
    this.blurAllField(controls);
  }

  blurAllField(controls: any) {
    try {
      for (const name in controls) {
        if (controls[name]) {
          try {
            document.getElementById(name).blur();
          } catch (error) { }
        }
      }
    } catch (error) { }
  }

  resolveInputIcon(control: any): string {
    return control.value 
        ? control.status === 'INVALID' 
            ? 'mdn-Icon-fechar mdn-Icon--md' 
            : 'mdn-Icon-check mdn-Icon--md' 
        : '';
  }

  getLoyaltyStatusFromStorage(): boolean {
    const fidelityStatus = this.storageGetItem('loyalty');
    return (fidelityStatus === '' || fidelityStatus === 'true');
  }
  /**
   * Resolves whether to show the shopping cart based on the provided URL.
   * @param url - The URL to check.
   * @returns A boolean indicating whether to show the shopping cart.
   */
  resolveShowCarrinho(url: string): boolean {
    const isConfirmacao = url === '/checkout/confirmacao';
    const isMonteSuaCombinacao = url === '/monte-sua-cobinacao';

    if (isMonteSuaCombinacao || isConfirmacao) {
      return false;
    } else {
      return true;
    }
  }
  /**
   * Resolves whether to show the "Alterar Endereço" button based on the current URL.
   * @param url - The current URL.
   * @returns A boolean indicating whether to show the "Alterar Endereço" button.
   */
  resolveShowAlterarEndereco(url: string): boolean {
    const isCobertura = url === '/' || url === '/cobertura';
    const isConfirmacao = url === '/checkout/confirmacao';
    const isErrorPage = url === '/500' || url === '/404';
    const isRetentativa = url === '/checkout/retentativa';

    if (isCobertura || isConfirmacao || isErrorPage || isRetentativa) {
      return false;
    } else {
      return true;
    }
  }
  /**
   * Determines whether or not to show the "Ja sou cliente" button based on the provided URL.
   * @param url - The URL to check.
   * @returns A boolean indicating whether or not to show the button.
   */
  resolveShowJaSouClienteBtn(url: string): boolean {
    const isCobertura = url === '/' || url === '/cobertura';
    const isErrorPage = url === '/500' || url === '/404';

    if (isCobertura || isErrorPage ) {
      return true;
    } else {
      false;
    }
  }

  setEsconderContainerTextoLegal(value: boolean) {
    this.esconderContainerTextoLegal = value;
  }

  clearAddressData(): void {
    sessionStorage.removeItem('number');
    sessionStorage.removeItem('cep');
  }

  clearCatalogData(): void {
    sessionStorage.removeItem('origem');
    sessionStorage.removeItem('catalog');
    sessionStorage.removeItem('city');
    sessionStorage.removeItem('uf');
  }

  clearCookiesData(): void {
    sessionStorage.removeItem('cookiePortabilityCelular');
    sessionStorage.removeItem('cookieCelularDependentes');
  }

  clearDadosDeIdentificacaoMovel(): void {
    sessionStorage.removeItem('sms-ecare-token');
    sessionStorage.removeItem('cliente-claro-movel');
    sessionStorage.removeItem('customerName');
    sessionStorage.removeItem('claro-movel-promo-applied');
    sessionStorage.removeItem('cliente-movel-de-para-mobile-plan');
    sessionStorage.removeItem('customer-mobile-info-api-return');
    sessionStorage.removeItem('ModalConfiraOQueGanhouShowed');
    sessionStorage.removeItem('banner_info_cliente_claro_movel');
  }

  formatCurrency(value: any) {
    return (value / 100).toLocaleString('pt-BR', { minimumFractionDigits: 2 });
  }
      
  checkIfDomElementExists(selector: string): Promise<HTMLElement> {
    return new Promise((resolve) => {
      const observer = new MutationObserver((mutationsList, observer) => {
        const elementoAdicionado = document.querySelector(selector) as HTMLElement;
        
        // Para de observar quando o elemento for encontrado
        if (elementoAdicionado) {
          observer.disconnect(); 
          resolve(elementoAdicionado);
        }
      });
      
      observer.observe(document.body, { childList: true, subtree: true });
      
      // Verifica se o elemento já existe antes de iniciar a observação
      const elementoExistente = document.querySelector(selector) as HTMLElement;
      if (elementoExistente) {
        observer.disconnect();
        resolve(elementoExistente);
      }
    });
  }

  mustBlockPurchaseProduct(selection: any, quantityCart: number, products: any) {
    const isAppTv = selection['tv'] ? selection['tv'].tags.some(tag => tag === 'claroapp') : false;
    const isCelular = selection['celular'] ? true : false;

    products.forEach((item: any) => {

      if (isAppTv && quantityCart === 1 || isAppTv && quantityCart >= 2 && selection['fone']) {
        item.mustBlock = true;        
      } 

      if (isCelular && quantityCart === 1 || isCelular && quantityCart >= 2 && selection['fone']) {
        item.mustBlock = item.tags?.some(tag => tag === 'claroapp') ? true : false;
      }
      
      if (quantityCart >= 2 && selection['internet']) {
        item.mustBlock = false;
      }
    });
    
    return products;
  }

  isRedeMovel(): boolean {
    if(this.cacheIsRedeMovel != null){
      return this.cacheIsRedeMovel
    }

    const currNetwork = sessionStorage.getItem('autonomous_system_organization') || ""
    this.cacheIsRedeMovel = ALLOWED_NETWORKS_REDE_MOVEL.includes(currNetwork.toLowerCase().trim())
    return this.cacheIsRedeMovel
  }

}
