import { Component, OnInit, AfterViewInit, Input, HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { UtilsService } from 'src/app/services/utils.service';
import { AssineJaParserService } from 'src/app/services/assine-ja-parser.service';
import { CarrinhoService } from 'src/app/services/carrinho.service';
import { IncentivoCelularService } from 'src/app/services/incentivo-celular.service';
import { CroService } from '../../../services/cro.service';
import { LeadService } from '../../../services/lead.service';
import { EnderecoService } from '../../../modules/checkout/services/endereco.service';
import { environment } from 'src/environments/environment';

import { ObjectCep } from './menu.model';

import modalFn from 'mondrian-vanilla/core/components/Modal/modal.js';
import themeChanger from 'mondrian-vanilla/core/util/theme-changer.js';
import { CheckoutService } from 'src/app/modules/checkout/services/checkout.service';
import { MdnModalService } from '../mdn-modal/mdn-modal.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, AfterViewInit {
  @Input() showJaSouClienteBtn: boolean;
  @Input() showAlterarEnderecoBtn: boolean;
  @Input() showCarrinho: boolean;

  cepInfo: ObjectCep = {};
  gtmCategory: string;
  quantidadeCarrinho: number = 0;
  skipLinkContent: boolean = false;
  // tslint:disable-next-line: no-any
  mdnModalFontSize: any;
  // tslint:disable-next-line: no-any
  mdnModalTargetBlank: any;
  public linkBlankTitle: string;
  public linkBlankHref: string;
  private theme: string = 'index';
  private isWidthLessThan992: boolean = true
  public isWidthLessThan768: boolean = false

  constructor(
    public router: Router,
    public utils: UtilsService,
    public assine: AssineJaParserService,
    public carrinho: CarrinhoService,
    public incentivoCelularService: IncentivoCelularService,
    public cro: CroService,
    public leadService: LeadService,
    public enderecoService: EnderecoService,
    public checkoutService: CheckoutService,
    public modal: MdnModalService,
  ) { }

  ngOnInit(): void {
    this.verifyWidth992Breakpoint()
    this.verifyWidth768Breakpoint()
    const theme = this.utils.cookieService.get('theme');

    if (this.theme !== theme && theme !== '') {
      this.themeChanger();
    }

    this.assine.parserReady$.subscribe(() => {
      this.montaObjetoCep();
    });

    this.carrinho.atualizouCarrinho$.subscribe(() => {
      this.quantidadeCarrinho = this.getQtdCarrinho();

      const updatedMobileCustomer = this.carrinho.updatedMobileCustomer;
      const celular = this.carrinho.getCart()?.selection?.celular;
      if (updatedMobileCustomer && !!celular) {
        this.quantidadeCarrinho--;
      }
    });

    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        this.quantidadeCarrinho = this.getQtdCarrinho();

        const updatedMobileCustomer = this.carrinho.updatedMobileCustomer;
        const celular = this.carrinho.getCart()?.selection?.celular;
        if (updatedMobileCustomer && !!celular) {
          this.quantidadeCarrinho--;
        }
      }
    });

    this.gtmCategory = this.utils.getGtmCategory();
  }

  ngAfterViewInit(): void {
    this.mdnModalFontSize = modalFn(document.getElementById('modal-font-size'));
    this.mdnModalTargetBlank = modalFn(document.getElementById('modal-target-blank-cliente'));
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.verifyWidth992Breakpoint()
  }

  verifyWidth992Breakpoint(): void {
    this.isWidthLessThan992 = window.innerWidth < 992
  }

  verifyWidth768Breakpoint(): void {
    this.isWidthLessThan768 = window.innerWidth < 768;
  }

  getQtdCarrinho(): number {
    let qtd = 0;
    const products = ['internetId', 'tvId', 'celularId', 'foneId'];

    products.forEach((product) => { if (this.assine.parser?.cart[product] > 0) { qtd++; } });

    return qtd;
  }

  montaObjetoCep(): void {
    this.cepInfo.canChangeInstallation = this.utils.storage.getItem('canChangeInstallation') === 'true';
    this.cepInfo.cidade = this.assine.jsonCity;
    this.cepInfo.uf = this.assine.jsonUF;
    this.cepInfo.cep = this.utils.formataCepHeader(this.utils.storage.getItem('cep'));
    this.utils.esconderContainerTextoLegal = false;
  }

  mudarCep(): void {
    const { storage } = this.utils;

    this.setCepInfo({});
    const leadId = storage.getItem('leadId')
    storage.clearAddressData();
    storage.clearCatalogData();
    storage.clearCookiesData();
    storage.clearDadosDeIdentificacaoMovel();
    storage.removeItem('produto');
    storage.removeItem('single');
    storage.removeItem('modalBaseCep');
    storage.removeItem('removedFone');
    storage.setItem('abandonoMostrado', 'true');
    storage.removeItem('outro-endereco@JaSouCliente')

    this.assine.setCheckParser(false);
    this.carrinho.setProductIncentivo(undefined);
    this.utils.setEsconderContainerTextoLegal(true);
    this.incentivoCelularService.setIdIncentivoCelular(undefined);
    this.incentivoCelularService.setInternetSelecionadaId(undefined);

    this.carrinho.limparCarrinho();
    this.leadService.clearAddressData();

    this.returnToFlow(leadId);
  }

  returnToFlow(leadId) {
    if (this.utils.storageGetItem('isBoxTv') === 'true') {
      this.utils.storage.setItem('leadId', leadId);
      window.location.href = `${environment.urlCobertura}/box-tv`
    } else if (this.utils.storageGetItem('isAppTv') === 'true') {
      this.utils.storage.setItem('leadId', leadId);
      window.location.href = `${environment.jornadaNova}/checkout-tv`
    } else {
      window.location.href = environment.urlCobertura;
    }
  }

  logoRedirect(): void {
    this.registerRedirectIntoDataLayer();
    const pageUrl = this.router.url;
    this.carrinho.statusCart = false;    

    if (pageUrl.indexOf('500') > -1) {
      this.mudarCep();
      return;
    }

    if (pageUrl === '/monte-sua-combinacao' || '/checkout/confirmacao') {
      this.utils.storageClear();
      window.location.href = "https://www.claro.com.br/";
      return;
    }

    this.router.navigateByUrl('/monte-sua-combinacao');
  }

  registerRedirectIntoDataLayer(): void {
    this.cro.postDataLayer({
      event: 'event',
      eventCategory: 'planos-claro-res:geral',
      eventAction: 'clique:link',
      eventLabel: 'logo-claro',
    });
  }

  showSkipLink(): void {
    this.skipLinkContent = true;
  }

  hideSkipLink(): void {
    this.skipLinkContent = false;
  }

  openTargetBlank(title?: string, href?: string): void {
    this.registerJaSouClienteIntoDataLayer();
    this.linkBlankTitle = title;
    this.linkBlankHref = href;
    this.cro.openModal('target-blank');
    this.mdnModalTargetBlank.open();
  }

  registerJaSouClienteIntoDataLayer(): void {
    this.cro.postDataLayer({
      event: 'event',
      eventCategory: 'planos-claro-res:geral',
      eventAction: 'clique:link',
      eventLabel: 'ja-sou-cliente',
    });
  }

  showHandTalkAndOpenLibrasContainer(): void {
    const element = document.getElementsByClassName('ht-skip');
    if (element.length > 0) {
      element[0].classList.add('show');
      this.openLibrasContainer()
    }
  }

  openLibrasContainer():void {
    const accessibilityLibrasButton: HTMLElement = document.querySelectorAll('button[icon="accessibility-Libras"]')[0] as HTMLElement
    if(accessibilityLibrasButton){
      accessibilityLibrasButton.click()
    }
  }

  themeChanger(): void {
    this.theme = this.theme === 'index' ? 'contrast' : 'index';
    this.utils.cookieService.set('theme', this.theme);
    themeChanger(this.theme);
  }

  setCepInfo(value: any): void {
    this.cepInfo = value;
  }
}
