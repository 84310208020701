import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';

import { Subject } from 'rxjs';

import { AssineJaParserService } from './assine-ja-parser.service';
import { Router } from '@angular/router';
import { RotasEnum } from '../shared/enums/rotas-enum';
import { CroService } from './cro.service';

interface Obj {
  checkout: boolean;
  id: number;
  tipo: string;
}

interface Adicional {
  id: number;
  nome?: string;
  preco?: any;
  segmento?: string;
  tipo?: string;
}

@Injectable({
  providedIn: 'root'
})
export class SubprodutoSelecaoService {

  public subprodutoAtulizado$: Subject<any> = new Subject<any>();
  public novoParser$: Subject<any> = new Subject<any>();
  public montaCroAdicionais$: Subject<any> = new Subject<any>();
  public lastCroObj: Array<object> = [];

  constructor(
    private utils: UtilsService,
    public assine: AssineJaParserService,
    public router: Router,
    public cro: CroService,
  ) {
  }

  subprodutosAtualizados(obj: any) {
    this.subprodutoAtulizado$.next(obj);
  }

  carregaNovoParser(parser: any) {
    this.novoParser$.next(parser);
  }

  montaCroAdicionais(data) {
    this.montaCroAdicionais$.next(data);
    this.croAdicionais(data);
  }

  setAddCookie(keys) {
    if (keys.length > 0) {
      this.utils.storage.setItem('additionalIds', keys);
    } else {
      this.utils.storage.removeItem('additionalIds');
    }
  }

  setSubCookie(keys) {
    if (keys.length > 0) {
      this.utils.storage.setItem('subprodutosIds', keys);
    } else {
      this.utils.storage.removeItem('subprodutosIds');
    }
  }

  private croAdicionais(obj: Obj): void {

    let adicional: Adicional;

    if (this.assine.parser.cart.selection[obj.tipo] && this.assine.parser.cart.selection[obj.tipo].selecionados) {
      adicional = this.assine.parser.cart.selection[obj.tipo].selecionados.find(adcional => adcional.id === obj.id);
    }

    if (adicional) {
      const product = adicional;
      const tipo = 'adicional/' + obj.tipo;
      const cookie = false;
      const croObj: Array<object> = [];
      const vm = this;

      if (product && cookie === false) {
        croObj.push({
          name: this.utils.convertToSanitizer(product.nome),
          id: product.id,
          price: product.preco.currency().integer() + '.' + product.preco.currency().cents(), // TODO: Remover este currency do Jquery
          brand: vm.utils.getBrandTag(),
          category: tipo,
          quantity: '1'
        });

        if (JSON.stringify(this.lastCroObj) !== JSON.stringify(croObj)) {
          this.cro.AddToCart(croObj);
          this.lastCroObj = croObj;
        }
      }
    }

    if (obj.checkout) {

      const croObjCheckout: Array<object> = [];
      let text: string = '';
      let path: object;
      const produtos = this.assine.parser.cart.selection;

      for (const key in produtos) {
        if (key !== 'selecoes') {
          text += produtos[key].nome;

          for (const key2 in produtos[key].selecionados) {
            if (produtos[key].selecionados.hasOwnProperty(key2)) {
              const croObjeto = {
                name: produtos[key].selecionados[key2].nome,
                id: produtos[key].selecionados[key2].id,
                price: this.utils.formataDinheiroCro(produtos[key].selecionados[key2].preco.toString()),
                brand: this.utils.getBrandTag(),
                category: 'planos/' + key
              };
              croObjCheckout.push(croObjeto);
            }
          }
        }
      }

      switch (this.router.url) {
        case `/${RotasEnum.CHECKOUT}/${RotasEnum.DADOS_PESSOAIS}`: {
          path = {
            eventAction: `${RotasEnum.DADOS_PESSOAIS}`,
            actionField: '1'
          };

          break;
        }
        case `/${RotasEnum.CHECKOUT}/${RotasEnum.ENDERECO}`: {
          path = {
            eventAction: `${RotasEnum.ENDERECO}`,
            actionField: '2'
          };

          break;
        }
        case `/${RotasEnum.CHECKOUT}/${RotasEnum.PAGAMENTO}`: {
          path = {
            eventAction: `${RotasEnum.PAGAMENTO}`,
            actionField: '3'
          };

          break;
        }
      }

      this.cro.Checkout(croObjCheckout, path, text);
    }
  }

}
