import { Injectable } from '@angular/core';
import { AssineJaParserService } from 'src/app/services/assine-ja-parser.service';
import { SubprodutoSelecaoService } from 'src/app/services/subproduto-selecao.service';
import { UtilsService } from 'src/app/services/utils.service';
import { AlertService } from 'src/app/shared/mondrian-components/alert/alert.service';
import { List, Tv, Additionals, Config, Channel, AdicionalParcelavel, ChannelUnified, AdditionalUnified } from './new-adicionais.model';

export interface Resp {
  additionals?: Additionals[];
  configs?: Config[];
}

@Injectable({
  providedIn: 'root'
})
export class NewAdicionaisService {

  list: List;
  cart: any;

  adicionaisParcelaveis: AdicionalParcelavel[] = [
    
    //BL
    { id: 329, nome: 'Disney+ Premium Anual' },
    { id: 332, nome: 'Max sem anúncios Anual' },
    { id: 305, nome: 'Max com anúncios Anual' },
    { id: 300, nome: 'Premiere Anual' },
    { id: 306, nome: 'Telecine Anual' },
    { id: 307, nome: 'Paramount Anual' },
    
    //TV
    { id: 184, nome: 'Max com anúncios Anual' },
    { id: 15, nome: 'Max sem anúncios Anual' },
    { id: 116, nome: 'Premiere Anual' },
    { id: 133, nome: 'Telecine Anual' },
    { id: 139, nome: 'HBO Anual' },
    { id: 140, nome: 'Paramount Anual' },
    { id: 147, nome: 'Disney+ Premium Anual' },
    
    //Satelite
    { id: 188, nome: 'Max sem anúncios Anual' },
    { id: 23, nome: 'Max sem anúncios Anual' },
    { id: 149, nome: 'Disney+ Premium Anual' },
    { id: 51, nome: 'Premiere Anual' },
    { id: 134, nome: 'Telecine Anual' },
    { id: 142, nome: 'HBO Anual' },
    { id: 141, nome: 'Paramount Anual' },

    // Adicionar novo adicional parcelavel
  ];

  private priorityList: string[] = ['celular', 'internet', 'tv'];
  private configOthersInfos: object = {
    pontoultra: {
      logo: 'assets/additional/general/ponto-ultra.svg',
      description: 'O ponto ultra é uma conexão via cabo de rede que leva o sinal da internet banda larga da Claro até o dispositivo que você precisa de mais velocidade e estabilidade, melhorando a conexão.',
    },
    'extensorwi-fimesh': {
      logo: 'assets/additional/general/wi-fi-mesh.svg',
      description: 'Com WI-FI Mesh, você leva o sinal da sua banda larga para todos os cômodos. Ele ainda cria uma rede única e inteligente, que identifica o melhor ponto de conexão para você trafegar. Você caminha pela casa sem perder o sinal. O resultado é mais alcance de sinal e conectividade.',
    },
  };

  constructor(
    public utils: UtilsService,
    public subprodutoService: SubprodutoSelecaoService,
    public assine: AssineJaParserService,
    public alert: AlertService,
  ) { }

  private getAdditionalAndConfig(categoryAdditionals: any, category: string): object {
    const additionals: Additionals[] = [];
    const configs: Config[] = [];
    const sessionStorageAdditionalIds: string[] = this.utils.storageGetItem('additionalIds')?.split(',') || [];

    for (const additionalId of Object.keys(categoryAdditionals)) {
      const additional = categoryAdditionals[additionalId];
      if (additional.multipleChoice === 0) {
        additional.categoryPlan = category;
        const othersInfos = this.configOthersInfos[this.stringSanitizer(additional.nome)];

        if (othersInfos) {
          additional.logo = othersInfos.logo;
          additional.description = othersInfos.description;
        }
        configs.push(additional);
      } else if (additional.multipleChoice === 1) {
        additional.opcoes.forEach((option: Additionals) => {
          option.categoryPlan = category;
          option.selected = sessionStorageAdditionalIds.some(add => add === option.id.toString());
          additionals.push(option);
        });
      }
    }
    
    configs.reverse();

    return { additionals, configs };
  }

  private checkRepeatAdditionals(): void {
    const subprodutosStorageIds = sessionStorage.getItem('subprodutosIds');
    const adicionaisStorageIds = sessionStorage.getItem('additionalIds');

    this.list.tv?.channels?.some(item => {
      if (subprodutosStorageIds?.includes((item as Channel).id.toString())) {
        this.list.entertainment?.find(add => {
          if ((add as AdditionalUnified).name !== undefined && (item as Channel).nome !== undefined && 
              this.stringSanitizer((item as Channel).nome).includes(this.stringSanitizer((add as AdditionalUnified).name))) {
                (add as AdditionalUnified).month.hidden = true;
                (add as AdditionalUnified).year.hidden = true;
          } else if ((add as Additionals).nome !== undefined && (item as Channel).nome !== undefined && 
              this.stringSanitizer((item as Channel).nome).includes(this.stringSanitizer((add as Additionals).nome))) {
                (add as Additionals).hidden = true;
          }
        })
      }
    })

    this.list.entertainment?.some(item => {
      if (((item as AdditionalUnified).idMonth !== undefined && adicionaisStorageIds?.includes((item as AdditionalUnified).idMonth.toString())) || 
      ((item as AdditionalUnified).idYear !== undefined && adicionaisStorageIds?.includes((item as AdditionalUnified).idYear.toString())) || 
      ((item as Channel).id !== undefined && adicionaisStorageIds?.includes((item as Channel).id.toString()))) {
        
        this.list.tv?.channels?.find(add => {
          if ((add as Channel).nome !== undefined && (item as AdditionalUnified).name !== undefined && 
          this.stringSanitizer((add as Channel).nome).includes(this.stringSanitizer((item as AdditionalUnified).name))) {
            (add as Channel).hidden = true;
            (add as Channel).hidden = true;
          } else if ((add as Channel).nome !== undefined && (item as Additionals).nome !== undefined && 
              this.stringSanitizer((item as Additionals).nome).includes(this.stringSanitizer((add as Channel).nome))) {
                (add as Channel).hidden = true;
          }
        })
      }
    })
  }

  private hiddenRepeatAdditional(type: string, name: string, action: string, period?: boolean): void {
    if (type === 'channel') {
      if (period === true) {        
        const target = this.list.entertainment?.find(item => this.stringSanitizer((item as AdditionalUnified).name) === this.stringSanitizer(name));
        if (target) {
          target.month.hidden = action === 'add';
          target.year.hidden = action === 'add';
        }
      } else {
        this.list?.entertainment?.find(item => {
          if ((item as Channel).nome !== undefined && this.stringSanitizer((item as Additionals).nome) === this.stringSanitizer(name)) {
            (item as Channel).hidden = action === 'add';
          }
        });
      }
    } else if (type === 'additional') {
      if (period === true) {
        this.list.tv?.channels.find(item => {
          if ((item as ChannelUnified).name !== undefined && this.stringSanitizer((item as ChannelUnified).name) === this.stringSanitizer(name)) {
            (item as ChannelUnified).month.hidden = action === 'add';
            (item as ChannelUnified).year.hidden = action === 'add';
          }
        })
      } else {
        this.list.tv?.channels.find(item => {
          if ((item as Channel).nome !== undefined && this.stringSanitizer((item as Channel).nome) === this.stringSanitizer(name)) {
            (item as Channel).hidden = action === 'add';
          }
        });
      }
    }
  }

  public getList(): List {
    this.list = new List();
    this.cart = this.assine.parser.cart;

    const { selection } = this.assine.parser.cart;
    let entertainment: Additionals[] = [];
    const configs: Config[] = [];
    
    this.priorityList.forEach(category => {
      if (category in selection) {
        const categoryAdditionals = selection[category].adicionais || {};
        const resp: Resp = this.getAdditionalAndConfig(categoryAdditionals, category);
        
        if (category === 'tv') {
          const additionalsList: Tv = {};
          let channels: Channel[] = [];
          
          const subCanais = this.assine.parser.cart.subprodutos.permitidos(selection.tv.id);
          const sessionStorageSubprodutosIds: string[] = this.utils.storageGetItem('subprodutosIds')?.split(',') || [];
          if (subCanais) {
            channels = subCanais.map((channel: Channel) => {
              channel.selected = sessionStorageSubprodutosIds.some(sub => sub === channel.id.toString());
              channel.hidden = false;
              channel.categoryPlan = category;
              channel.isPaidInInstallments = this.resolveIsPaidInInstallments(channel.id);
              channel.installmentPrice = this.resolveInstallmentsPrice(channel);
              channel.installmentsAmount = this.resolveInstallmentsAmount(channel);
              return channel;
            });
          }

          if (resp.configs.length > 0) { additionalsList.configs = resp.configs; }
          if (channels.length > 0) { additionalsList.channels = channels; }
          this.list.tv = additionalsList;
          
        } else {
          const respEntertainment = resp.additionals;
          const respConnectivity = resp.configs;
          
          respEntertainment.forEach(adds => {
            const test = entertainment.some(add => this.stringSanitizer(add.nome) === this.stringSanitizer(adds.nome));
            if (!test) {
              adds.hidden = false;
              entertainment.push(adds);
            }
          });

          if (category === 'internet') {
            const subCanais = entertainment;
            const sessionStorageAdditionalsIds: string[] = this.utils.storageGetItem('additionalIds')?.split(',') || [];
            if (subCanais) {
                entertainment = subCanais.map((channel: Additionals) => {
                  channel.selected = sessionStorageAdditionalsIds.some(sub => sub === channel.id.toString());
                  channel.hidden = false;
                  channel.categoryPlan = category;
                  channel.isPaidInInstallments = this.resolveIsPaidInInstallments(channel.id);
                  channel.installmentPrice = this.resolveInstallmentsPrice(channel);
                  channel.installmentsAmount = this.resolveInstallmentsAmount(channel);
                return channel;
              });
            }

            respConnectivity.forEach(config => configs.push(config));
          }
        }
      }
    });

    this.populateUnifiedAdditionals(entertainment);

    if (entertainment.length > 0) { this.list.entertainment = entertainment; }
    if (configs.length > 0) { this.list.connectivity = configs; }

    this.checkRepeatAdditionals();

    return this.list;
  }  

  public addAdditional(id: number, type: string, categoryPlan: string): void {
    if (type === 'channel') {
      this.cart.subprodutos.add(id);
      this.subprodutoService.setSubCookie(this.cart.subprodutos.keys);
      const channel = this.list.tv.channels;
      const arrayIndex = this.list.tv.channels.findIndex(channel => (channel as Channel).id === id);
      const hasMonth = this.list.tv.channels.some(channel => (channel as ChannelUnified).idMonth === id)
      const hasYear = this.list.tv.channels.some(channel => (channel as ChannelUnified).idYear === id)

      if (!!hasMonth) {
        const arrayMonthIndex = this.list.tv.channels.findIndex(channel => (channel as ChannelUnified).idMonth === id);
        (channel[arrayMonthIndex] as ChannelUnified).selected = true;
        (channel[arrayMonthIndex] as ChannelUnified).month.selected = true;
        this.hiddenRepeatAdditional('channel', (channel[arrayMonthIndex] as ChannelUnified).name, 'add', hasMonth);
      } else if (!!hasYear) {
        const arrayYearIndex = this.list.tv.channels.findIndex(channel => (channel as ChannelUnified).idYear === id);
        (channel[arrayYearIndex] as ChannelUnified).selected = true;
        (channel[arrayYearIndex] as ChannelUnified).year.selected = true;
        this.hiddenRepeatAdditional('channel', (channel[arrayYearIndex] as ChannelUnified).name, 'add', hasYear);
      } else {
        (channel[arrayIndex] as Channel).selected = true;
        this.hiddenRepeatAdditional('channel', (channel[arrayIndex] as Channel).nome, 'add');
      }

    } else {
      this.cart.adicionais.add(id);
      this.subprodutoService.setAddCookie(this.cart.adicionais.keys);      

      if (categoryPlan !== 'tv') {
        const entertainmentsChannels = this.list.entertainment;
        const hasMonth = entertainmentsChannels.some(channel => (channel as AdditionalUnified).idMonth === id)
        const hasYear = entertainmentsChannels.some(channel => (channel as AdditionalUnified).idYear === id)
        const arrayIndex = entertainmentsChannels.findIndex(add => (add as Additionals).id === id);

        if (!!hasMonth) {
          const arrayMonthIndex = entertainmentsChannels.findIndex(channel => (channel as AdditionalUnified).idMonth === id);
          (entertainmentsChannels[arrayMonthIndex] as AdditionalUnified).selected = true;
          (entertainmentsChannels[arrayMonthIndex] as AdditionalUnified).month.selected = true;
          this.hiddenRepeatAdditional('additional', (entertainmentsChannels[arrayMonthIndex] as AdditionalUnified).name, 'add', hasMonth);
        } else if (!!hasYear) {
          const arrayYearIndex = entertainmentsChannels.findIndex(channel => (channel as AdditionalUnified).idYear === id);
          (entertainmentsChannels[arrayYearIndex] as AdditionalUnified).selected = true;
          (entertainmentsChannels[arrayYearIndex] as AdditionalUnified).year.selected = true;
          this.hiddenRepeatAdditional('additional', (entertainmentsChannels[arrayYearIndex] as AdditionalUnified).name, 'add', hasYear);
        } else if (arrayIndex > -1){
          (entertainmentsChannels[arrayIndex] as Additionals).selected = true;
          this.hiddenRepeatAdditional('additional', (entertainmentsChannels[arrayIndex] as Additionals).nome, 'add');
        }
      }
    }

    this.subprodutoService.subprodutosAtualizados(this.assine.parser);
    this.subprodutoService.montaCroAdicionais({
      id: Number(id),
      tipo: 'categoryPlan',
      checkout: false,
    });

    this.alert.open({
      data: {
        text: 'Adicional incluído com sucesso!',
        theme: 'light',
        type: 'success',
        time: 3000,
      },
      target: 'alert-drawer',
    });
  }

  public removeAdditional(id: number, type: string, categoryPlan: string): void {
    if (type === 'channel') {
      this.cart.subprodutos.remove(id);
      this.subprodutoService.setSubCookie(this.cart.subprodutos.keys);
      const channel = this.list.tv.channels;
      const hasMonth = this.list.tv.channels.some(channel => (channel as ChannelUnified).idMonth === id)
      const hasYear = this.list.tv.channels.some(channel => (channel as ChannelUnified).idYear === id)
      const arrayIndex = this.list.tv.channels.findIndex(channel => (channel as Channel).id === id);

      if (!!hasMonth) {
        const arrayMonthIndex = this.list.tv.channels.findIndex(channel => (channel as ChannelUnified).idMonth === id);
        (channel[arrayMonthIndex] as ChannelUnified).selected = false;
        (channel[arrayMonthIndex] as ChannelUnified).month.selected = false;
        this.hiddenRepeatAdditional('channel', (channel[arrayMonthIndex] as ChannelUnified).name, 'remove', hasMonth);

      } else if (!!hasYear) {
        const arrayYearIndex = this.list.tv.channels.findIndex(channel => (channel as ChannelUnified).idYear === id);
        (channel[arrayYearIndex] as ChannelUnified).selected = false;
        (channel[arrayYearIndex] as ChannelUnified).year.selected = false;
        this.hiddenRepeatAdditional('channel', (channel[arrayYearIndex] as ChannelUnified).name, 'remove', hasYear);       

      } else {
        const additionalsArrayIndex = this.list.entertainment?.findIndex(item => {
          return (item as Channel).nome === (channel[arrayIndex] as Channel).nome;
        });

        (channel[arrayIndex] as Channel).selected = false;

        if (additionalsArrayIndex > -1) {
          this.list.entertainment[additionalsArrayIndex].hidden = false;
        }
        
        this.hiddenRepeatAdditional('channel', (channel[arrayIndex] as Channel).nome, 'remove');
      }

    } else {
      this.cart.adicionais.remove(id);
      this.subprodutoService.setAddCookie(this.cart.adicionais.keys);

      if (categoryPlan === 'tv') {
        const arrayIndex = this.list.tv.additionals?.findIndex(channel => channel.id === id);
        if (arrayIndex > -1) {
          this.list.tv.additionals[arrayIndex].selected = false;
          this.hiddenRepeatAdditional('additional', this.list.tv.additionals[arrayIndex].nome, 'remove');
        }
      } else {
        const entertainmentsChannels = this.list.entertainment;
        const hasMonth = entertainmentsChannels.some(channel => (channel as AdditionalUnified).idMonth === id)
        const hasYear = entertainmentsChannels.some(channel => (channel as AdditionalUnified).idYear === id)
        const arrayIndex = entertainmentsChannels.findIndex(add => (add as Additionals).id === id);

        if (!!hasMonth) {
          const arrayMonthIndex = entertainmentsChannels.findIndex(channel => (channel as AdditionalUnified).idMonth === id);
          (entertainmentsChannels[arrayMonthIndex] as AdditionalUnified).selected = false;
          (entertainmentsChannels[arrayMonthIndex] as AdditionalUnified).month.selected = false;
          this.hiddenRepeatAdditional('additional', (entertainmentsChannels[arrayMonthIndex] as AdditionalUnified).name, 'remove', hasMonth);
        } else if (!!hasYear) {
          const arrayYearIndex = entertainmentsChannels.findIndex(channel => (channel as AdditionalUnified).idYear === id);
          (entertainmentsChannels[arrayYearIndex] as AdditionalUnified).selected = false;
          (entertainmentsChannels[arrayYearIndex] as AdditionalUnified).year.selected = false;
          this.hiddenRepeatAdditional('additional', (entertainmentsChannels[arrayYearIndex] as AdditionalUnified).name, 'remove', hasYear);
  
        } else {
          if (arrayIndex > -1) {
            this.list.entertainment[arrayIndex].selected = false;
            this.hiddenRepeatAdditional('additional', this.list.entertainment[arrayIndex].nome, 'remove');
          }          
        }
      }
    }

    this.subprodutoService.subprodutosAtualizados(this.assine.parser);

    this.alert.open({
      data: {
        text: 'Adicional removido com sucesso!',
        theme: 'light',
        type: 'danger',
        time: 3000,
      },
      target: 'alert-drawer',
    });
  }

  public stringSanitizer(str: string): string {
    return str.toLowerCase().replace(/ /g, '').normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  resolveIsPaidInInstallments(id: number): boolean {
    const isPaidInInstallments = this.adicionaisParcelaveis.find(
      (parcelavel: AdicionalParcelavel) => id === parcelavel.id
    );
    return isPaidInInstallments ? true : false;
  }

  resolveInstallmentsPrice(channel: any): number | undefined {
    const adicional = this.adicionaisParcelaveis.find(
      (parcelavel: AdicionalParcelavel) => channel.id === parcelavel.id
    );
    return adicional ? channel.periodos[0] : undefined
  }

  resolveInstallmentsAmount(channel: any): number | undefined {
    const adicional = this.adicionaisParcelaveis.find(
      (parcelavel: AdicionalParcelavel) => channel.id === parcelavel.id
    );

    if (adicional) {
      channel.periodos = channel.periodos.filter((periodo => periodo > 0))

      if (channel.periodos.length > 12) {
        return channel.periodos.length = 12
      } else {
        return channel.periodos.length;
      }
      
    } else {
      return undefined
    }
  }

  cleanProductName = (name: string): string => {
    return name.toLowerCase().replace('mensal', '').replace('anual', '').trim();
  };

  populateUnifiedAdditionals(list: any): void {
    let additionalUnified: AdditionalUnified[] = [];

    //Cria um novo objeto com as informações mensais e anuais
    for (let i = 0; i < list?.length; i++) {
      const channel = list[i];
      let channelName = this.cleanProductName((channel as Additionals).nome);
      
      if ((channel as Additionals).nome.toLowerCase().includes('mensal')
      ) {
        let additionalExistIndex = additionalUnified.findIndex(item => item.name === channelName);

        if (additionalExistIndex !== -1) {
          additionalUnified[additionalExistIndex].month = { ...(channel as Additionals) };          
          additionalUnified[additionalExistIndex].idMonth = (channel as Additionals).id;          
        } else {
            additionalUnified.push({
              showOptions: true,
              name: channelName,
              image: channelName,
              idMonth: (channel as Additionals).id,
              selected: (channel as Additionals).selected,
              month: { ...(channel as Additionals) }
          });
        }

      //Remove o objeto único
      list.splice(i, 1);
      i--;
    }      
    if ((channel as Additionals).nome.toLowerCase().includes('anual')) {
      let additionalExistIndex = additionalUnified.findIndex(item => item.name === channelName);

      if (additionalExistIndex !== -1) {
        additionalUnified[additionalExistIndex].year = { ...(channel as Additionals) };
        additionalUnified[additionalExistIndex].idYear = (channel as Additionals).id;          
      } else {
        additionalUnified.push({
          showOptions: true,
          name: channelName,
          image: channelName,
          idYear: (channel as Additionals).id,
          selected: (channel as Additionals).selected,
          year: { ...(channel as Additionals) }
        });
      }
      //Remove o objeto único
      list.splice(i, 1);
      i--;       
    }
  }

    for (let i = 0; i < additionalUnified.length; i++) {    
      if (additionalUnified[i]) {
        list.splice(i, 0, additionalUnified[i]);
      }  
    }
  }
}
