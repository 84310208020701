<div class="mdn-Modal-header">
    <h2 class="mdn-Heading mdn-Heading--sm header-Heading">
        Confira o que você ganhou!
    </h2>
    <button (click)="close()" class="mdn-Button-header-close mdn-Button mdn-Button--icon mdn-Button--secondary" aria-label="Close modal">
        <em class="mdn-Icon-fechar mdn-Icon--sm"></em>
    </button>
</div>
<div class="mdn-Modal-body">
    <h3 *ngIf="newPlan" class="mdn-Subtitle mdn-Subtitle--xs body-subtitle">Atualização de <span>+{{newPlan.bonus}}gb</span> no seu plano de móvel!</h3>
    <p class="mdn-Text mdn-Text--body">A sua franquia de móvel, preço e fidelidade serão atualizados nesta contratação.</p>
    <div class="mdn-Container-fluid">
        <div class="mdn-Row body-boxes-row">
            <div class="mdn-Col-xs-12 mdn-Col-md-5 grey-box grey-box-left">
                <h4 class="mdn-Subtitle mdn-Subtitle--xs">Seu plano atual</h4>
                <div class="mdn-Topic mdn-Topic--horizontal mdn-Topic--sm">
                    <div class="mdn-Topic-symbol">
                        <em class="mdn-Icon-celular mdn-Icon--sm"></em>
                    </div>
                    <p class="mdn-Topic-description">{{oldPlanName}}</p>
                    <p class="mdn-Topic-description price">{{ (oldPlanValue | currency) }}</p>
                </div> 
            </div>
            <div class="mdn-Col-xs-12 mdn-Col-md-2 body-arrow">
                <span class="mdn-Icon-seta-direita mdn-Icon--md view-desk"></span>
                <span class="mdn-Icon-seta-baixo mdn-Icon--md view-tablet"></span>
                <p class="mdn-Text mdn-Text--body">Trocando para</p>
            </div>
            <div class="mdn-Col-xs-12 mdn-Col-md-5 grey-box grey-box-right">
                <div class="mdn-Row">
                    <div class="mdn-Col-xs-12 grey-box-right-plan">
                        <h4 class="mdn-Subtitle mdn-Subtitle--xs plan-name">Plano atualizado</h4>
                        <div class="mdn-Topic mdn-Topic--horizontal mdn-Topic--sm">
                            <div class="mdn-Topic-symbol">
                                <em class="mdn-Icon-celular mdn-Icon--md"></em>
                            </div>
                            <p class="mdn-Topic-description">{{config.data.planName}}</p>
                            <p class="mdn-Topic-description price">{{ (config.data.planValue / 100 | currency) }}</p>
                        </div> 
                    </div>
                </div>
                <div *ngIf="newPlan" class="mdn-Row division-plan">
                    <div class="mdn-Col-xs-12">
                        <h5 class="mdn-Subtitle mdn-Subtitle--xs">Distribuição do seu plano</h5>
                    </div>
                    <div class="mdn-Col-xs-12 division-plan-tag-row">
                        <div *ngFor="let tag of newPlan.extras" class="division-plan-tag">{{tag}}</div>
                    </div>
                </div>

                <ng-template [ngIf]="newPlan">
                    <div *ngFor="let benefit of newPlan.benefits" class="mdn-Row grey-box-right-apps">
                        <div class="mdn-Col-xs-12">
                            <h5 class="mdn-Subtitle mdn-Subtitle--xs">{{benefit.name}}</h5>
                        </div>
                        <div class="mdn-Col-xs-12">
                            <div *ngIf="benefit.list" class="mdn-BrandGroup mdn-BrandGroup--sm">
                                <div *ngFor="let item of benefit.list" class="mdn-BrandGroup-item" href="#">
                                    <button class="mdn-BrandGroup-item-focus" aria-label="{{item.name}}"></button>
                                    <figure class="mdn-BrandGroup-image">
                                        <img class="mdn-BrandGroup-image"
                                            src="{{item.icon}}"
                                            alt="brand image" />
                                        <figcaption class="mdn-BrandGroup-image-caption mdn-Tooltip mdn-Tooltip--top">
                                            <p class="mdn-Text mdn-Text--caption mdn-Text--inverse">{{item.name}}</p>
                                        </figcaption>
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
        <div class="mdn-Row view-tablet">
            <div class="mdn-Tag mdn-Tag--feedback">              
            </div>
        </div>
    </div>
</div>
<div class="mdn-Modal-footer">
    <div class="mdn-Modal-footer-start">
        <div class="mdn-Tag mdn-Tag--feedback view-desk">           
        </div>
    </div>
    <div class="mdn-Modal-footer-end">
        <button (click)="continuePurchase()" class="mdn-Button mdn-Button--global">Avançar</button>
    </div>
</div>