import { Component, OnInit } from '@angular/core';
import { MobileCustomerInfoModel } from 'src/app/models/mobile-customer-identification.model';
import { CroService } from 'src/app/services/cro.service';
import { CommonNavigationService } from 'src/app/services/navigation.service';
import { UtilsService } from 'src/app/services/utils.service';
import { MdnModalConfig } from '../../mondrian-components/mdn-modal/mdn-modal-config';
import { MdnModalRef } from '../../mondrian-components/mdn-modal/mdn-modal-ref';
import { ConfiraOQueGanhouService } from './confira-o-que-ganhou.service';

@Component({
  selector: 'app-confira-o-que-ganhou',
  templateUrl: './confira-o-que-ganhou.component.html',
  styleUrls: ['./confira-o-que-ganhou.component.scss']
})
export class ConfiraOQueGanhouComponent implements OnInit {

  croOrigin: string = 'confira-o-que-voce-ganhou';
  oldPlanName: string = '';
  oldPlanValue: number = 0;
  newPlan: any;

  constructor(
    public cro: CroService,
    public utils: UtilsService,
    public navigationService: CommonNavigationService,
    public config: MdnModalConfig,
    public dialog: MdnModalRef,
    public service: ConfiraOQueGanhouService,
  ) { }

  ngOnInit(): void {
    const customerMobileInfoApiReturn = this.utils.storageGetItem('customer-mobile-info-api-return');
    if (!!customerMobileInfoApiReturn) {
      const oldPlan: MobileCustomerInfoModel = JSON.parse(customerMobileInfoApiReturn);
      this.oldPlanName = oldPlan.planName;
      this.oldPlanValue = oldPlan.planValue;
    }

    const clienteMovelDeParaMobilePlan = this.utils.storageGetItem('cliente-movel-de-para-mobile-plan');
    const planId = clienteMovelDeParaMobilePlan && JSON.parse(clienteMovelDeParaMobilePlan)?.para;

    if (!!planId) {
      this.service.getAdditionalComboPlanInfo().subscribe(
        resp => {
          this.service.additionalComboPlanInfo = resp;
          const mobileId = planId;
          this.newPlan = resp.find(plan => plan.id === mobileId);
        },
        err => {
          console.error(err);
        }
      );
    }
  }

  continuePurchase(): void {
    this.cro.clickButtonModal(this.croOrigin, 'avancar');
    this.navigationService.sendToNext();
    this.close();
  }

  close(): void {
    this.cro.clickButtonModal(this.croOrigin, 'fechar');
    this.dialog.close();
  }

}
