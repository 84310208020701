import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRouting } from './app-routing';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { LoadingModule } from './shared/loading/loading.module';
import { MenuModule } from './shared/mondrian-components/menu/menu.module';
import { FooterModule } from './shared/mondrian-components/footer/footer.module';
import { HttpClientModule } from '@angular/common/http';
import { AlertModule } from './shared/mondrian-components/alert/alert.module';
import { ReactiveFormsModule } from '@angular/forms';
import { Drawer2Module } from '../app/shared/drawer2/drawer2.module';
import { NewAdicionaisModule } from '../app/modules/new-adicionais/new-adicionais.module';
import { DisclaimerModule } from './shared/mondrian-components/accordion/accordion.module';
import { ReguaTransacionalOfertaPlanoMovelModule } from './shared/regua-transacional-oferta-plano-movel/regua-transacional-oferta-plano-movel.module';


@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRouting,
    BrowserModule,
    BrowserAnimationsModule,
    FooterModule,
    HttpClientModule,
    LoadingModule,
    MenuModule,
    ReactiveFormsModule,
    AlertModule,
    Drawer2Module,
    NewAdicionaisModule,
    DisclaimerModule,
    ReguaTransacionalOfertaPlanoMovelModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule { }
