import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private message = new Subject<string>();
  public notifier = this.message.asObservable();

  notify(text: string) {
    this.message.next(text);
  }

}
