import {
  AfterViewInit, ChangeDetectorRef, Component, ComponentFactoryResolver, ComponentRef, HostListener, OnDestroy, OnInit, Type, ViewChild,
} from '@angular/core';

import { MdnModalConfig } from './mdn-modal-config';
import { MdnModalRef } from './mdn-modal-ref';
import { MdnModalDirective } from './mdn-modal.directive';

import { Subject } from 'rxjs';

@Component({
  selector: 'app-mdn-modal',
  styleUrls: ['./mdn-modal.component.scss'],
  templateUrl: './mdn-modal.component.html',

})
export class MdnModalComponent implements OnInit, AfterViewInit, OnDestroy {

  public componentRef: ComponentRef<any>;
  public childComponentType: Type<any>;

  @ViewChild(MdnModalDirective) public insertionPoint: MdnModalDirective;

  // cria um promessa para o onclose
  public readonly _onClose = new Subject<any>();
  public onClose = this._onClose.asObservable();
  public size = 'sm';
  public titulo: string;
  public type: string;
  public nomeGtm = 'modal';
  public bannerTy

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    public config: MdnModalConfig,
    private cd: ChangeDetectorRef,
    public dialogRef: MdnModalRef,
  ) { }

  public ngOnInit(): any {

    if (this.config.data && this.config.data.size) {
      this.size = this.config.data.size;
    }
    if (this.config.data && this.config.data.titulo) {
      this.titulo = this.config.data.titulo;
    }
    if (this.config.data && this.config.data.type) {
      this.type = this.config.data.type;
    }
    if (this.config.data.data && this.config.data.data.nomeGtm) {
      switch (this.type) {
        case 'lineup':
          this.nomeGtm = 'modal-planos-de-tv:'+this.config.data.data.nomeGtm;
          break;
        default:
          this.nomeGtm = 'modal:'+this.config.data.data.nomeGtm;
          break;
      }
    }
  }

  public ngAfterViewInit(): void {
    this.loadChildComponent(this.childComponentType);
    this.cd.detectChanges();
  }

  public ngOnDestroy(): void {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  public loadChildComponent(componentType: Type<any>): void {
    // Cria uma refrência do componente que será carregado dentro do modal
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);
    // Cria uma referência de onde o componente filho sera anexado no modal
    const viewContainerRef = this.insertionPoint.viewContainerRef;
    viewContainerRef.clear();

    // injeta o componente filho na referência criada
    this.componentRef = viewContainerRef.createComponent(componentFactory);
  }

  public close(): void {
    this._onClose.next();
  }

  public onOverlayClicked(evt: MouseEvent): void {
    if (!this.config.data.disableClose === true) {
      this.dialogRef.close();
    }
  }

  public onDialogClicked(evt: MouseEvent): void {
    evt.stopPropagation();
  }

  @HostListener('document:keydown.escape')
  public onKeydownHandler(event: KeyboardEvent): any {
    if (!this.config.data.disableClose) {
      this.close();
    }
  }
}
