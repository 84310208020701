import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Address } from '../models/address';
import { map } from 'rxjs/operators';
import jwtDecode from "jwt-decode";

@Injectable({
  providedIn: 'root'
})
export class EnderecoService {

    private endereco = new Address();

    constructor(
      private http: HttpClient
    ) { }

    buscarEndereco(cep: string, number: string, fingerprint_: string): Observable<any> {
      const httpOptions = {
        headers: new HttpHeaders({
          'cep'   : cep,
          'number': number,
          'x-fingerprint': fingerprint_,
          'x-channel': 'ecommerce',
          'x-segment': 'pf',
          'x-partner': '',
          'x-customer-journey': 'prospect'
        }),
      };

      return this.http.get(environment.coberturaHomepassed, httpOptions).pipe(
        map((x: any) => jwtDecode(x))
      )
    }

    buscarAddressHomepassed(cep: string, number: string, dthRegion: boolean, fingerprint_: string): Observable<any> {
      const httpOptions = {
        headers: new HttpHeaders({
          'x-channel': 'ecommerce',
          'x-segment': 'pf',
          'x-partner': '',
          'x-customer-journey': 'prospect',
          'x-fingerprint': fingerprint_
        }),
      };
      return this.http.get(`${environment.apiAddress}homepassed/${cep}/${number}?dthRegion=${dthRegion}`, httpOptions);
    }

    setEndereco(endereco: any) {
      this.endereco = endereco;
    }

    getEndereco() {
      return this.endereco;
    }

    getFormatedInstallationType(street: string): string {
      let type: string;
  
      street = street.trim();
      const index_: number = street.indexOf(' ');
  
      type = index_ < 0 ? 'R' : street.substr(0, index_)
     
      return type 
    }

    // showCalendar(products: any): boolean {
    //   if (products.length === 1 && (products[0].type === 'celular' || products[0].tags?.indexOf('inibicaoagenda') >= 0)) {
    //     return false;
    //   } else {
    //     let allIsInibicaoAgenda = true;
    //     products.forEach(product => {
    //       if ((product.tags === null || product.tags?.indexOf('inibicaoagenda') === -1) && product.type !== 'celular') {
    //         allIsInibicaoAgenda = false;
    //       }
    //     });
    //     return !(allIsInibicaoAgenda);
    //   }
    // }
}