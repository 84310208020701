import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

// SERVICES
import { StoryBlockService } from 'src/app/services/storyblock.service';
import { MdnModalService } from 'src/app/shared/mondrian-components/mdn-modal/mdn-modal.service';

// COMPONENTS
import { BannerPromocionalComponent } from 'src/app/modules/monte-sua-combinacao/modals/banner-promocional/banner-promocional.component';

interface bannerInfoI {
  url_imagem_desktop: string,
  url_imagem_mobile: string,
  promoId: string,
  promoCategory: string,
  noLoyalty: string,
  slug: string,
  idProducts: any
}

@Injectable({
  providedIn: 'root'
})
export class BannerPromocionalService {

  setBannerInfoReady$ = new Subject<void>();

  private new_Technology: string;
  private new_BannerInfo: bannerInfoI[];
  private new_MobileCustumerInfo: any;
  
  constructor(
    private storyBlockService: StoryBlockService,
    private modal: MdnModalService
  ) { }

  public setStoragePromoInCart(value: string) {
    sessionStorage.setItem('claro-movel-promo-incart', value);
  }

  public getStoragePromoInCart(): string {
    return sessionStorage.getItem('claro-movel-promo-incart');
  }

  public setTechnology(technology_: string) {
    this.new_Technology = technology_;
  }

  public shouldReopenBanner() {
    return this.getStoragePromoInCart() === 'true';
  }

  public isPromoInCartRemoved(): boolean {
    return this.getStoragePromoInCart() === 'removed';
  }

  public openBanner(cart: any, stop: boolean = false) {
    const loyalty = sessionStorage.getItem('loyalty');
    if (loyalty === 'false') { return }

    this.getBannersInfo();

    if (!!this.new_BannerInfo && this.new_BannerInfo?.length > 0) {
      const selection = cart?.selection;
      for (let i = 0; i < this.new_BannerInfo?.length; i++) {
        const promoCategory = this.new_BannerInfo[i].promoCategory;
        const promoId = this.new_BannerInfo[i].promoId;
        const promoIdArray = promoId.split(',');
        const found = promoIdArray?.find(x => {
          return selection && selection[promoCategory] && selection[promoCategory].id === Number(x)
        })

        if (!!found) { return}
      }
      
      this.openModal(this.new_BannerInfo);
      return
    }

    this.new_MobileCustumerInfo = sessionStorage.getItem('customer-mobile-info-api-return');
    const custumerPlanCategory = !!this.new_MobileCustumerInfo && JSON.parse(this.new_MobileCustumerInfo)?.planType;

    if (this.new_Technology && custumerPlanCategory) {
      this.storyBlockService.getBannersPromotionalClienteMovelInfo(this.new_Technology, custumerPlanCategory).subscribe(resp => {
        this.setBannerInfo(resp);

        if (!!this.new_BannerInfo && !stop) {
          this.openBanner(cart, true);
        }
      })
      return
    }

    console.warn('Não foi encontrado dados suficiêntes para abrir o banner promocional para clientes Claro Móvel')
  }

  public getBannersInfo(): bannerInfoI[] {
    if (!!this.new_BannerInfo) {
      return this.new_BannerInfo;
    }

    const bannerInfoSessionStorage = sessionStorage.getItem('banner_info_cliente_claro_movel');
    if (!!bannerInfoSessionStorage) {
      const newBannerInfo = JSON.parse(bannerInfoSessionStorage);
      this.new_BannerInfo = newBannerInfo;
      return newBannerInfo
    }

    return undefined
  }

  private openModal(bannerInfo: bannerInfoI[]) {
    this.modal.open(BannerPromocionalComponent, {
      data: {
        type: 'bannerPromocionalComponent',
        banners: bannerInfo,
      },
    });
  }

  private setBannerInfo(data: any) {
    let dataBannersInfoStoryBlok;
    if(data?.stories){
      dataBannersInfoStoryBlok = []
      data.stories.forEach(story => {
        const { content, slug } = story;
        const storyInfo = {
            ...content,
            slug
        };
        dataBannersInfoStoryBlok.push(storyInfo)
      });
    }

    if (dataBannersInfoStoryBlok) {
      let bannersInfo_: bannerInfoI[] = []
      dataBannersInfoStoryBlok.forEach(dataBannerInfoStoryBlok => {
        bannersInfo_.push({
          url_imagem_desktop: dataBannerInfoStoryBlok.banner_desktop,
          url_imagem_mobile: dataBannerInfoStoryBlok.banner_mobile,
          promoId: dataBannerInfoStoryBlok.promo_id,
          promoCategory: dataBannerInfoStoryBlok.promo_category,
          noLoyalty: dataBannerInfoStoryBlok.promo_id_no_loyalty,
          slug: dataBannerInfoStoryBlok.slug,
          idProducts: dataBannerInfoStoryBlok.idProducts
        })
      });
      
      this.new_BannerInfo = bannersInfo_;
      sessionStorage.setItem('banner_info_cliente_claro_movel', JSON.stringify(bannersInfo_));
      this.setBannerInfoReady$.next();
    }
  }
}