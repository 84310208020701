import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputSelectComponent } from './input-select.component';

@NgModule({
  imports: [
    CommonModule
  ],
  exports: [
    InputSelectComponent
  ],
  declarations: [
    InputSelectComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class InputSelectModule { }
