export class List {
    entertainment?: any[];
    tv?: Tv;
    connectivity?: Config[];
}

export class ChannelUnified {
    showOptions: boolean;
    name: string;
    order: number;
    image: string;
    idMonth?: number;
    idYear?: number;
    selected: boolean;
    month?: Channel;
    year?: Channel;
}

export class AdditionalUnified {
    showOptions: boolean;
    name: string;
    image: string;
    idMonth?: number;
    idYear?: number;
    selected: boolean;
    month?: Additionals;
    year?: Additionals;
}

export class Tv {
    channels?: Array<Channel | ChannelUnified>;
    additionals?: Additionals[];
    configs?: Config[];
}

export class Additionals {
    categoryPlan?: string;
    acrescimoNaoDCC: number;
    adesao: number;
    adesaoNaoFidelidade: number;
    ate: string;
    categoria: Category;
    descricao: string;
    id: number;
    multipleChoice: number;
    nome: string;
    periodos: number[];
    preco: number;
    precoDe: number;
    taxaInstalacao: number;
    tvAdicional: number;
    visible?: boolean;
    hidden?: boolean;
    selected?: boolean;
    paidInInstallments?: boolean;
    isPaidInInstallments?: boolean;
    installmentsAmount?: number | null;
    installmentPrice?: number | null;
}

export class Category {
    id: number;
    nome: string;
}

export class Channel {
    descricaoCapa: string;
    categoryPlan: string;
    id: number;
    imagens: CanaisImagens;
    nome: string;
    ordem: number;
    periodos: number[];
    preco: number;
    precoDe: number;
    src: string;
    tipo: string;
    visible?: boolean;
    hidden?: boolean;
    selected?: boolean;
    isPaidInInstallments: boolean;
    installmentsAmount: number | null;
    installmentPrice: number | null;
}

export class CanaisImagens {
    capa: string;
    canal: string;
    descricao: string;
}

export class Config {
    id: number;
    multipleChoice: number;
    nome: string;
    opcoes: ConfigOptions[];
    required: number;
    tvAdicional: number;
}

export class ConfigOptions {
    acrescimoNaoDCC: number;
    adesao: number;
    adesaoNaoFidelidade: number;
    ate: string;
    descricao: string;
    id: number;
    multipleChoice: number;
    nome: string;
    otherOptions: number[];
    periodos: number[];
    preco: number;
    precoDe: number;
    prazo?: string;
    taxaInstalacao: number;
    tvAdicional: number;
}

export class CroData {
    action: string;
    data: Additionals;
}

export class TabConfig {
    conectividade: TabData;
    entretenimento: TabData;
    tv: TabData;
}

export class TabData {
    icon: string;
}

export class Card {
  id: number;
  categoryPlan: string;
  imgSrc: string;
  name: string;
  description: string;
  price: number;
  empacotado: boolean;
  prazo: string;
  priceOf: number;
  deadline: string;
  isPaidInInstallments: boolean;
  installmentsAmount: number;
  installmentPrice: number;
}

export interface AdicionalParcelavel {
  id: number
  nome: string
}
