<div class="mdn-Modal-header">
    <h1 class="mdn-Heading mdn-Heading--xs">
        {{titulo}}
    </h1>
</div>

<div class="mdn-Modal-body">
    <p>Verificamos que você já é nosso cliente, assim você pode contratar esse produto 
        para o seu endereço atual ou para um novo endereço, basta selecionar 
        abaixo a opção desejada.
    </p>
</div>

<div class="mdn-Modal-footer">
    <div class="mdn-Modal-footer-end">
        <button class="mdn-Button mdn-Button--lg mdn-Button--primary" (click)="redirectClient()"
            (keydown.enter)="redirectClient()"
            [attr.data-gtm-event-category]="gtmCategory+':modal-ja-e-cliente'"
            data-gtm-event-action="clique:botao:modal:ja-e-cliente" data-gtm-event-label="mesmo-endereco">
            Utilizar o mesmo endereço
        </button>
        <button class="mdn-Button mdn-Button--lg mdn-Button--global" (click)="fecharDialog()" (keydown.enter)="fecharDialog()"
            [attr.data-gtm-event-category]="gtmCategory+':modal-ja-e-cliente'"
            data-gtm-event-action="clique:botao:modal:ja-e-cliente" data-gtm-event-label="endereco-diferente">
            Cadastrar novo endereço
        </button>
    </div>
</div>