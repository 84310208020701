import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { MdnModalService } from 'src/app/shared/mondrian-components/mdn-modal/mdn-modal.service';
import { environment } from 'src/environments/environment';
import { BucketS3Service } from '../../bucket-s3.service';
import { CarrinhoService } from '../../carrinho.service';
import { UtilsService } from '../../utils.service';
import { ActivatedRoute } from '@angular/router';
import { StoryBlockService } from '../../storyblock.service';
import { BannerPromocionalService } from 'src/app/modules/monte-sua-combinacao/modals/banner-promocional/banner-promocional.service';
import { ViabilityService } from '../../viability.service';
import { catchError, map } from 'rxjs/operators';
import { MobileClientAuthenticatorComponent } from 'src/app/shared/dialog/mobile-client-authenticator/mobile-client-authenticator.component';
import { FingerprintService } from '../../fingerprint.service';

@Injectable({
  providedIn: 'root'
})
export class IdentificacaoMovelService {

  public isClienteMovel: boolean = this.utils.storageGetItem('cliente-claro-movel') === 'true';
  public isPromoApplied: boolean = this.utils.storageGetItem('claro-movel-promo-applied') === 'true';

  public config: any;
  originIdListToAuthentication: string[];
  isSatellite: boolean = false;

  constructor(
    private http: HttpClient,
    public carrinho: CarrinhoService,
    public bucketS3Service: BucketS3Service,
    public utils: UtilsService,
    public modal: MdnModalService,
    private activatedRoute: ActivatedRoute,
    public storyBlockService: StoryBlockService,
    private bannerPromocionalService: BannerPromocionalService,
    public viabilityService: ViabilityService,
    private fingerprintService: FingerprintService
  ) {
    this.fetchClaroMovelConfig();

    this.carrinho.updateIsClienteMovel$.subscribe((resp) => {
      this.isClienteMovel = resp;
    });

    this.carrinho.openModalCustomerIndetification$.subscribe((type) => {
      this.modal.open(MobileClientAuthenticatorComponent, {
        data: {
          size: 'lg',
          titulo: 'Identificação do cliente',
          disableClose: true,
          type: 'IdentificacaoMovelComponent',
          action: 'validates-plan-addition',
          planType: type,
        },
      });
    });

    this.activatedRoute.queryParams.subscribe(params => {
      const verifiedMobileCustomerByOriginidStage = sessionStorage.getItem('verified-mobile-customer-by-originid');

      if (
        !!params.originId &&
        verifiedMobileCustomerByOriginidStage !== 'true'
      ) {
        this.getOriginIdListToAuthentication().subscribe(resp => {
          if (resp?.includes(params.originId)) {
            let cartQtd = 0;

            ['internetId', 'celularId', 'tvId', 'foneId'].forEach(plan => {
              const storage = sessionStorage.getItem(plan);
              if (!!storage) { cartQtd++ }
            })

            this.fingerprintService.getFingerprint().subscribe((fingerprint_: string) => {
              this.checkIfTechnologyIsSatellite(fingerprint_).subscribe(isSatellite => {
                if (!isSatellite && cartQtd > 0) {
                  this.modal.open(MobileClientAuthenticatorComponent, {
                    data: {
                      size: 'lg',
                      titulo: 'Identificação do cliente',
                      disableClose: true,
                      type: 'IdentificacaoMovelComponent',
                      action: 'MobileCustumerIdentificationComboInternetMobile',
                      flow: 'originId'
                    },
                  });
                }
              });
            })
          }
        })
      }
    })

    this.bannerPromocionalService.setBannerInfoReady$.subscribe(() => {
      this.atualizouCarrinhoActions();
    })

  }

  //TODO procurar uma forma já existente de descobrir a technologia sem precisar consultar a Viability
  checkIfTechnologyIsSatellite(fingerprint_: string): Observable<boolean> {
    const storageCep = sessionStorage.getItem('cep');
    const storageNumber = sessionStorage.getItem('number')

    return this.viabilityService.getNewViability(storageCep, storageNumber, '', '', fingerprint_).pipe(
      map((item) => {
        const technologies = item.data.technologies;
        const techName = technologies[0].name;
        if (techName === 'Satellite' && technologies.length === 1) {
          this.isSatellite = true;
        }
        return this.isSatellite;
      }),
      catchError((error) => {
        console.log(error);
        return of(false);
      })
    );
  }
  
  checkIsPromoInCart(): boolean {
    const isClientMovel = sessionStorage.getItem('cliente-claro-movel') === 'true';

    if (isClientMovel) {
      const bannersInfo = this.bannerPromocionalService.getBannersInfo();

      if (!!bannersInfo) {
        for (let i = 0; i < bannersInfo?.length; i++) {
          const promoType = bannersInfo[i].promoCategory;
          const promoId = bannersInfo[i].promoId;
          const currentProductInCart = this.carrinho.parser.cart.selection && this.carrinho.parser.cart.selection[promoType];

          if (currentProductInCart) {
            return currentProductInCart.id.toString() === promoId;
          }
        }
      }
    }

    return false;
  }

  subscribeToCartUpdates(): void {
    this.carrinho.atualizouCarrinho$.subscribe(() => {
      this.atualizouCarrinhoActions();
    });
  }

  atualizouCarrinhoActions() {
    const isPromoInCart = this.checkIsPromoInCart();
    this.isPromoApplied = isPromoInCart;
    this.utils.storageSetItem('claro-movel-promo-applied', isPromoInCart.toString());
  }
  getOriginIdListToAuthentication(): Observable<string[]> {
    if (this.originIdListToAuthentication === undefined) {
      return this.http.get<string[]>(
        environment.ecommerceResponsivo + 'mobile-customer-identification/originIdListToAuthentication.json ',
        { responseType: 'json' }
      );
    } else {
      return of(this.originIdListToAuthentication);
    }
  }
  fetchClaroMovelConfig(): void {
    this.storyBlockService.getRegrasGeraisData().subscribe(data => {
      this.config = data;
      this.subscribeToCartUpdates();
      this.utils.storageSetItem('identificacao-cliente-movel-data', JSON.stringify(data));
    },
      (error) => {
        console.error(error);
      }
    );
  }

  openBanner(): void {
    this.isClienteMovel = true;
    const cart = this.carrinho.getCart();
    this.bannerPromocionalService.openBanner(cart);
  }
}
