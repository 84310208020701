import { Injectable } from '@angular/core';
import { AssineJaParserService } from './assine-ja-parser.service';
import { Observable, Subject } from 'rxjs';
import { StoryBlockService } from './storyblock.service';

@Injectable({
  providedIn: 'root'
})
export class CroFormVersionsService {
  enums = {
    croFormVersion: 'cro-form-version',
    emailMock: 'deadpooltestleads-',
    useAffiliateIdCroFormVersion: 'use-affiliateId-cro-form-version'
  };
  affiliateIds = {
    v1: 'HJoI*dCzT',
    v2: 'rygF*dAf6'
  }
  croVersions = ['v1', 'v2'];
  productsMock: any = {};
  croFormversion = '';

  sendLeadForCroTest$ = new Subject<any>();

  constructor(
    public assine: AssineJaParserService,
    public storyBlockService: StoryBlockService
  ) {
    if (this.isACroFormVersion()){

      this.storyBlockService.getCroFormTestProductsMock().subscribe(res => {
        const respObj = res.story.content.jsonProduto;
        this.productsMock = respObj ? JSON.parse(respObj) : undefined;
      })

      sessionStorage.setItem('originId', `cro_form_version_${sessionStorage.getItem(this.enums.croFormVersion)}`)
    }

    if (sessionStorage.getItem(this.enums.croFormVersion) === 'original') {
      sessionStorage.setItem('originId', `cro_form_version_original`)
    }
  }

  getMockProduct(): any {
    const tech = this.getTechnology();
    return this.productsMock[tech];
  }

  getNoMockProducts(leadProducts: any[]): any {
    if (leadProducts.length > 0) {
      return leadProducts.filter((product: any) => {
        if (product?.id) {
          return String(product.id) === sessionStorage.getItem(`${product.type}Id`)
        }
      });
    }

    return [];
  }

  getTechnology(): string {
    let tech = 'dth';
    const technologiesAvailable = this.assine.technologiesAvailable;
    const cableTechnology = technologiesAvailable.find(techs => techs.name === 'Cable');
    if (cableTechnology) {
      const isGpon = cableTechnology.gpon;
      tech = isGpon ? 'gpon' : 'hfc'
    }
    return tech
  }

  getAffiliateId(): string {
    return this.affiliateIds[this.croFormversion];
  }

  getUseAffiliateIdCroFormVersion(): boolean {
    const useAffiliateIdCroFormVersion = sessionStorage.getItem(this.enums.useAffiliateIdCroFormVersion);
    return useAffiliateIdCroFormVersion === 'true';
  }

  isACroFormVersion(): boolean {
    const croFormVersion = sessionStorage.getItem(this.enums.croFormVersion);
    this.croFormversion = croFormVersion ? this.croVersions.find(version => version === croFormVersion) : undefined;
    return !!this.croFormversion
  }

}
