export class ReguaTransacionalOfertaPlanoMovelModel {
    type: string;
    name: string;
    link: string;
    price: number;
    extras?: string[];
    benefits?: ReguaTransacionalOfertaPlanoMovelBenefitsModel[];
}

class ReguaTransacionalOfertaPlanoMovelBenefitsModel {
    name: string;
    list: ReguaTransacionalOfertaPlanoMovelBenefitModel[];
}

class ReguaTransacionalOfertaPlanoMovelBenefitModel {
    name: string;
    icon: string;
}
