import { Component, OnInit } from '@angular/core';
import { CarrinhoService } from 'src/app/services/carrinho.service';
import { CroService } from 'src/app/services/cro.service';
import { UtilsService } from 'src/app/services/utils.service';
import { AlertService } from 'src/app/shared/mondrian-components/alert/alert.service';
import { MdnModalConfig } from 'src/app/shared/mondrian-components/mdn-modal/mdn-modal-config';
import { MdnModalRef } from 'src/app/shared/mondrian-components/mdn-modal/mdn-modal-ref';
import { BannerPromocionalService } from './banner-promocional.service';
import { ViabilityService } from 'src/app/services/viability.service';
import { SwiperBannerConfig } from '../../../../models/swiper-banner-config.model';
import { Router } from '@angular/router';
import { AssineJaParserService } from 'src/app/services/assine-ja-parser.service';

@Component({
    selector: 'app-banner-promocional',
    styleUrls: ['./banner-promocional.component.scss'],
    templateUrl: './banner-promocional.component.html',
})

export class BannerPromocionalComponent implements OnInit {

    croOrigin: string = 'cliente-claro-movel-beneficios-banner';
    croName: string = 'cliente-claro-movel-beneficios-banner';
    public obj: any = this.config.data;
    public banners: string[] = [];
    public swiperBannerConfig: SwiperBannerConfig;
    public banner: string;
    private tech: string;

    constructor(
        public dialog: MdnModalRef,
        public config: MdnModalConfig,
        public carrinho: CarrinhoService,
        public alert: AlertService,
        public cro: CroService,
        public utils: UtilsService,
        private service: BannerPromocionalService,
        private router: Router,
        public viabilityService: ViabilityService,
        public assine: AssineJaParserService,
    ) { }

    public ngOnInit(): void {
        this.setTechAndCroModal()
        this.setBannersByWindowWidth()
        this.setSwiperBannerConfig()
        this.setHtSkipPointerEvents()
    }

    setHtSkipPointerEvents(): void {
        const htSkipDiv: HTMLElement = document.querySelectorAll("body > div.ht-skip > div")[0] as HTMLElement;
        if (htSkipDiv) {
            htSkipDiv.style.pointerEvents = 'none';
        }
    }

    setTechAndCroModal(): void {
      this.tech = this.viabilityService.getAddressTechnology();

      if (this.tech !== 'hfc') {
          this.cro.openModal(this.croName, '', '', this.tech);
      } else {
          this.cro.openModal(this.croName, '', '', 'hfc');
      }
  }

    setBannersByWindowWidth(): void {
        if (window.innerWidth >= 768 || navigator.userAgent.toLowerCase() == 'desktop') {
            this.obj.banners.forEach(banner => {
                this.banners.push(banner.url_imagem_desktop.filename)
            });
        } else {
            this.obj.banners.forEach(banner => {
                this.banners.push(banner.url_imagem_mobile.filename)
            });
        }
    }

    setSwiperBannerConfig(delay: number = 3000, autoPlay: boolean = true): void {
        this.swiperBannerConfig = {
            config: {
                delay: delay,
                autoPlay: autoPlay,
            },
            banners: this.banners
        }
    }

    onClickBanner(bannerIndex: number): void {
        this.dialog.close();

        if (!!this.obj.banners) {
            this.utils.storageSetItem('originId', 'cliente_movel');
            if (!this.service.isPromoInCartRemoved()) {
                this.service.setStoragePromoInCart('true');
            }
            const slug = this.obj.banners[bannerIndex].slug;

            const products = this.obj.banners[bannerIndex].idProducts.tbody.reduce((acc, curr) => {
              const currCategories = curr.body[0].value.slice(0, -2);
              const currIds = curr.body[1].value;
            
              const categories = currCategories.split(',').map(x => x.trim());
            
              return {
                categories: [...acc.categories, ...categories],
                ids: [...acc.ids, currIds]
              };
            }, { categories: [], ids: [] });
            
            products.categories.forEach((category: string, index: number) => {            
              const idsArray = products.ids[index].split(',');
              let idFound = idsArray.find(x => this.assine.parser.data.getProductById(category, x));
            
              if (idFound) {
                idFound = idFound.trim();
            
                const eventAction = `contratar:${slug}:${this.tech}:${idFound}`;
                this.cro.clickButtonModal(this.croOrigin, eventAction, 'clique:botao:modal:' + this.croName);

                this.carrinho.comprarProduto({
                  id: idFound,
                  tipo: category,
                  cookie: false,
                  showAlert: true,
                });
              } else {
                idsArray.length === 1 ?
                  console.error(`Não foi possível encontrar o plano de ${category} id: ${products.ids[index]}`) :
                  console.error(`Não foi possível encontrar os planos de ${category} ids: ${products.ids[index]}`);
              }
            });

            this.router.navigate(['/checkout/dados-pessoais']);
           
        }
  }

  closeModal(): void {
    this.cro.clickButtonModal(this.croOrigin, 'fechar:' + this.tech);
    this.dialog.close();
}
}
