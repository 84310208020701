import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MobileInfoService {
  private ENV = environment;
  private MOBILE_INFO_URL: string = `${this.ENV.mobileInfo}`;

  private mock1 = {
    "data": {
      "dependents": [
        {
          "msisdn": "11999999999",
          "situation": "ATIVO", // "oneOf(\"ATIVO\", \"SUSPENSO\", \"CANCELADO\", \"PREATIVO\")"
          "type": "VOZ",
          "planType": "POS_PAGO", // "oneOf(\"POS_PAGO\", \"PRE_PAGO\", \"CONTROLE\")"
          "planComponents": [
            "VOZ",
            "DADOS",
            "TORPEDOS"
          ]
        }
      ],
      "customerType": "ownerx",
      "customerName": "RAFAEL GRACIOLI",
      "situation": "ATIVO",
      "planName": "Claro Controle Conectado 8GB + SVA",
      "planValue": 57,
      "planType": "POS_PAGO",
      "promotionDiscountPlanValue": 0,
      "netSubscriberValue": 47,
      "planId": "209688",
      "type": "VOZ",
      "addresses": [
          {
              "prefix": "R",
              "street": "R JUAREZ PEREIRA DA SILVA",
              "neighborhood": "JARDIM BEIJA FLOR",
              "city": "INDAIATUBA",
              "state": "SP",
              "zipCode": "13349271"
          }
      ],
      "planComponents": []
    },
    "headers": {
        "Access-Control-Allow-Origin": "*"
    },
    "statusCode": 200,
    "meta": {
        "code": "200",
        "message": "OK"
    }
  }

  private mock2 = {
    "data": {
      "dependents": [
        {
          "msisdn": "11989376064",
          "situation": "ATIVO",
          "planType": "PRE_PAGO",
          "type": "VOZ",
          "customerName": "MARCOS MOREIRA LEITE",
          "planComponents": []
        }
      ],
      "customerType": "owner", // owner / dependent
      "situation": "ATIVO",
      "planName": "Claro Pós+ 50GB  Combo + SVA",
      "planValue": 109.9,
      "planType": "POS_PAGO",
      "promotionDiscountPlanValue": 10, 
      "netSubscriberValue": 99.9, 
      "planId": "22402", 
      "type": "VOZ", 
      "addresses": [
        {
          "prefix": "ACS",
          "street": "PRF LUIS E DE B VIDIGAL", 
          "neighborhood": "CENTRO", 
          "city": "OSASCO", 
          "state": "SP", 
          "zipCode": "06093085" 
        }
      ], 
      "customerName": "MARCOS MOREIRA LEITE", 
      "planComponents": [] 
    }
  }

  constructor(
    public http: HttpClient
  ) { }

  getNewCustomerMobileInfo(cpf: string, msisdn: string, code: string, token: string): Observable<any> {
    return this.http.get<any>(`${this.MOBILE_INFO_URL}?cpf=${cpf}&msisdn=${msisdn}&code=${code}&token=${encodeURIComponent(token)}`);

    // return of (
    //   this.mock2
    // );

    // return throwError(
    //   {
    //     error: {
    //       statusCode: 422,
    //       error: {
    //         code:'400-006'
    //       }
    //     }
    //   }
    // )
  }
}
