import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { FilterQueryModel, ResolveRelationsModel } from './../models/storyblok-models';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { map, shareReplay, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})

export class StoryBlockService {
    public spaceDir = 'residencial/prospect';
    public storiesPerPage = '25';
    private storyBlokData = new BehaviorSubject<any>(null);
    public storyBlokServiceReady$: Subject<any> = new Subject<any>();
    private storyBlokCroFormTestURL = 'https://api.storyblok.com/v2/cdn/stories/residencial/prospect/cro-form-version/produto?version=published&token=PP1FL5OznEAZUdDziBYDKwtt&cv=1698180472'
    public isBlackFriday:boolean = false;
    public mobileAffiliateIds:string = '';
    public city: any = {};
    public groupRules: any = {};
    public nodes: any = {};
    public nodeData: any = {};
    public generalContentVitrine: any = {};
    public todasRegrasCidade: any = {};
    public appIds: any
    public tvIdGroup: any
    public useDefaultPromotions: any = {}
    
    private cache$: Observable<any> | null = null;

    constructor(
        private http: HttpClient
    ) { }

    public getConfig(fileSlug: string, filterQuery?: FilterQueryModel, resolveRelations?: ResolveRelationsModel, page?: string) {
        const envData = environment.storyBlock;
        const params = {
            page: page || '1',
            per_page: this.storiesPerPage,
            starts_with: `${this.spaceDir}/${fileSlug}`,
            token: envData.token,
            version: envData.version
        }

        if (filterQuery) {
        params[`filter_query[${filterQuery.attribute}][${filterQuery.operation}]`] = filterQuery.value;
        }

        if (resolveRelations) {
        params['resolve_relations'] = `${resolveRelations.component}.${resolveRelations.field}`
        }

        return this.http.get(envData.api, { params });
    }
    
    getdccRulesPerCategory(citySegment: string) {
        let dccRulesPerCategory = this.todasRegrasCidade[citySegment];
        sessionStorage.setItem('dccRulesPerCategory', JSON.stringify(dccRulesPerCategory));
        return dccRulesPerCategory
    }

    setTodasRegrasCidade(content: any) { 
        this.todasRegrasCidade.grandes_mercados = content['grandes_mercados'][0];
        this.todasRegrasCidade.mercados_em_desenvolvimento = content['mercados_em_desenvolvimento'][0];
    }

    getStoryBlokRegrasGerais(): Observable<any> {
        if (this.cache$) {
            return this.cache$;
        }

        this.cache$ = this.getConfig('regras-gerais').pipe(
            // Armazena o resultado e compartilha com quem se inscrever
            shareReplay(1), // Garante que todas as assinaturas recebam o mesmo valor
            tap(() => {
                // Limpa o cache após o retorno (opcional, se quiser resetar o cache)
                this.cache$ = null;
            }),
            map((resp: any) => {
                if (resp.stories[0].content.affiliateId) {
                    this.setMobileAffiliateIds(resp.stories[0].content.affiliateId)
                    this.setRegrasGeraisData(resp);
                }

                if (resp.stories[0].content) {
                    this.setTodasRegrasCidade(resp.stories[0].content);
                    this.setUseDefaultPromotion(resp.stories[0].content);
                }

                return resp
            })
        )

        return this.cache$;
    }


    getGeneralContentVitrine() {
        const params = {
            starts_with: `${this.spaceDir}/vitrine`,
            token: environment.storyBlock.token,
            version: environment.storyBlock.version
        }

        const request$ = this.http.get(environment.storyBlock.api, { params });

        this.setGeneralContentVitrine(request$);

        return request$
    }

    setGeneralContentVitrine(generalContent: any) {
        generalContent.subscribe((data: any) => {
            this.generalContentVitrine = data;
        },
            (error) => {
            console.error(error);
        })
    }

    getBannersPromotionalClienteMovelInfo(technology: string, custumer_plan_category: string) {
      const params = {
        'filter_query[technology][any_in_array]': technology,
        'filter_query[custumer_plan_category][any_in_array]': custumer_plan_category,
        starts_with: 'residencial/prospect/cliente-claro-movel/promotional-banners/',
        token: environment.storyBlock.token,
        version: environment.storyBlock.version
      }

      return this.http.get(environment.storyBlock.api, { params })
    }

    getBannersPromotionalRedeMovelInfo() {
        const params = {
            "filter_query[offer_by_network][in]": true,
            starts_with: 'residencial/prospect/cliente-claro-movel/promotional-banners-rede-movel/',
            token: environment.storyBlock.token,
            version: environment.storyBlock.version
        }

        return this.http.get(environment.storyBlock.api, { params })
    }

    getListPromotionalPlans() {
      return this.http.get(`https://api.storyblok.com/v2/cdn/stories?starts_with=residencial/prospect/list-promotional-plans&token=${environment.storyBlock.token}&version=${environment.storyBlock.version}`)
    }

    getCroFormTestProductsMock(): Observable<any> {
        return this.http.get<any>(this.storyBlokCroFormTestURL);
    }

    setRegrasGeraisData(data: any): void {
        this.storyBlokData.next(data);
    }

    getRegrasGeraisData(): Observable<any> {
        return this.storyBlokData.asObservable();
    }

    setUseDefaultPromotion(content: any) {
        this.useDefaultPromotions = content.useDefaultPromotions;    
    }
    
    getUseDefaultPromotion() {
        return this.useDefaultPromotions;
    }

    setCheckoutConfig(data: any): void {
      this.storyBlokData.next(data);
    }
    getCheckoutConfig(): Observable<any> {
        return this.storyBlokData.asObservable();
    }

    getBlackFriday(): any{
        return this.isBlackFriday
    }

    setBlackFriday(isBlackFriday): void{
        this.isBlackFriday = isBlackFriday
    }

    getCityConfig(regionId: string): void {
        let modelCity = {
            attribute: 'id',
            operation: 'like',
            value: regionId
        };

        this.getGeneralContentVitrine();

        this.getConfig('cidades', modelCity).subscribe((data) => {
            this.setCity(data);
            this.nodes = this.city?.stories[0]?.content.promotionNodes || [];
            this.getGroupRulesConfig();
            this.storyBlokServiceReady$.next();
        },
            (error) => {
            console.error(error);
        })
    }

    getCity(): any {
        return this.city;
    }

    setCity(city: any): void {
        this.city = city;
    }

    getGroupRulesConfig(): void {
        const data = this.city;

        const modelGroup = {
            attribute: 'cidades',
            operation: 'any_in_array',
            value: data.stories[0]?.uuid
        };

        this.getConfig('regras-por-grupo', modelGroup).subscribe(
            (data: any) => {
                this.setGroupRules(data);
                this.storyBlokServiceReady$.next();
            },
                (error) => {
                console.error(error);
            }
        );
    }

    getGroupRules(): any {
        return this.groupRules;
    }

    setGroupRules(groupRules: any): void {
        this.groupRules = groupRules;
    }

    getNode(nodeId: string): any {
        if (this.nodes) {
            for (const node of this.nodes) {
                if (node.nodes.includes(nodeId)) {
                    this.nodeData = node;
                }
            }
            return this.nodeData;
        }
    }

    getMobileAffiliateIds() {
        return this.mobileAffiliateIds || []
    }

    setMobileAffiliateIds(affiliateIds) {
        this.mobileAffiliateIds = affiliateIds
    }

    getAppIds() {
        return this.appIds
    }

    setAppIds(ids: any, ) {        
        sessionStorage.setItem('appIds', ids);
        this.appIds = ids;
    }

    setTvIdGroup(tvIdGroup: any, ) {       
        sessionStorage.setItem('tvIdGroup', tvIdGroup);
        this.tvIdGroup = tvIdGroup;
    }
}
