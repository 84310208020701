import { Injectable, ComponentFactoryResolver, Injector, ApplicationRef, EmbeddedViewRef, Type, ComponentRef } from '@angular/core';
import { MdnModalConfig } from './mdn-modal-config';
import { MdnModalComponent } from './mdn-modal.component';
import { ModuloInjector } from './mdn-modal-injector';
import { MdnModalRef } from './mdn-modal-ref';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class MdnModalService {

  dialogComponentRef: ComponentRef<MdnModalComponent>;
  clearInput: Subject<string[]> = new Subject<string[]>();

    constructor(
        public componentFactoryResolver: ComponentFactoryResolver,
        public injector: Injector,
        public appRef: ApplicationRef,
    ) {}

    public open(componentType: Type<any>, config: MdnModalConfig): any {
        // verifica se já tem algum modal aberto com o mesmo componente filho, se tiver ele fecha o antigo
        if (this.dialogComponentRef) {
            // if (componentType.prototype.constructor.name === this.dialogComponentRef.instance.childComponentType.name) {
                this.removeDialogComponentFromBody();
            // }
        }
        /* Chama a funcão que injeta o Modal no corpo da página e cria as refrências dos componentes do modal e do componente que
         será injetado dentro do Modal. */
        const dialogRef = this.appendModalComponentToBody(config);

        // altera a variável childComponentType do componente do modal
        this.dialogComponentRef.instance.childComponentType = componentType;
        // cria um subscribe da função close do modal que quando envocado chama a função que destroi o componente
        this.dialogComponentRef.instance.onClose.subscribe(() => {
            this.removeDialogComponentFromBody();
        });

    }

    public appendModalComponentToBody(config: MdnModalConfig): any {

        // Cria um referência do compenente MdnModalComponent
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(MdnModalComponent);

        // insere o métogos de set, get, delet e has a const map;
        const map = new WeakMap();

        // insere na const map as referências do config que enviarão as informações para o componente filhos
        map.set(MdnModalConfig, config);

        // cria uma nova instancia do classe MdnModalRef
        const modalRef = new MdnModalRef();
        // insere na const map a nova instancia do MdnModalRef
        map.set(MdnModalRef, modalRef);

        // faz a assinatura com o modalref que vai escutar a chamada da função close de dentro do componente filho
        const sub = modalRef.afterClosed.subscribe(() => {
            this.removeDialogComponentFromBody();
            sub.unsubscribe();
        });

        /* Cria um component com base no componentFactory, envia o injector e o map (confif e mdnModalRef)
        para ele e armazena na variável componentRef*/
        const componentRef = componentFactory.create(new ModuloInjector(this.injector, map));

        // Vincula o template do component que está na variável componentRef com a aplicação dele
        this.appRef.attachView(componentRef.hostView);

        // Armazena a view do componentRef
        const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
        // injeta a view no corpo da página
        document.body.appendChild(domElem);

        this.dialogComponentRef = componentRef;
    }

    public removeDialogComponentFromBody(): void {
        this.appRef.detachView(this.dialogComponentRef.hostView);
        this.dialogComponentRef.destroy();
    }
}
