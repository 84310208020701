import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { UtilsService } from 'src/app/services/utils.service';
import { CroService } from '../../../services/cro.service';
import modalFn from 'mondrian-vanilla/core/components/Modal/modal.js';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, AfterViewInit {

  env: any = environment;
  isSafari: any;
  userAgent = navigator.userAgent;
  vendor = navigator.vendor;
  yearFooter = new Date();
  public gtmSocialMedia: string = ':social-media';
  public gtmCategory: string = 'planos-claro-res:rodape';
  public dataGtmEventAction: string = 'clique:link';
  public arraySocialLinks = [
    {
      name: 'facebook',
      href : 'https://www.facebook.com/clarobrasil',
      target : '_blank',
      title : 'Facebook Claro Brasil',
      rel : 'noopener',
    },
    {
      name : 'instagram',
      href : 'https://www.instagram.com/clarobrasil',
      target : '_blank',
      title : 'Instagram Claro Brasil',
      rel : 'noopener',
    },
    {
      name : 'twitter',
      href : 'https://twitter.com/clarobrasil',
      target : '_blank',
      title : 'Twitter Claro Brasil',
      rel : 'noopener',
    },
    {
      name : 'youtube',
      href : 'https://www.youtube.com/clarobrasil',
      target : '_blank',
      title : 'YouTube Claro Brasil',
      rel : 'noopener',
    },
    {
      name : 'linkedin',
      href : 'https://www.linkedin.com/company/clarobrasil',
      target : '_blank',
      title : 'Linkedin Claro Brasil',
      rel : 'noopener',
    }
  ];
  mdnModalSocialLinks = [];

  constructor(
    public router: Router,
    public utils: UtilsService,
    public cro: CroService
  ) { }

  ngOnInit(): void {
    this.isSafari = /Safari/.test(this.userAgent) && /Apple Computer/.test(this.vendor);
  }

  ngAfterViewInit() {
    this.arraySocialLinks.forEach(element => {
      if (element.target === '_blank') {
        this.mdnModalSocialLinks[element.name] = modalFn(document.getElementById('modal-target-blank-'+element.name));
      }
    });
  }

  showHandTalkAndOpenLibrasContainer(): void {
    const element = document.getElementsByClassName('ht-skip');
    if (element.length > 0) {
      element[0].classList.add('show');
      this.openLibrasContainer()
    }
  }

  openLibrasContainer():void {
    const accessibilityLibrasButton: HTMLElement = document.querySelectorAll('button[icon="accessibility-Libras"]')[0] as HTMLElement
    if(accessibilityLibrasButton){
      accessibilityLibrasButton.click()
    }
  }

  openMdnModalSocialLinks(socialLink?: any) {
    this.mdnModalSocialLinks[socialLink.name].open();
  }
}
