import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { environment } from '../../environments/environment';
import { map, shareReplay, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FingerprintService {

  private cache$: Observable<any> | null = null;

  constructor(
    public http: HttpClient
  ) { }

  getFingerprint(): Observable<any> {
    const fingerprintStorage = this.getFingerprintStorage();

    if (!!fingerprintStorage) {
      return of(
        fingerprintStorage
      )
    }
    
    // Se já existe uma chamada pendente ou já concluída, retorna o cache
    if (this.cache$) {
      return this.cache$;
    }

    // Se não houver cache, faz a chamada HTTP
    this.cache$ = this.http.get(environment.coberturaFingerprint).pipe(
      // Armazena o resultado e compartilha com quem se inscrever
      shareReplay(1), // Garante que todas as assinaturas recebam o mesmo valor
      tap(() => {
        // Limpa o cache após o retorno (opcional, se quiser resetar o cache)
        this.cache$ = null;
      }),
      map((x: any) => {
        const fingerprint_ = x.data?.fingerprint;
        sessionStorage.setItem('fingerprint', fingerprint_)
        return fingerprint_
      })
    );

    return this.cache$;
  }

  getFingerprintStorage(): string {
    return sessionStorage.getItem('fingerprint');
  }
}
