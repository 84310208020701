import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PersonalData } from '../models/personal-data';
import { UtilsService } from 'src/app/services/utils.service';
import { LeadService } from '../../../services/lead.service';
import { AssineJaParserService } from '../../../services/assine-ja-parser.service';
import { OrderService } from './order.service';
import { Payment } from '../models/payment';
import { Address } from '../models/address';
import { CroService } from '../../../services/cro.service';
import { EnderecoService } from './endereco.service';
import { CarrinhoService } from 'src/app/services/carrinho.service';
import { CommonNavigationService } from 'src/app/services/navigation.service';
import { LoadingService } from 'src/app/shared/loading/loading.service';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';
import { BucketS3Service } from 'src/app/services/bucket-s3.service';
import { StoryBlockService } from 'src/app/services/storyblock.service';
import { FingerprintService } from 'src/app/services/fingerprint.service';

@Injectable({
  providedIn: 'root',
})
export class CheckoutService {
  public parserInstance: any;
  public status: string;
  public route: any;
  public cupom: any;

  public cupomDesconto$: Subject<any> = new Subject<any>();
  public showReguaTransacionalOfertaPlanoMovel$: Subject<boolean> =
    new Subject<boolean>();
  public tokenCodeError$: Subject<any> =
    new Subject<any>();
  public useTotp: boolean = 
    sessionStorage.getItem('useTotp') === 'true';

  private pagamento: Payment = new Payment();
  private dadosPessoais: PersonalData = new PersonalData();
  private checkoutConfig: any;

  private paymentDays = [5, 8, 10, 15, 20];

  private daysWeek = ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sab'];

  private states = [
    'AC',
    'AL',
    'AP',
    'AM',
    'BA',
    'CE',
    'DF',
    'ES',
    'GO',
    'MA',
    'MT',
    'MS',
    'MG',
    'PA',
    'PB',
    'PR',
    'PE',
    'PI',
    'RJ',
    'RN',
    'RS',
    'RO',
    'RR',
    'SC',
    'SP',
    'SE',
    'TO',
  ];

  private types = [
    'Alameda',
    'Avenida',
    'Estrada',
    'Parque',
    'Praça',
    'Rodovia',
    'Rua',
    'Vila',
    'Aeroporto',
    'Área',
    'Campo',
    'Chácara',
    'Colônia',
    'Condomínio',
    'Conjunto',
    'Distrito',
    'Esplanada',
    'Estação',
    'Favela',
    'Feira',
    'Jardim',
    'Ladeira',
    'Lago',
    'Lagoa',
    'Largo',
    'Loteamento',
    'Morro',
    'Núcleo',
    'Passarela',
    'Pátio',
    'Quadra',
    'Recanto',
    'Residencial',
    'Setor',
    'Sítio',
    'Travessa',
    'Trecho',
    'Trevo',
    'Vale',
    'Vereda',
    'Via',
    'Viaduto',
    'Viela',
  ];

  public ottProducts: any;

  constructor(
    private utils: UtilsService,
    private lead: LeadService,
    private parserService: AssineJaParserService,
    private order: OrderService,
    private cro: CroService,
    private navigationService: CommonNavigationService,
    private loader: LoadingService,
    private enderecoService: EnderecoService,
    private cart: CarrinhoService,
    private http: HttpClient,
    public bucketS3Service: BucketS3Service,
    public router: Router,
    public storyBlockService: StoryBlockService,
    public fingerprintService: FingerprintService    
  ) {
    this.route = { actualRoute: 'dados-pessoais' };
  }

  getPaymentDays() {
    return this.paymentDays;
  }

  hasMobilePlanAndIsOnlyForLegalPerson(cartSelection?: any): boolean {
    if (cartSelection) {
      return cartSelection.celular && cartSelection.celular.somentePJ === 1;
    }
    if (
      this.parserService &&
      this.parserService.parser &&
      this.parserService.parser.cart &&
      this.parserService.parser.cart.selection
    ) {
      const cart = this.parserService.parser.cart.selection;
      return cart.celular && cart.celular.somentePJ === 1;
    }
    return false;
  }

  hasPersonalizacaoMovelStep(): boolean {
    if (
      this.parserService &&
      this.parserService.parser &&
      this.parserService.parser.cart &&
      this.parserService.parser.cart.selection
    ) {
      const cart = this.parserService.parser.cart.selection;
      return !!cart.celular
    }
    return false;
  }

  getDaysWeek() {
    return this.daysWeek;
  }

  getStates() {
    return this.states;
  }

  getTypes() {
    return this.types;
  }

  getDadosPessoais(): PersonalData {
    return this.dadosPessoais;
  }

  setDadosPessoais(dadosPessoais: PersonalData) {
    this.dadosPessoais = dadosPessoais;
  }

  clearDadosPessoais() {
    const cleanDadosPessoais: PersonalData = new PersonalData()
    this.setDadosPessoais(cleanDadosPessoais)
  }

  getEndereco() {
    return this.enderecoService.getEndereco();
  }

  setEndereco(endereco: Address) {
    this.enderecoService.setEndereco(endereco);
  }

  clearEndereco() {
    const cleanEndereco: Address = new Address()
    this.setEndereco(cleanEndereco);
  }

  getPagamento() {
    return this.pagamento;
  }

  setPagamento(pagamento: Payment) {
    this.pagamento = pagamento;
  }

  clearPagamento() {
    const cleanPagamento: Payment = new Payment()
    this.setPagamento(cleanPagamento)
  }

  getOttProducts(): any {
    return this.ottProducts;
  }

  setOttProducts(): void {
    this.bucketS3Service.getOttProducts().subscribe(
      (results) => {
        this.ottProducts = results;
      },
      (error) => {
        console.error(error);
      }
    );
  }

  setRoute(route: string) {
    this.route.actualRoute = route;
  }

  getCheckoutConfig(): any {
    return this.checkoutConfig;
  }

  setCheckoutConfig(checkoutConfig: any) {
    this.checkoutConfig = checkoutConfig;
  }

  getStoryblokCheckoutRoles(): Promise<any>{
    return new Promise((resolve, reject)=>{
      if(this.checkoutConfig){
        resolve(this.checkoutConfig)
      }else{
        this.storyBlockService.getConfig('checkout').subscribe(
          (data: any) => {
            this.setCheckoutConfig(data?.stories[0].content)
            resolve(data?.stories[0].content)
          },
          (error) => {
            console.error(error);
            reject(error);
          }
        );
      }
    })
    
  }

  async submitCheckout(dataForm): Promise<void> {
    const that = this;
    let dest;

    dataForm.parser = this.parserInstance;

    if (dataForm.origin === 'dados-pessoais') {
      this.utils.storage.setItem('step', '2');
      dest = 'endereco';
    } else if (dataForm.origin === 'endereco') {
      this.utils.storage.setItem('step', '3');
      dest = 'pagamento';
    } else if (dataForm.origin === 'pagamento') {
      dest = 'confirmacao';
    }

    this.utils.storage.setItem('dest', dest);

    if (
      dataForm.origin === 'dados-pessoais' ||
      dataForm.origin === 'endereco'
    ) {
      that.status = dest;

      const isSingleMovel = this.cart.resolveIsSingleMovel();
      if (isSingleMovel) {
        const inCartCelularId = this.cart.getCelularIdFromCart();
        const urlJornadaMovel =
          this.cart.getJornadaMovelUrlById(inCartCelularId);
        !!urlJornadaMovel
          ? this.cart.navigateTo(urlJornadaMovel)
          : this.navigationService.sendToNext();
      } else {
        this.navigationService.sendToNext();
      }
    } else if (dataForm.origin === 'pagamento') {
      this.loader.show({ type: 'default' });
      let dataLead = this.lead.get();
      if(!!dataForm.tokenCode && dataForm.tokenCode !== "" && !!this.useTotp){
        dataLead.TOTP = dataForm.tokenCode
      }
      this.fingerprintService.getFingerprint().subscribe(
        (fingerprint_: string) => {
          this.order
          .send(dataLead, fingerprint_)
          .then((dataOrder: any) => {
            this.cro.monitoramentoAPI(
              'planos-res:checkout-confirmacao',
              'sucesso:order',
              'sucesso:order'
            );
            this.utils.storage.setItem('orderId', dataOrder.data.data.id);
            this.status = dest;
            this.loader.hide();
            this.croCompra();
            this.navigationService.sendToNext();
          })
          .catch((http) => {
            // TODO: aplicar um modal falando ERRO
            this.status = 'pagamento';
            if (http.httpCode === '409') {
              this.cro.monitoramentoAPI(
                'planos-res:checkout-confirmacao',
                'erro:order',
                'erro409:duplicado'
              );
              this.order.duplicateOrder = true;
              this.navigationService.sendToNext();
            } else if (http.httpCode === '401') {
              const specificErrorCode = http?.error?.code.slice(-3)
              if(specificErrorCode === '001'){
                this.cro.monitoramentoAPI(
                  'planos-res:checkout-confirmacao',
                  'erro:order',
                  'erro401:totp-obrigatorio'
                );
                this.tokenCodeError$.next({ code_does_not_match: true }); 
              } else if(specificErrorCode === '002'){
                this.cro.monitoramentoAPI(
                  'planos-res:checkout-confirmacao',
                  'erro:order',
                  'erro401:totp-invalido'
                );
                this.tokenCodeError$.next({ code_does_not_match: true }); 
              }  else if(specificErrorCode === '003'){
                this.cro.monitoramentoAPI(
                  'planos-res:checkout-confirmacao',
                  'erro:order',
                  'erro401:pedido-nao-pode-ser-processado'
                );
                this.tokenCodeError$.next({ order_cannot_be_processed: true }); 
              }           
              this.loader.hide();
            } else {
              this.cro.monitoramentoAPI(
                'planos-res:checkout-confirmacao',
                'erro:order',
                'erro500'
              );
              this.loader.hide();
              this.router.navigate(['/500']);
            }
          });
        }
      )
    }
  }

  sendLead(dataForm): void {
    dataForm.billingNumber = dataForm.billingNumber
      ? dataForm.billingNumber.replace(/\D/g, '')
      : '';

    this.fingerprintService.getFingerprint().subscribe(
        (fingerprint_: string) => {
          this.lead.send(dataForm, fingerprint_);
        },
        (error => {
          console.error(error)
        })
    )
  }

  checkoutCro(dir, step): void {
    const { selection } = this.parserService.parser.cart;

    //if nothing is selected returns
    if (!selection) return;

    const products = selection;
    const _vm = this,
      croObj: any = [];

    let path: any = {};
    let txt = '';

    path = {
      eventAction: dir,
      actionField: step,
    };

    for (const key in products) {
      if (products.hasOwnProperty(key)) {
        croObj.push({
          name: this.utils.convertToSanitizer(products[key].nome),
          id: products[key].id,
          price:
            products[key].preco.currency().integer() +
            '.' +
            products[key].preco.currency().cents(),
          brand: key === 'celular' ? 'claro-movel' : _vm.utils.getBrandTag(),
          category: 'planos/' + key,
          quantity: '1',
        });

        const adicionais = products[key].selecionados;
        if (adicionais) {
          for (const adicional in adicionais) {
            if (adicionais.hasOwnProperty(adicional)) {
              croObj.push({
                name: this.utils.convertToSanitizer(adicionais[adicional].nome),
                id: adicionais[adicional].id,
                price:
                  adicionais[adicional].preco.currency().integer() +
                  '.' +
                  adicionais[adicional].preco.currency().cents(),
                brand:
                  key === 'celular' ? 'claro-movel' : _vm.utils.getBrandTag(),
                category: 'adicional/' + key,
                quantity: '1',
              });
            }
          }
        }
      }
    }

    croObj.forEach((product) => {
      txt = txt === '' ? product.name : txt + ' + ' + product.name;
    });
    this.cro.Checkout(croObj, path, txt);
  }

  getCatalog(): string {
    return this.lead.catalog;
  }

  getSelectedProducts() {
    const products = [];
    for (const key in this.lead.leadProducts) {
      if (this.lead.leadProducts.hasOwnProperty(key)) {
        products.push(this.lead.leadProducts[key].type);
      }
    }
    return products;
  }

  getCouponDiscont(cupomDesconto) {
    const urlCupom = `${environment.apiNewPath}coupons/${cupomDesconto}`;

    this.fingerprintService.getFingerprint().subscribe(
      (fingerprint_: string) => {

        const httpOptions = {
          headers: new HttpHeaders({
            'x-fingerprint': fingerprint_
          }),
        };

        this.http.get(urlCupom, httpOptions).subscribe(
          (data: any) => {
            this.cupom = data;
            const lengthCart = Object.keys(
              this.parserService.parser.cart.selection
            ).length;
            if (lengthCart === 1) {
              this.parserService.parser.data.calculateDiscount(
                this.parserService.parser.cart.selection,
                this.cupom
              );
            }
            this.cart.carrinhoReload$.next(true);
            this.cupomDesconto$.next(true);
          },
          (error) => {
            this.cupom = error;
            this.parserService.parser.data.calculateDiscount(
              this.parserService.parser.cart.selection
            );
    
            this.cupomDesconto$.next(true);
          }
        );
      },
      (error => {
        console.error(error)
      })
    )
  }

  public croCompra(): void {
    const objCroArray: Array<any> = [];

    const { selecoes, ...products } = this.parserService.parser.cart.selection;
    const orderId = this.utils.storage.getItem('orderId');
    let text = '';
    const _vm = this;

    const valorTotal = this.utils.formataDinheiroCro(
      (
        this.parserService.parser.cart.getMontly() +
        this.parserService.parser.cart.getInstallation() +
        this.parserService.parser.cart.getSignup()
      ).toString()
    );

    const valorTaxas = this.utils.formataDinheiroCro(
      (
        this.parserService.parser.cart.getInstallation() +
        this.parserService.parser.cart.getSignup()
      ).toString()
    );

    for (const key in products) {
      if (products.hasOwnProperty(key)) {
        const croObjeto = {
          name: products[key].nome,
          id: products[key].id,
          price: this.utils.formataDinheiroCro(products[key].preco.toString()),
          brand: _vm.utils.getBrandTag(),
          category: 'planos/' + key,
          quantity: '1',
        };

        objCroArray.push(croObjeto);

        const adicionais = products[key].selecionados;
        if (adicionais) {
          for (const adicional in adicionais) {
            if (adicionais.hasOwnProperty(adicional)) {
              objCroArray.push({
                name: adicionais[adicional].nome.replace(/(\()|(\))/g, ''),
                id: adicionais[adicional].id,
                price:
                  adicionais[adicional].preco.currency().integer() +
                  '.' +
                  adicionais[adicional].preco.currency().cents(),
                brand: _vm.utils.getBrandTag(),
                category: 'adicional/' + key,
                quantity: '1',
              });
            }
          }
        }
      }
    }

    objCroArray.forEach((product) => {
      text = text === '' ? product.name : text + ' + ' + product.name;
    });

    this.cro.Purchase(orderId, objCroArray, valorTotal, valorTaxas, text);
  }

  limparDadosVoltarVitrine(): void {
    this.clearDadosPessoais();
    this.clearPagamento();
    this.clearEndereco();
    this.cart.limparCarrinho();
    sessionStorage.removeItem('leadId');
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('orderCannotBeProcessed');   
    this.lead.clearDados() 
    this.limparDadosClienteClaroMovel()
  }

  limparDadosClienteClaroMovel(): void {
    // Storage referente a Identificação do Cliente Claro Móvel
    this.utils.storage.removeItem('sms-ecare-token');
    this.utils.storage.removeItem('cliente-claro-movel');
    this.utils.storage.removeItem('customerName');
    this.utils.storage.removeItem('claro-movel-promo-applied');
    this.utils.storage.removeItem('cliente-movel-de-para-mobile-plan');
    this.utils.storage.removeItem('customer-mobile-info-api-return');
    this.utils.storage.removeItem('ModalConfiraOQueGanhouShowed');
    this.utils.storage.removeItem('user');
    ///////////////////////////////////////////////////////////////////
  }
}
