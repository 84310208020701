import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JwtService } from '../../../services/jwt.service';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  public duplicateOrder = false;

  constructor(
    private http: HttpClient,
    private jwtService: JwtService
  ) { }

  httpOptions = {
    headers: new HttpHeaders({
      'x-channel': 'ecommerce',
      'x-segment': 'pf',
      'x-partner': '',
      'x-customer-journey': 'prospect'
    }),
  };

  async createOrder(dataOrder: any, fingerprint: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'x-fingerprint': fingerprint
      }),
    };
    
    return this.http.post(environment.coberturaOrders, dataOrder, httpOptions).toPromise();
  }

  filterDataOrders(dataOrder){
    const mgmId = sessionStorage.getItem('mgmId')
    if(mgmId !== '' && mgmId !== null){
      dataOrder.mgmId = mgmId
    }
    return dataOrder
  }

  addTestAttribute(dataOrder: any) {
    if (dataOrder.user) {
      const { name, email } = dataOrder.user;
      const testRegex = /(test)/i;
      const isTest = !!(name.match(testRegex) || email.match(testRegex));

      return {
        ...dataOrder,
        isTest
      }
    }
    
    return {
      ...dataOrder,
      isTest: false
    }
  }

  async send(dataOrder: any, fingerprint: string) {
    const manipulatedDataOrder = await this.manipulateDataOrder(dataOrder)
    return new Promise((resolve, reject) => {
      this.createOrder(manipulatedDataOrder, fingerprint)
        .then((resOrder: any) => { // Success
          window.sessionStorage.removeItem('leadId');
          return resolve(resOrder);
        })
        .catch((err) => { // Error
          const errOrder = err.error
          const { error: errorOrder } = errOrder.json && typeof errOrder.json === 'function' ? errOrder.json() : errOrder;
          window.sessionStorage.removeItem('leadId');
          return reject(errorOrder);
        });
    });
  }

  getCID() {
    const cid = (function(){
      try{
          const ga = (window as any).ga;
          const trackers = ga.getAll();
          for (let i = 0; i <= trackers.length; i++){
            if (trackers[i] && trackers[i].get('trackingId') === environment.googleTrackingId){
              return trackers[i].get('clientId');
              i = trackers.length;
            }
          }
       }catch (e){}
    })();
    return cid;
  }

  async manipulateDataOrder(dataOrder: any): Promise<any> {
    let updatedDataOrder = this.addTestAttribute(dataOrder);
    this.duplicateOrder = false;
    const descriptor = Object.getOwnPropertyDescriptor(
      Object(updatedDataOrder), 'user'
    );
    if (descriptor) {
      Object.defineProperty(updatedDataOrder, 'customer', descriptor);
    }
    delete updatedDataOrder['user'];
    updatedDataOrder.googleClientId = this.getCID();
    delete updatedDataOrder.id
    updatedDataOrder = this.filterDataOrders(updatedDataOrder);
    const dataOrderJwt = await this.jwtService.objectToSignedJwt(updatedDataOrder)
    const payload = {
      token: dataOrderJwt
    }
    return payload;
  }
}
