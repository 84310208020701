import {
  Component, OnInit,
} from '@angular/core';
import { Subject } from 'rxjs';
// import { setTimeout } from 'timers';
import { AlertConfig } from './alert-config';
import { AlertRef } from './alert-ref';

export interface Settings {
  type: string;
  theme: string;
  text: string;
  showIcon: boolean;
  linklabel: string;
  link: string;
  action: string;
  actionText: string;
  time: number;
}

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
  public onClose: Subject<number> = new Subject<number>();

  id: number;
  settings: Settings;
  isClosing: boolean = false;

  defaultType: string = 'mdn-Alert--success';
  defaultTheme: string = 'mdn-Alert--light';
  defaultTime: number = 3000;
  typeList: [string, string][] = [
    ['success', 'mdn-Alert--success'],
    ['danger', 'mdn-Alert--danger']
  ];
  themeList: [string, string][] = [
    ['light', 'mdn-Alert--light'],
    ['dark', 'mdn-Alert--dark']
  ];

  constructor(
    public config: AlertConfig,
    public ref: AlertRef
  ) { }

  ngOnInit(): void {
    this.id = this.config.id;

    const settings = this.config.data;
    this.settings = {
      type: this.setType(settings.type),
      theme: this.setTheme(settings.theme),
      text: settings.text || '',
      showIcon: settings.showIcon || false,
      linklabel: settings.linklabel || undefined,
      link: settings.link || undefined,
      action: settings.action || undefined,
      actionText: settings.actionText || undefined,
      time: settings.time || Number(settings.time) || this.defaultTime
    };

    setTimeout(() => {
      if (!this.isClosing) {
        this.close();
      }
    }, this.settings.time);
  }

  setType(type?: string): string {
    let index = -1;
    if (type) {
      index = this.typeList.findIndex(typeItem => typeItem[0] === type);
    }
    return index === -1 ? this.defaultType : this.typeList[index][1];
  }

  setTheme(theme?: string): string {
    let index = -1;
    if (theme) {
      index = this.themeList.findIndex(themeItem => themeItem[0] === theme);
    }
    return index === -1 ? this.defaultTheme : this.themeList[index][1];
  }

  close(): void {
    this.isClosing = true;
    setTimeout(() => {
      this.onClose.next(this.id);
    }, 300);
  }

  openAdicionais(): void {
    console.log('abrir adicionais');
  }
}
