import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class DependentesService {

  public cellphoneModalIsClosed$: Subject<any> = new Subject<any>();

  cellphoneModalIsClosedSubscription: Subscription;

  constructor(
    public utils: UtilsService
  ) {
    if (this.utils.storageGetItem('cookieCelularDependentes') !== '') {
      const { dependentsLine, termAccepted } = JSON.parse(this.utils.storageGetItem('cookieCelularDependentes'));
      const quantidade = [];

      if (!!dependentsLine && dependentsLine !== 'null' && !!termAccepted && termAccepted !== 'null') {
        dependentsLine.forEach(dependente => {
          quantidade.push(dependente.id);
        });

        this.sendDependente(dependentsLine);
        this.sendTermo(termAccepted);
        this.sendQuantidade(quantidade);
      }
    }
  }

  private dependenteConfigurationService$ = new BehaviorSubject<any>(null);
  private quantidadeAdicional$ = new BehaviorSubject<any>(null);
  private termoChecked$ = new BehaviorSubject<any>(null);
  private pacote$ = new BehaviorSubject<any>(null);

  castDependente = this.dependenteConfigurationService$.asObservable();
  castQuantidade = this.quantidadeAdicional$.asObservable();
  castTermoChecked = this.termoChecked$.asObservable();
  castPacote = this.pacote$.asObservable();

  private productDependents: any = {};

  sendDependente(dependente) {
    this.productDependents.dependentsLine = dependente;
    this.dependenteConfigurationService$.next(dependente);
  }
  sendQuantidade(quantidade) {
    this.quantidadeAdicional$.next(quantidade);
  }

  sendTermo(termo) {
    this.productDependents.termAccepted = termo;
    this.termoChecked$.next(termo);
  }

  sendPacote(pacote) {
    this.pacote$.next(pacote);
  }

  getProductDependents(): any {
    return this.productDependents;
  }

}

