import { Observable, Subject } from 'rxjs';

export class AlertRef {
    public readonly _afterClosed = new Subject<any>();

    afterClosed: Observable<any> = this._afterClosed.asObservable();

    close(result?: any): void {;
        this._afterClosed.next(result);
    }
}
