<div class="mdn-Row" *ngIf="id !== 'disclaimer-entenda-fatura'">
    <div class="mdn-Col-xs-12 texto-legal">
        <h2 class="mdn-Heading mdn-Heading--xs">
            Residencial
        </h2>
        <p class="mdn-Text mdn-Text--body">
            Ofertas válidas exclusivamente nas contratações através do site para pessoa física, sujeitas à
            análise de crédito e disponibilidade técnica dos serviços no endereço solicitado.
        </p>
    </div>
</div>

<div class="mdn-Row">
    <div class="mdn-Col-xs-12">
        <div id="{{id}}" class="lista-Accordion" *ngIf="list.length > 0">
            <div class="mdn-Accordion" [attr.data-component]="'accordion'">
                <div class="mdn-Accordion-box" *ngFor="let item of list">
                    <a class="mdn-Accordion-toggle mdn-Text mdn-Text--body" role="button" (click)="tagAccordion($event, item.title)">
                        {{item.title}}
                        <em class="mdn-Accordion-icon mdn-Icon-baixo mdn-Icon--lg"></em>
                    </a>
                    <div class="mdn-Accordion-content">
                        <p class="mdn-Text mdn-Text--body" [innerHTML]="item.text | safeHtml"></p>
                        <em></em>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>