<div>
  <div
    class="mdn-Modal mdn-Modal--{{ size }} {{
      size === 'xl' ? 'mdn-Modal--lg mdn-Modal--xl' : ''
    }} mdn-isOpen"
    (click)="onOverlayClicked($event)"
  >
    <div
      class="mdn-Modal-content"
      (click)="onDialogClicked($event)"
      [class.modal-banner]="type === 'banner'"
      [class.isModalNow]="type === 'now'"
      [class.modal-Abandono]="type === 'abandono' || type === 'lineup'"
      [class.modal-Line-Up]="type === 'lineup'"
      [class.modal-identificacao-movel-component]="type === 'IdentificacaoMovelComponent'"
      [class.banner-promocional-component]="type === 'bannerPromocionalComponent'"
      [class.modal-Venda-Assistida]="type === 'modalVendaAssistida'"
      [class.modal-ja-e-cliente]="type === 'ja-e-cliente'"
      [class.modal-ops-nao-deu-certo]="type === 'ops-nao-deu-certo'"
      [class.modal-pedido-nao-processado]="type === 'pedido-nao-processado'"
      [class.modal-fone]="titulo === 'Portabilidade fixo'"
      [class.modal-loss-offer]="type === 'loss-offer'"
      [class.bannerPromocionalNode]="type === 'bannerPromocionalNode'"
      [class.modal-digital-banks]="type === 'modal-digital-banks'"
      [class.modal-order-status]="type === 'order-status'"
      [class.modal-address-complement]="type === 'modal-address-complement'"
      [class.modal-dth]="type === 'modal-dth'"
      [class.modal-combo-indisponivel]="type === 'combo-indisponivel'"
    >
      <button
        class="mdn-Button-header-close mdn-Button mdn-Button--icon mdn-Button--secondary button-Abandono"
        *ngIf="type === 'abandono' || type === 'lineup'"
        (click)="close()"
        [attr.data-gtm-event-category]="'planos-claro-res:' + nomeGtm"
        data-gtm-event-action="clique:botao"
        data-gtm-event-label="fechar-modal"
      >
        <em class="mdn-Icon-fechar mdn-Icon--md"></em>
      </button>
      <ng-template appModalHost></ng-template>
    </div>
  </div>
</div>
