<div class="mdn-Container">
  <div class="mdn-Row">
      <div class="mdn-Col-lg-12 no-padding">
          <div id="swiper-banner" class="mdn-Swiper mdn-Swiper--banner"
              [attr.data-mdn-slider-delay]="swiperBannerConfig.config.delay"
              [attr.data-mdn-auto-play]="swiperBannerConfig.config.autoPlay"
          >
              <div class="mdn-Swiper-wrapper">
                  <div class="mdn-Banner mdn-Banner--slide mdn-Swiper-slide mdn-Banner--left mdn-Banner--transparent"
                    *ngFor="let banner of swiperBannerConfig.banners; index as i"
                  >
                      <div class="mdn-Banner-wrapper" (click)="emitOnClickBanner(i)">
                        <img src={{banner}} class="banner-img" alt="Banner Promocional {{i+1}}">
                      </div>
                  </div>
              </div>
              <div class="mdn-Swiper-pagination-banner">
                <div class="mdn-Swiper-button-prev">
                    <i class="mdn-Icon-esquerda mdn-Icon--lg"></i>
                </div>
                <div class="mdn-Swiper-button-next">
                    <i class="mdn-Icon-direita mdn-Icon--lg"></i>
                </div>
              </div>
              <div class="mdn-Swiper-pagination"></div>
          </div>
      </div>
  </div>
</div>
