import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoadingService } from './loading.service';
import { Loader } from './Loader';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit, OnDestroy {
  showLoaderSub: Subscription;
  stopLoaderSub: Subscription;

  isLoaderActive: boolean;

  loadingType: "default" | "modal-loading";
  loadingTitle: string;
  loadingDescription: string;

  constructor(
    public LoadingService: LoadingService,
  ) { }

  ngOnInit(): void {
    this.subscribeShowLoaderEvent((loader: Loader) => {
      this.displayLoaderAs(loader.type);
      this.setTitle(loader.title);
      this.setDescription(loader.description);
    });

    this.subscribeHideLoaderEvent(() => this.hideLoader());
  }

  ngOnDestroy(): void {
    this.unsubscribeAll();
  }

  subscribeShowLoaderEvent(callback: Function): void {
    this.showLoaderSub = this.LoadingService.showLoader.subscribe(
      (loader: Loader) => callback(loader)
    );
  }

  subscribeHideLoaderEvent(callback: Function): void {
    this.stopLoaderSub = this.LoadingService.hideLoader.subscribe(
      () => callback()
    );
  }

  unsubscribeAll(): void {
    this.showLoaderSub.unsubscribe();
    this.stopLoaderSub.unsubscribe();
  }

  displayLoaderAs(type: "default" | "modal-loading"): void {
    this.loadingType = type;
    this.isLoaderActive = true;
  }

  hideLoader(): void {
    this.isLoaderActive = false;
  }

  setTitle(title: string): void {
    this.loadingTitle = title;
  }

  setDescription(description: string): void {
    this.loadingDescription = description;
  }
}
