import { Component, OnInit } from '@angular/core';
import { MdnModalConfig } from '../../mondrian-components/mdn-modal/mdn-modal-config';
import { Router } from '@angular/router';

import { MdnModalRef } from '../../mondrian-components/mdn-modal/mdn-modal-ref';

import { storageNamesEnum } from './service/mobile-client-validation/mobile-client-validation.service'

import { MobileClientValidationService } from './service/mobile-client-validation/mobile-client-validation.service';
import { SmsEcareService } from './service/apis/sms-ecare/sms-ecare.service';
import { LoadingService } from '../../loading/loading.service';
import { SanitizeService } from './service/sanitize/sanitize.service';
import { CroService } from 'src/app/services/cro.service';
import { CarrinhoService } from 'src/app/services/carrinho.service';
import { PushService } from './flows/push.service';
import { OriginIdService } from './flows/origin-id.service'
import { FingerprintService } from 'src/app/services/fingerprint.service';


enum stepsNames {
  SEND_CODE = 'send-code',
  MOBILE_CLIENT_VALIDATION = 'mobile-client-validation',
  NOT_MOBILE_CUSTOMER = 'not-mobile-customer',
  API_ERROR = 'api-error'
}

@Component({
  selector: 'app-mobile-client-authenticator',
  templateUrl: './mobile-client-authenticator.component.html',
  styleUrls: ['./mobile-client-authenticator.component.css']
})
export class MobileClientAuthenticatorComponent implements OnInit {

  step: string
  flow: string

  constructor(
    private router: Router,
    private config: MdnModalConfig,
    private dialog: MdnModalRef,
    private service: MobileClientValidationService,
    private carrinho: CarrinhoService,
    private cro: CroService,
    private loader: LoadingService,
    private smsEcareService: SmsEcareService,
    private sanitizeService: SanitizeService,
    private pushService: PushService,
    private originIdService: OriginIdService,
    public fingerprintService: FingerprintService
  ) { }

  ngOnInit(): void {
    this.cro.openModal(`cliente-claro-movel-beneficios`);
    
    this.service.changeStep$.subscribe((step_: string) => {
      this.step = step_;
    });

    this.service.closeDialog$.subscribe(() => this.closeModal())

    this.flow = this.config.data?.flow
    this.service.setFlow(this.flow);

    if (this.flow === 'push' || 'originId') {
      this.step = stepsNames.SEND_CODE;
    }

    if (this.flow === 'originId') {
      this.dialog._afterClosed.subscribe(() => {
        if (
          this.flow === 'originId' &&
          this.service.getVerifiedMobileCustomerByOriginid() !== true
        ) {
          this.service.setVerifiedMobileCustomerByOriginid(false);
          this.service.deleteSmsEcareToken();
          this.service.deleteModalConfiraOQueGanhouShowed();
          this.service.deleteUpgradeDePara();
          this.service.deleteCustomerPlanData();
          this.service.deleteMsisdn();
          this.service.deleteCpf();
          sessionStorage.removeItem('claro-movel-cep-sem-numero');
          sessionStorage.removeItem('claro-movel-number');
          sessionStorage.removeItem('cliente-claro-movel');
          sessionStorage.removeItem('customerName');
          sessionStorage.removeItem('cookiePortabilityCelular');
          sessionStorage.removeItem('claro-movel-promo-applied');

          this.carrinho.limparCarrinho();
        }
      })
    }
  }

  changeStep(step_: string) {
    this.step = step_
  }

  returnSendCode(value: {cpf: string, msisdn: string}) {
    const cpf    = this.sanitizeService.sanitizeCpf(value.cpf);
    const msisdn = this.sanitizeService.sanitizePhone(value.msisdn);

    this.service.setCpf(cpf);
    this.service.setMsisdn(msisdn);
    sessionStorage.setItem(storageNamesEnum.CPF, cpf);
    sessionStorage.setItem(storageNamesEnum.MSISDN, msisdn);

    if (this.flow === 'push' || 'originId') {
      this.loader.show({ type: "default" });

      this.smsEcareService.sendSmsAndGetToken(msisdn).subscribe(
        resp => {
          const token_ = resp.data.token;
          this.service.setToken(token_);
          this.loader.hide();
          this.step = stepsNames.MOBILE_CLIENT_VALIDATION;
        },
        error => {
          console.error(error);
          this.loader.hide();
          this.showPage500();
        }
      );
    }
  }

  returnMobileClientValidation(code_: string) {
    this.service.setCode(code_);

    if (this.flow === 'push') {
      this.pushService.flowPush()
    }

    if (this.flow === 'originId') {
      this.originIdService.flowOriginId()
    }
  }

  returnNotMobileCustumer() {
    this.step = stepsNames.SEND_CODE;
  }

  closeModal() {
    this.dialog.close();
  }

  closeModalWithTag(): void {
    this.cro.clickButtonModal(this.service.croOrigin, 'fechar');
    this.dialog.close();
    this.loader.hide();
  }

  showPage500() {
    this.dialog.close();
    this.router.navigate(['/500']);
  }

}
