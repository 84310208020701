import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { UtilsService } from './utils.service';
import { delay, finalize, map, tap } from 'rxjs/operators';
import jwtDecode from "jwt-decode";

interface TechnologyI {
  gpon: boolean
  internet: boolean
  name: string
  phone: boolean
  tv: boolean
}
@Injectable({
  providedIn: 'root'
})
export class ViabilityService {

  constructor(
    public http: HttpClient,
    public utils: UtilsService
  ) { }

  public isNewViability: boolean;
  public addressTechnology = '';

  getNewViability(cep: string, number: string, city: string, uf: string, fingerprint_: string): Observable<any> {
    let useAddress = environment.production ?
      this.utils.storageGetItem('useAddress')==='true' :
      this.utils.storageGetItem('useAddressTestEnvironment')==='true';
      useAddress = false;
    // Remover quando a API address for realmente validada e deixar o fluxo 100% para apenas 1 api
    let urlNewViability = useAddress ? environment.apiAddress : environment.coberturaViability 
    let notIsCityAndUf = true;

    if (useAddress) {
      if(cep !== '' && number === 'SN'){
        urlNewViability = `${urlNewViability}viability/${cep}`;
      }
      else if (cep !== '' && number !== '') {
        urlNewViability = `${urlNewViability}viability/${cep}/${number}`;
      } else if (city !== '' && uf !== '') {
        urlNewViability = `${urlNewViability}viability/city?city=${city}&state=${uf}`;
        notIsCityAndUf = false;
      }
    }

    this.isNewViability = true;

    if (useAddress && notIsCityAndUf) {
      const httpOptions = {
        headers: new HttpHeaders({
          'x-channel': 'ecommerce',
          'x-segment': 'pf',
          'x-partner': '',
          'x-customer-journey': 'prospect',
          'x-fingerprint': fingerprint_
        }),
      };

      return this.http.get(urlNewViability, httpOptions);
    }

    const AVAILABLE_TECHNOLOGIES_RESPONSE_SESSION_NAME = 'available_technologies_response';
    const storage = sessionStorage.getItem(AVAILABLE_TECHNOLOGIES_RESPONSE_SESSION_NAME);

    if (!!storage) {
      const viabilityStorage: any = jwtDecode(storage)

      const cepStorage = viabilityStorage?.data?.cep;
      const numberStorage = viabilityStorage?.data?.number;

      if (cepStorage === cep && numberStorage === number) {
        return of(
          viabilityStorage
        ).pipe(
          delay(50),
          tap((data: any) => {
            this.newViabilityTap(data);
          }),
          finalize(() => {
            this.newViabilityFinalize();
          })
        );
      }
    }

    const urlViability = environment.coberturaViability;

    const httpOptions = {
      headers: new HttpHeaders({
        'cep': cep,
        'number': number,
        'x-fingerprint': fingerprint_
      }),
    };

    return this.http.get(urlViability, httpOptions).pipe(
      map((resp: any) => {

        sessionStorage.setItem(AVAILABLE_TECHNOLOGIES_RESPONSE_SESSION_NAME, resp);

        const decodedx : any = jwtDecode(resp);
        
        if (decodedx?.data?.data) {
          const dataTemp = decodedx.data.data;
          decodedx.data = dataTemp;
        } 
               
        return decodedx;
      }),
      tap((data: any) => {
        this.newViabilityTap(data);
      }),
      finalize(() => {
        this.newViabilityFinalize();
      })
    );
  }

  newViabilityTap(data: any){
    if (data.data?.technologies && data.data.technologies.length > 0) {
      this.addressTechnology = this.updateAddressTechnology(data.data.technologies)
    }
    sessionStorage.removeItem('banner_info_cliente_claro_movel');
  }

  newViabilityFinalize() {
    if(this.addressTechnology === '') {this.addressTechnology = 'dth'}
  }

  getViability(cep: string, number: string, offShot: string, fingerprint_: string): Observable<any> {
    this.isNewViability = false;
    const urlViability = `${environment.apiPath}technicalViability?zipCode=${cep}&number=${number}&company=${environment.company}&offshoot=${offShot}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'x-fingerprint': fingerprint_
      }),
    };

    return this.http.get(urlViability, httpOptions);

  }

  updateAddressTechnology(technologies: TechnologyI[]) {
    const cable     = technologies.some(v => v.name === 'Cable');
    const gpon      = technologies.some(v => v.name === 'Cable' && v.gpon);
    const technology = gpon ? 'gpon' : cable ? 'hfc' : 'dth';

    sessionStorage.setItem('technology', technology);

    return technology

    // gpon = cabo com gpon
    // hfc  = cabo sem gpon
    // dth  = satélite
  }

  getAddressTechnology(): string {
    return this.addressTechnology;
  }
}
