import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AssineJaParserService } from './assine-ja-parser.service';
import { SubprodutoSelecaoService } from './subproduto-selecao.service';
import { SubprodutoSelecionadoService } from './subproduto-selecionado.service';
import { RecursoSelecaoService } from './recurso-selecao.service';
import { UtilsService } from './utils.service';
import { CroService } from './cro.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DependentesService } from './dependentes.service';
import { MdnModalService } from '../shared/mondrian-components/mdn-modal/mdn-modal.service';
import { SubprodutosSelecaoComponent } from '../modules/monte-sua-combinacao/modals/subprodutos-selecao/subprodutos-selecao.component';
import { IncentivoCelularService } from './incentivo-celular.service';
import { MonteSuaCombinacaoService } from './monte-sua-combinacao.service';
import { StateManagerService } from './state-manager-service';
import { AlertService } from '../shared/mondrian-components/alert/alert.service';
import { BucketS3Service } from './bucket-s3.service';
import { PromoVitrineConfig } from '../models/promo-vitrine-config.model';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { CommonNavigationService } from './navigation.service';
import { BucketS3MovelProductsModel } from '../models/bucket-s3-movel-products.model';

import { ConfiraOQueGanhouComponent } from '../shared/dialog/confira-o-que-ganhou/confira-o-que-ganhou.component';
import { PortabilidadeService } from './portabilidade.service';
import { StoryBlockService } from './storyblock.service';
import { BannerPromocionalService } from '../modules/monte-sua-combinacao/modals/banner-promocional/banner-promocional.service';
import { CroFormVersionsService } from './cro-form-versions.service';
import { NewAdicionaisService } from 'src/app/modules/new-adicionais/new-adicionais.service'

interface ComprarProdutoProps {
  id: number;
  tipo: any;
  cookie?: boolean;
  temAdicional?: boolean;
  isCombo?: boolean;
  showAlert?: boolean;
  hideFromCart?: boolean;
  shouldEmitEvent?: boolean;
  shouldSetDCC?: boolean;
}

interface RemoverProdutoProps {
  tipo: string;
  selectProduct?: boolean;
  shouldEmitEvent?: boolean;
}

interface Promotionalplan {
  id: number;
  type: string;
  campaign: string;
}

interface Products {
  type: string,
  name: string,
  value: string,
  extras: Extras,
  id: string,
}

interface Extras {
  resources: string[],
  additionals: any[]
}

interface MartechTagging {
  lead: {
    display: string,
    products: Products[],
    fee: {}[];
    total: string
  }
}
@Injectable({
  providedIn: 'root',
})
export class CarrinhoService {
  carrinhoParserReady$: Subject<any> = new Subject<any>();
  errorAddingToCart$: Subject<any> = new Subject<any>();
  limparCarrinho$: Subject<any> = new Subject<any>();
  atualizouCarrinho$: Subject<any> = new Subject<any>();
  adicionadoViaAbandono$: Subject<any> = new Subject<any>();
  carrinhoReload$: Subject<any> = new Subject<any>();
  carrinhoRecalque$: Subject<any> = new Subject<any>();
  confirmacaoAssinatura$: Subject<any> = new Subject<any>();
  fechouCarrinho$: Subject<any> = new Subject<any>();
  produtoRemovido$: Subject<any> = new Subject<any>();
  updateIsClienteMovel$: Subject<boolean> = new Subject<boolean>();
  rehydrateCartSelectionDone$: Subject<any> = new Subject<any>();
  openModalCustomerIndetification$: Subject<string> = new Subject<string>();
  lastCroObj: any = [];
  parser: any;
  quantidadeCarrinho: number = 0;
  statusCart: boolean = false;
  containerCart: string = '';
  addsSession = [];
  celId: number;
  mediaQuery: any;
  isConfirmacaoLead: boolean;
  internetNameIncentivo: string;
  productIncentivo: any;
  idCelularIncentivo: any;
  celularSelecionado: any;
  isSingle: boolean = false;
  listPromotionalplans: any = [];
  fetchClaroMovelConfigStatus: string = 'unrequested';
  listFromToPlans: PromoVitrineConfig;
  requestStatusListsFromToPlans: string = 'not requested';
  resquestsListFromToPlans: any[] = [];
  movelProducts: BucketS3MovelProductsModel[] = [];
  updatedMobileCustomer: boolean = false;
  public static readonly MULTI_OFERTA = 'multi-oferta-';
  public static readonly MULTI_OFERTA_PRE_PAGO = 'multi-oferta-pre-';
  private quantidaCarrinhoDCC: number = 0;
  public dccRules: boolean ;

  constructor(
    public assine: AssineJaParserService,
    private subproduto: SubprodutoSelecaoService,
    private subprodutoSelecionado: SubprodutoSelecionadoService,
    private recursoSelecao: RecursoSelecaoService,
    public modal: MdnModalService,
    public utils: UtilsService,
    private cro: CroService,
    private router: Router,
    private route: ActivatedRoute,
    private dependentesSvc: DependentesService,
    public incentivoCelularService: IncentivoCelularService,
    public monteCombinacaoService: MonteSuaCombinacaoService,
    public stateManagerService: StateManagerService,
    public alert: AlertService,
    public bucketS3Service: BucketS3Service,
    public http: HttpClient,
    public navigationService: CommonNavigationService,
    public portabilidadeService: PortabilidadeService,
    public storyBlokService: StoryBlockService,
    private bannerPromocionalService: BannerPromocionalService,
    public croFormVersionsService: CroFormVersionsService,
    private NewAdicionaisService: NewAdicionaisService
  ) {
    this.assine.parserReady$.subscribe(() => {
      this.rehydrateCartSelection();
      this.openCartOnMobile();
      this.setQuantidadeCarrinhoDCC();
    }, (error) => { console.error(error) });

    this.subproduto.subprodutoAtulizado$.subscribe((data) => {
      this.parser = data;
      this.carrinhoParserReady$.next(true);
      this.atualizouCarrinho$.next(true);

      this.subproduto.carregaNovoParser(data);
    });

    this.subprodutoSelecionado.subprodutoSelecionadoAtualizado$.subscribe(
      (data) => {
        this.parser = data;
        this.carrinhoParserReady$.next(true);
        this.atualizouCarrinho$.next(true);
      }
    );

    this.updatedMobileCustomer = this.utils.storageGetItem('ModalConfiraOQueGanhouShowed') !== '';

    this.bucketS3Service.getMovelProducts().subscribe(
      (data) => { this.movelProducts = data },
      (error) => { console.error(error) }
    );
  }
  rehydrateCartSelection(): void {
    this.setQuantidadeCarrinhoDCC();
    const isRural = sessionStorage.getItem('isRural') === 'true';
    const isDth = sessionStorage.getItem('technology') === 'dth';

    this.parser = this.assine.parser;

    if (
      this.parser.cart &&
      !this.parser.cart.internetId &&
      this.utils.storage.getItem('internetId') !== ''
    ) {
      this.utils.storage
        .getItem('internetId')
        .split(',')
        .forEach((id) => {
          if (!this.parser.cart.internetId && !isDth) {
            this.comprarProduto({
              id: Number(id),
              tipo: 'internet',
              cookie: true,
              showAlert: true,
            });
          }

          if (isDth && isRural) {
            this.comprarProduto({
              id: Number(id),
              tipo: 'internet',
              cookie: true,
              showAlert: true,
            });
          }
        });
    }
    if (
      this.parser.cart &&
      !this.parser.cart.tvId &&
      this.utils.storage.getItem('tvId') !== ''
    ) {
      this.utils.storage
        .getItem('tvId')
        .split(',')
        .forEach((id) => {
          if (!this.parser.cart.tvId) {
            this.comprarProduto({
              id: Number(id),
              tipo: 'tv',
              cookie: true,
              showAlert: true,
            });
          }
        });
    }
    if (
      this.parser.cart &&
      !this.parser.cart.foneId &&
      this.utils.storage.getItem('foneId') !== ''
    ) {
      this.utils.storage
        .getItem('foneId')
        .split(',')
        .forEach((id) => {
          if (!this.parser.cart.foneId) {
            this.comprarProduto({
              id: Number(id),
              tipo: 'fone',
              cookie: true,
              showAlert: true,
            });
          }
        });
    }
    if (
      this.parser.cart &&
      !this.parser.cart.celularId &&
      this.utils.storage.getItem('celularId') !== ''
    ) {
      this.utils.storage
        .getItem('celularId')
        .split(',')
        .forEach((id) => {
          if (!this.parser.cart.celularId) {
            this.comprarProduto({
              id: Number(id),
              tipo: 'celular',
              cookie: true,
              showAlert: true,
            });
          }
        });
    }
    if (this.utils.storage.getItem('additionalIds') !== '') {
      const additionalIds = this.utils.storage.getItem('additionalIds').split(',');
      let hasPontoultra = false;
      additionalIds.forEach(additionalId => {
        const additional = this.parser.cart.adicionais.getById(additionalId)
        if (additional) {
          const isPontoUltra = this.NewAdicionaisService.stringSanitizer(additional.nome).includes('pontoultra');
          if (isPontoUltra) {
            hasPontoultra = true;
            this.utils.storageSetItem('pontoultra', additional.taxaInstalacao);
            this.utils.storageSetItem('pontoultraId', additional.id.toString());
          }
        }
      })

      if (!hasPontoultra) {
        sessionStorage.removeItem('pontoultra')
        sessionStorage.removeItem('pontoultraId')
      }
      
      this.parser.cart.adicionais.add(additionalIds);
    }
    if (this.utils.storage.getItem('subprodutosIds') !== '') {
      this.parser.cart.subprodutos.add(
        this.utils.storage.getItem('subprodutosIds').split(',')
      );
    }

    const selection = this.parser?.cart?.selection
    if (selection) {
      this.checkComboMultiCombinations(this.parser.cart.selection)
    }

    this.saveCategoryUrlIds();
    this.rehydrateCartSelectionDone$.next();
  }

  saveCategoryUrlIds() {
    const categories = ['internetId', 'tvId', 'celularId', 'foneId'];
    const url = window.location.href;

    categories.forEach(category => {
      const regex = new RegExp(`[?&]${category}=([^&#]*)`, 'i');
      const match = regex.exec(url);
      
      if (match) {
        const value = match[1];
        sessionStorage.setItem(category, value);
      }
    });
  }

  checkComboMultiCombinations(selection: any) {
    const list = []

    Object.keys(selection).forEach(x => {
      if (selection[x].tags?.some((x: string) => x.startsWith('multi-oferta-'))) {
        list.push(selection[x])
      }
    })
    
    const length_ = list.length

    if (length_ >= 2) {
      let tagList = [];

      list.forEach(x => {
        tagList = tagList.concat(x.tags)
      });

      tagList = tagList.filter(x => x.startsWith('multi-oferta-'))

      let counts = {};
      tagList.forEach(function (x) { counts[x] = (counts[x] || 0) + 1; });

      let verify = false;

      Object.keys(counts).forEach(x => {
        if (counts[x] >= length_) {
          verify = true;
        }
      })

      if (verify === false) {
        this.removerProdutoMultiOferta();
      }

    } else {
      this.removerProdutoMultiOferta()
    }
  }

  isAbleToBought(product): boolean{
    const isGpon = sessionStorage.getItem('isGpon') === 'true';
    const selectedProduct = this.parser.data.getProductById(product.tipo, product.id) || {}
    const hastagGpon = !!selectedProduct.tags && !!selectedProduct.tags.find(tag=> tag==='gpon')
    // Adicionado a variavel hasProductViability, pois se não retornar true o produto da viability, não podemos vender em HFC,
    //para os casos de gpon ainda será avaliado pois temos o tecnico ok.      EUNCP-4780
    const hasProductViability = sessionStorage.getItem(product.tipo) === 'true'

    if(selectedProduct && isGpon && hastagGpon){
      return true
    }else if(selectedProduct && !isGpon && !hasProductViability){
      this.utils.storageRemoveItem(`${product.tipo}Id`)
      return false
    }else if(selectedProduct && !isGpon && !hastagGpon){
      return true
    }else{
      this.utils.storageRemoveItem(`${product.tipo}Id`)
      return false
    }
  }

  createMartechTagging() {
    if (!this.parser) {
      return
    }

    const selection = this.parser.cart.selection;
    if (!selection) {
      return
    }
    delete selection['selecoes']
    const { products, display } = this.mountMartechProducts(selection)
    let fee: any;
    let total = '';

    fee = this.mountMartechFee(selection);
    total = this.utils.formatCurrency(this.parser.cart.getMontly());

    this.sendMartechTagging(display, products, fee, total)
  }

  mountMartechProducts(selection: any) {
    let display: string = '';
    let products: Products[] = [];

    Object.keys(selection).forEach((product, index) => {
      let extras: Extras = { resources: [], additionals: [] };

      const resources =   selection[product]?.recursos ?
                          Object.keys(selection[product].recursos).map(item => selection[product].recursos[item].nome) : [];
      const additionals = selection[product]?.selecionados ?
                          Object.keys(selection[product].selecionados).map(item => selection[product].selecionados[item]) : [];

      extras.resources.push(...resources);
      extras.additionals.push(...additionals);

      display += selection[product].nome;

      if (index !== Object.keys(selection).length - 1) {
          display += ' + ';
      }

      products.push({
        type: product,
        name: this.parser.cart.selection[product].nome,
        value: this.utils.formatCurrency(this.parser.cart.selection[product].preco),
        extras: extras,
        id: this.parser.cart.selection[product].id
      })
    });

    return { products, display }
  }

  mountMartechFee(selection: any) {
    let fee: {}[] = [];
    let martechFee: number = 0;

    Object.keys(selection).forEach((product) => {
      martechFee += selection[product].taxaInstalacao;
    })

    fee.push(
      {
        taxaDeInstalacao: martechFee === 0 ? 'Grátis' : this.utils.formatCurrency(martechFee)
      },
      {
        taxaDeAdesao: this.parser.cart.getSignup() === 0 ? 'Grátis' : this.utils.formatCurrency(this.parser.cart.getSignup())
      }
    )

    return fee;
  }

  sendMartechTagging(display: any, products: any, fee: any, total: any) {
    const martechTag: MartechTagging = {
      lead: {
        display: display,
        products: products,
        fee: fee,
        total: total
      }
    }

    this.cro.postDataLayer(martechTag);
  }

  comprarProduto(props: ComprarProdutoProps): void {    
    let isDifferentId: boolean;
    const tipoId = this.parser.cart[`${props.tipo}Id`];
    if(props.tipo !== 'celular' && !this.isAbleToBought(props)){
      return
    }

    if (
      props.tipo === 'tv' ||
      props.tipo === 'internet' ||
      props.tipo === 'fone'
    ) {
      if (props.id !== tipoId) {
        isDifferentId = true;
      } else {
        isDifferentId = false;
      }
    }

    if (props.tipo === 'internet') {
      this.productIncentivo = this.assine.parser.data.getProductById(
        'internet',
        props.id
      );

      if (!this.productIncentivo) {
        this.errorAddingToCart$.next(props.tipo);
        return;
      }

      this.incentivoCelularService.getTagIncentivo(this.productIncentivo);
      this.internetNameIncentivo = this.productIncentivo.nome;
      if (
        this.parser.cart.celularId &&
        this.parser.cart.celularId ===
        this.incentivoCelularService.idIncentivoCelular
      ) {
        this.incentivoCelularService.idIncentivoCelular = undefined;
      } else if (this.parser.cart.celularId) {
        const celularCarrinho = this.incentivoCelularService.getIndividualPrice(
          this.monteCombinacaoService.getProductsPeriodSelections(
            this.assine,
            'celular'
          ),
          this.parser.cart.celularId
        );
        const celularIncentivo = this.assine.parser.data.getProductById(
          'celular',
          this.incentivoCelularService.idIncentivoCelular
        );
        if (
          celularIncentivo &&
          celularCarrinho.individual &&
          celularCarrinho.individual &&
          celularCarrinho.individual.preco >= celularIncentivo.preco
        ) {
          this.incentivoCelularService.idIncentivoCelular = undefined;
        }
      }
    }

    if (props.tipo === 'celular') {
      this.productIncentivo = this.assine.parser.data.getProductById(
        'celular',
        props.id
      );

      if (!this.productIncentivo) {
        this.errorAddingToCart$.next(props.tipo);
        return;
      }

      this.incentivoCelularService.getTagIncentivo(this.productIncentivo);
      const celularAdicionado = this.incentivoCelularService.getIndividualPrice(
        this.monteCombinacaoService.getProductsPeriodSelections(
          this.assine,
          'celular'
        ),
        props.id
      );
      const celularIncentivo = this.assine.parser.data.getProductById(
        'celular',
        this.incentivoCelularService.idIncentivoCelular
      );
      if (props.id === this.incentivoCelularService.idIncentivoCelular) {
        this.idCelularIncentivo =
          this.incentivoCelularService.idIncentivoCelular;
        this.incentivoCelularService.idIncentivoCelular = undefined;
      }
      if (
        celularIncentivo &&
        celularAdicionado.individual &&
        celularAdicionado.individual &&
        celularAdicionado.individual.preco >= celularIncentivo.preco
      ) {
        this.idCelularIncentivo =
          this.incentivoCelularService.idIncentivoCelular;
        this.incentivoCelularService.idIncentivoCelular = undefined;
      }
      if (this.utils.storageGetItem('cookieCelularDependentes')) {
        const { dependentsLine } = JSON.parse(
          this.utils.storageGetItem('cookieCelularDependentes')
        );
        this.dependentesSvc.sendDependente(dependentsLine);
      }
    }

    if (props.tipo === 'tv') {
      this.productIncentivo = this.assine.parser.data.getProductById(
        'tv',
        props.id
      );

      if (!this.productIncentivo) {
        this.errorAddingToCart$.next(props.tipo);
        return;
      }
    }

    if (props.tipo === 'fone') {
      this.productIncentivo = this.assine.parser.data.getProductById(
        'fone',
        props.id
      );

      if (!this.productIncentivo) {
        this.errorAddingToCart$.next(props.tipo);
        return;
      }
    }

    const qtdCarrinhoDCC = this.getQuantidadeCarrinhoDCC();
    const dccRules = JSON.parse(sessionStorage.getItem("dccRulesPerCategory"));
    const customerDccOption = sessionStorage.getItem('customerDccOption')

    let dccRule;

    if (!!customerDccOption) {
      dccRule = customerDccOption === 'true'
    } else {
      dccRule = qtdCarrinhoDCC === 0 ? dccRules[props.tipo] : dccRules.combo
    }

    sessionStorage.setItem('directDebit', String(dccRule))

    this.parser.cart.conditions[props.tipo] = dccRule;
    this.parser.cart.addProduct(props.tipo, props.id);
    this.setQuantidadeCarrinhoDCC();  
    if (props.temAdicional) {
      this.incluirSubprodutos(props.tipo, isDifferentId);
    }

    if (
      props.tipo !== 'celular' &&
      isDifferentId &&
      !props.cookie &&
      !props.isCombo &&
      props.showAlert
    ) {
      this.alert.open({
        data: {
          type: 'success',
          theme: 'light',
          text: 'Plano adicionado no carrinho.',
          time: 3000,
        },
      });
    }

    this.checaDobroVelocidade();

    this.sendCRO(
      this.parser.cart.selection[props.tipo],
      'planos/' + props.tipo,
      props.cookie
    );

    this.validateProduct(props.tipo);
    if (this.parser.cart.selection[props.tipo]) {
      this.utils.storage.setItem(`${props.tipo}Id`, props.id);
      this.carrinhoParserReady$.next(true);
      this.showModalConfiraOQueGanhou();
      // this.checkPromotionalPlan(props.id, props.tipo);
      this.checkPlanUpdateRules('add', props.tipo, props.id);

      const isCroTest = this.croFormVersionsService.isACroFormVersion();
      if (isCroTest) {
        this.croFormVersionsService.sendLeadForCroTest$.next();
      }
    } else {
      this.errorAddingToCart$.next(props.tipo);
    }

    this.setisSingle();

    return;
  }

  showModalConfiraOQueGanhou(): void {
    const showed = this.utils.storageGetItem('ModalConfiraOQueGanhouShowed');
    const MobileCustomer = this.utils.storageGetItem('cliente-claro-movel');
    const tvOrInternetInCart = this.parser.cart.selection?.tv || this.parser.cart.selection?.internet;
    const newPlan = this.utils.storageGetItem('cliente-movel-de-para-mobile-plan');
    const newId = newPlan && JSON.parse(newPlan)?.para;

    if (showed !== 'true' && MobileCustomer === 'true' && tvOrInternetInCart && !!newId) {
      this.utils.storageSetItem('ModalConfiraOQueGanhouShowed', 'true');
      this.updatedMobileCustomer = true;

      // adiciona o novo plano no carrinho
      this.comprarProduto({
        id: newId,
        tipo: 'celular',
        cookie: true,
        showAlert: true,
      });

      const mobile = this.assine.parser?.cart?.selection?.celular;

      this.modal.open(ConfiraOQueGanhouComponent, {
        data: {
          size: 'lg',
          titulo: 'Identificação do cliente',
          sunWaterMark: true,
          planName: mobile?.nome || '',
          planValue: mobile?.preco || '',
        },
      });

      const phoneStorage = this.utils.storageGetItem('claro-movel-phone');
      if (phoneStorage !== '') {
        const portabilidadeObj = {
          area: phoneStorage.substring(0, 2),
          carrier: 'claro',
          number: phoneStorage.substring(2),
          productId: newId,
          valid: true
        };

        this.utils.storage.setItem('cookiePortabilityCelular', JSON.stringify(portabilidadeObj));
        this.portabilidadeService.portabilidadeReady$.next(true);
      }
    }
  }

  checkPromotionalPlan(id: number, type?: string): void {
    if (this.fetchClaroMovelConfigStatus === 'unrequested') {
      this.fetchClaroMovelConfigStatus = 'requesting';
      this.storyBlokService.getListPromotionalPlans().subscribe(
        (data: any) => {
          const promotions = data.stories.map(promotion => {
            const content = promotion.content;
            return {
              campaign: content.campaign,
              type: content.type,
              id: Number(content.id)
            }
          })
          this.fetchClaroMovelConfigStatus = 'requested';
          this.listPromotionalplans = promotions || [];
          if (this.listPromotionalplans.length > 0) {
            const selection = this.getCart().selection;
            if (selection) {
              Object.keys(selection).map((category) => {
                if (category !== 'selecoes') {
                  this.applyCampaign(selection[category].id, category);
                }
              });
            } else {
              this.applyCampaign(id, type);
            }
          }
        },
        (error) => {
          this.fetchClaroMovelConfigStatus = 'unrequested';
          this.listPromotionalplans = [];
        }
      );
    } else if (this.fetchClaroMovelConfigStatus === 'requested') {
      this.applyCampaign(id, type);
    }
  }

  applyCampaign(id: number, category?: string): void {
    const idFound = this.listPromotionalplans.find((item) => {
      return item.id === id && item.type === category;
    });

    if (idFound) {
      switch (idFound.campaign) {
        case 'cliente-claro-movel':
          this.mobileClaroClientValidation(category, id);
          break;
        default:
          console.log(
            `nenhuma ação definida para a campanha ${idFound.campaign}`
          );
      }
    }
  }

  mobileClaroClientValidation(type: string, id: number): void {
    this.utils.storageSetItem('promo-plan-applied', JSON.stringify({ planType: type, planId: id }));

    const claroMobileClient = this.utils.storageGetItem('cliente-claro-movel');

    if (claroMobileClient !== 'true') {
      this.openModalCustomerIndetification$.next(type);
    } else {
      this.updateIsClienteMovel$.next(true);
    }
  }

  sendCRO(product: any, tipo: string, cookie?): void {
    const croObj: any = [];
    const _vm = this;
    if (product && cookie === false) {
      croObj.push({
        name: this.utils.convertToSanitizer(product.nome),
        id: product.id,
        price:
          product.preco.currency().integer() +
          '.' +
          product.preco.currency().cents(), // TODO: Remover este currency do Jquery
        brand:
          tipo === 'planos/celular' ? 'claro-movel' : _vm.utils.getBrandTag(),
        category: tipo,
        quantity: '1',
      });

      if (JSON.stringify(this.lastCroObj) !== JSON.stringify(croObj)) {
        this.cro.AddToCart(croObj);
        this.lastCroObj = croObj;
      }
    }
  }

  removeItemCROCart(productType: string): void {
    let croProduto: any;
    let croObjeto: any;
    if (
      this.parser.cart &&
      this.parser.cart.selection &&
      this.parser.cart.selection[productType]
    ) {
      croProduto = this.parser.cart.selection[productType];
      croObjeto = {
        name: this.utils.convertToSanitizer(croProduto.nome),
        id: croProduto.id,
        price: this.utils.formataDinheiroCro(croProduto.preco.toString()),
        brand:
          productType === 'celular' ? 'claro-movel' : this.utils.getBrandTag(),
        category: 'planos/' + productType,
        quantity: 1,
      };
      if (this.router.url.indexOf('/checkout/confirmacao') === -1) {
        this.cro.RemoveFromCart(croObjeto);
      }
    }
  }

  clearStorageByProductType(tipo: string): void {
    switch (tipo) {
      case 'tv':
        // TODO: código referente a EUNCP-3872, remover após o teste A/B
        this.utils.cookieService.delete('tvId');
        this.utils.storage.removeItem('originCro');
        this.utils.storage.removeItem('subprodutosIds');
        break;
      case 'internet':
        this.addsSession = this.utils
          .storageGetItem('additionalIds')
          .split(',');
        this.utils.storageSetItem(
          'additionalIds',
          this.parser.cart.adicionais.keys
        );
        break;
      case 'celular':
        this.dependentesSvc.sendDependente([]);
        this.utils.storage.removeItem('cookieCelularDependentes');
        break;
      case 'fone':
        this.utils.storageSetItem('removedFone', 'true')
        break;
      default:
        break;
    }
    this.utils.storage.removeItem(`${tipo}Id`);
  }

  removeParamsUrl(parametro: string): void {
    let queryParams = { ...this.route.snapshot.queryParams };

    delete queryParams[parametro];

    this.router.navigate([], { queryParams: queryParams });
  }
  removerProduto(props: RemoverProdutoProps): void {
    if(props && props.tipo === 'tv'){
      this.utils.storageRemoveItem('isBoxTv');
      this.utils.storageRemoveItem('isAppTv');
    }

    if (props && props.tipo === 'internet') {
      sessionStorage.setItem('isRural', 'false');
    }
    
    if (
      !props.selectProduct &&
      props.tipo === 'celular' &&
      this.assine.parser.cart &&
      this.assine.parser.cart.celularId !== this.idCelularIncentivo &&
      this.assine.parser.cart &&
      this.assine.parser.cart.internetId ===
      this.incentivoCelularService.internetSelecionadaId
    ) {
      this.incentivoCelularService.getTagIncentivo(this.productIncentivo);
      this.internetNameIncentivo = this.productIncentivo
        ? this.productIncentivo.nome
        : '';
    }
    this.removeItemCROCart(props.tipo);
    this.clearStorageByProductType(props.tipo);

    if (props && props.tipo === 'celular') {
      this.utils.storage.removeItem('cookiePortabilityCelular');
    }

    this.removerSubprodutosAdicionais(props.tipo)
    this.removeParamsUrl(props.tipo + 'Id')

    this.assine.parser.cart.removeProduct(props.tipo);
    this.checaDobroVelocidade();
    
    const isCartEmpty = this.assine.parser?.cart?.selection
    ? Object.keys(this.assine.parser.cart.selection).length === 0
    : true;
    this.setQuantidadeCarrinhoDCC()
    if (isCartEmpty) {
      sessionStorage.removeItem('customerDccOption')
      this.recalque('dcc', true);
      this.recalque('digitalInvoice', true);
    } else {
      const qtdCarrinhoDCC = this.getQuantidadeCarrinhoDCC();
      const dccRules = JSON.parse(sessionStorage.getItem("dccRulesPerCategory"));
      const customerDccOption = sessionStorage.getItem('customerDccOption')

      let dccRule;

      if (!!customerDccOption) {
        dccRule = customerDccOption === 'true'
      } else {
        if (qtdCarrinhoDCC === 1) {
          const categories = ['internet', 'tv', 'celular', 'fone']
          let categoryInTheCart;
          categoryInTheCart = categories.find(category => !!sessionStorage.getItem(category+'Id'))
          if (categoryInTheCart === 'fone') {
            categoryInTheCart = 'foneFixo'
          }

          dccRule = dccRules[categoryInTheCart]
        } else {
          dccRule = dccRules.combo
        }
      }

      sessionStorage.setItem('directDebit', String(dccRule))
    }

    const storagepromoPlanApplied = this.utils.storageGetItem('promo-plan-applied');
    const promoPlanApplied = storagepromoPlanApplied
      ? JSON.parse(storagepromoPlanApplied)
      : false;
    if (!!promoPlanApplied && promoPlanApplied.planType === props.tipo) {
      this.utils.storageRemoveItem('promo-plan-applied');
    }

    const isCroTest = this.croFormVersionsService.isACroFormVersion();
    if (isCroTest) {
      this.croFormVersionsService.sendLeadForCroTest$.next();
    }

    this.carrinhoParserReady$.next(props.tipo);
    this.setisSingle();

    const checkSelection = this.assine.parser?.cart?.selection
      ? Object.keys(this.assine.parser.cart.selection).length
      : 0;

    if (checkSelection === 0) {
      this.assine.parser.cart.conditions.fidelity = true;
      this.limparCarrinho$.next(true);
    }
    if (props.shouldEmitEvent === true || props.shouldEmitEvent === undefined) {
      this.produtoRemovido$.next();
      this.checkPlanUpdateRules('remove', props.tipo);
      this.checkCustomerMobile();
    }
    if (this.router.url.indexOf('/checkout/confirmacao') <= -1) {
      this.checkPromoInCartToOpenBannerPromocional()
    }
  }

  checkPromoInCartToOpenBannerPromocional(){
    if(this.bannerPromocionalService.shouldReopenBanner()){
      this.bannerPromocionalService.setStoragePromoInCart('removed');
      this.bannerPromocionalService.openBanner(this.getCart())
    }
  }

  removerSubprodutosAdicionais(tipo: string){
    if (
      this.assine.parser.cart.selection &&
      this.assine.parser.cart.selection[tipo]
    ) {
      const subprodutos =
        this.assine.parser.cart.selection[tipo].selecionados;
      const storageAdicionaisIds = this.utils
        .storageGetItem('additionalIds')
        .split(',');
      const storagesubprodutosIds = this.utils
        .storageGetItem('subprodutosIds')
        .split(',');

      if (subprodutos) {
        subprodutos.forEach((subproduto) => {
          if (subproduto.tipo === 'subprodutos') {
            const index = storagesubprodutosIds.indexOf(
              subproduto.id.toString()
            );
            storagesubprodutosIds.splice(index, 1);
            this.assine.parser.cart.subprodutos.remove(subproduto.id);
          }
          if (subproduto.tipo === 'adicionais') {
            const index = storageAdicionaisIds.indexOf(
              subproduto.id.toString()
            );
            storageAdicionaisIds.splice(index, 1);
            this.assine.parser.cart.adicionais.remove(subproduto.id);
          }
        });
      }

      this.utils.storageSetItem(
        'additionalIds',
        storageAdicionaisIds.toString()
      );
      this.utils.storageSetItem(
        'subprodutosIds',
        storagesubprodutosIds.toString()
      );
    }
  }

  checkCustomerMobile(): void {
    const MobileCustomer = this.utils.storageGetItem('cliente-claro-movel');
    const tvOrInternetInCart = this.parser.cart.selection?.tv || this.parser.cart.selection?.internet;
    const ModalConfiraOQueGanhouShowedStorage = this.utils.storageGetItem('ModalConfiraOQueGanhouShowed');

    if (MobileCustomer === 'true' && !tvOrInternetInCart && ModalConfiraOQueGanhouShowedStorage) {
      this.utils.storageRemoveItem('ModalConfiraOQueGanhouShowed');
      this.updatedMobileCustomer = false;
      this.removerProduto({ tipo: 'celular' });
    }
  }

  isPaymentInAdvance(): boolean {
    const { selection } = this.getCart();
    const catalog = this.utils.storageGetItem('catalog');

    return !!(
      catalog === 'clarotv' &&
      selection &&
      (selection.fone || selection.tv)
    );
  }

  redirectToShopFrontCheck(): void {
    if (
      (this.router.url.indexOf('/checkout/confirmacao') === -1 &&
        this.router.url.indexOf('/checkout') > -1)
    ) {
      if (window.location.pathname.indexOf('/assine-empresas') === -1) {
        this.router.navigate(['/monte-sua-combinacao']);
      } else {
        this.router.navigate(['/assine-empresas/monte-sua-combinacao']);
      }
    }
  }

  limparCarrinho(click?: boolean): void {
    this.utils.storageRemoveItem('pontoultra');
    this.utils.storageRemoveItem('pontoultraId');
    if (this.router.url.indexOf('/checkout/confirmacao') > -1) {
      this.assine.parserReady = false;
      this.isConfirmacaoLead = true;
    }

    ['internet', 'tv', 'fone', 'celular'].forEach((item) => {
      if (
        this.assine.parser.cart.selection &&
        this.assine.parser.cart.selection[item]
      ) {
        this.removerProduto({ tipo: item });
      }
      this.utils.storageRemoveItem(item + 'IdDefault');
    });

    ['additionalIds', 'subprodutosIds', 'leadId'].forEach((item) =>
      this.utils.storage.removeItem(item)
    );

    if (click && this.utils.storage.getItem('CA') === 'true') {
      this.utils.storage.removeItem('CA');
      this.utils.storage.removeItem('caInternetId');
    }

    this.quantidadeCarrinho = 0;
    this.utils.storage.cookieService.set('quantidadeCarrinho', 0);
    this.statusCart = false;
    this.checaDobroVelocidade();
    this.carrinhoParserReady$.next(true);
    this.limparCarrinho$.next(true);
    this.redirectToShopFrontCheck();

    this.assine.parser.periodicidade = undefined;
  }

  carrinhoAtualizado(tipo?) {
    tipo !== undefined
      ? this.atualizouCarrinho$.next(tipo)
      : this.atualizouCarrinho$.next(true);
    this.quantidadeCarrinho = Number(
      this.utils.storage.cookieService.get('quantidadeCarrinho')
    );
    this.inclusaoAutomatica();
    this.createMartechTagging();
  }

  inclusaoAutomatica() {
    const selection = this.assine.parser.cart.selection
    if (selection !== undefined) {
      if (!selection.fone
        && !!selection.internet
        && !!selection.celular
        && !selection.tv
        && (this.utils.storageGetItem('removedFone') !== 'true')) {
        const produtos: any = this.monteCombinacaoService.getProductsPeriodSelections(this.assine, 'fone')
        const produtoGratis = produtos.find(produto => produto.preco === 0);
        if (!!produtoGratis && parseInt(this.utils.storageGetItem('foneId')) !== produtoGratis.id) {
          this.comprarProduto({
            id: produtoGratis.id,
            tipo: 'fone',
            cookie: false,
            temAdicional: false,
            showAlert: true,
          });
          this.utils.storageSetItem('flagInclusaoFone', 'true')
          this.utils.storageSetItem('removedFone', 'false')
        }
      } else if (!!selection.fone
        && (selection.fone.preco !== 0 || !!selection.tv)
        && (this.utils.storageGetItem('flagInclusaoFone') === 'true')
        && (this.utils.storageGetItem('removedFone') === 'false')) {
        this.removerProduto({
          tipo: 'fone'
        });
        this.utils.storageSetItem('removedFone', 'false')
      }
    }
  }

  incluirSubprodutos(tipoProduto: string, ProductIsDifferentId?: boolean, showModalProduto?: boolean): void {
    if (showModalProduto) {
      this.modal.open(SubprodutosSelecaoComponent, {
        data: {
          size: tipoProduto !== 'tv' ? 'lg' : 'xl',
          titulo: `Turbine sua ${tipoProduto}`,
          tipo: tipoProduto,
          parser: this.parser,
          isDifferentId: ProductIsDifferentId,
        },
      });
    }
  }

  checaDobroVelocidade(): void {
    if (
      this.parser.cart.selection &&
      (this.parser.cart.selection.combo || this.parser.cart.selection.selecoes)
    ) {
      const selecao =
        this.parser.cart.selection.combo || this.parser.cart.selection.selecoes;
      const regexCelular = new RegExp('dobro celular', 'gi');
      const regexInternet = new RegExp('dobro internet', 'gi');
      const regexTv = new RegExp('recurso tv', 'gi');
      const regexGanheTv = new RegExp('ganhe tv', 'gi');

      const recCelular = [];
      const recInternet = [];
      const recTv = [];

      const recursos = selecao && selecao.recursos;
      this.recursoSelecao.possuiRecursoGratis('', null);

      for (const key in recursos) {
        if (recursos[key].nome.match(regexCelular)) {
          if (
            recCelular.filter(
              (item) => recursos[key].descricao === item.descricao
            ).length === 0
          ) {
            recCelular.push(recursos[key]);
            this.recursoSelecao.possuiRecursoGratis('celular', recCelular);
          }
        } else if (recursos[key].nome.match(regexInternet)) {
          if (
            recInternet.filter(
              (item) => recursos[key].descricao === item.descricao
            ).length === 0
          ) {
            recInternet.push(recursos[key]);
            this.recursoSelecao.possuiRecursoGratis('internet', recInternet);
          }
        } else if (recursos[key].nome.match(regexTv)) {
          if (
            recTv.filter((item) => recursos[key].descricao === item.descricao)
              .length === 0
          ) {
            recTv.push(recursos[key]);
            this.recursoSelecao.possuiRecursoGratis('tv', recTv);
          }
        } else if (recursos[key].nome.match(regexGanheTv)) {
          if (
            recTv.filter((item) => recursos[key].descricao === item.descricao)
              .length === 0
          ) {
            recTv.push(recursos[key]);
            this.recursoSelecao.possuiRecursoGratis('tv', recTv);
          }
        }
      }
    } else {
      this.recursoSelecao.possuiRecursoGratis(null, '');
    }
  }

  getCart() {
    if (!(this.assine.parser && this.assine.parser.cart)) {
      return {};
    }

    const { selection } = this.assine.parser.cart;

    return {
      selection,
      subprodutos: this.assine.parser && this.assine.parser.cart.subprodutos,
      adicionais: this.assine.parser && this.assine.parser.cart.adicionais,
      dependentes: this.assine.parser && this.assine.parser.cart.dependentes,
      getPeriodos: this.assine.parser && this.assine.parser.cart.getPeriodos,
      prices: {
        to: this.assine.parser && this.assine.parser.cart.getMontly(),
        from: this.assine.parser && this.assine.parser.cart.getMontlyFrom(),
      },
      signup: {
        value: this.assine.parser && this.assine.parser.cart.getSignup(),
        prepaid:
          this.assine.parser && this.assine.parser.cart.getSignupPrePaid(),
        installments:
          this.assine.parser && this.assine.parser.cart.getSignupInstallments(),
      },
      installationFee:
        this.assine.parser && this.assine.parser.cart.getInstallation(),
    };
  }

  recalque(type: string, value: boolean): void { 
    if (this.parser.cart) {
      
      const qtdCarrinhoDCC = this.getQuantidadeCarrinhoDCC();
      const dccRules = JSON.parse(sessionStorage.getItem("dccRulesPerCategory"));  
      
      const product = Object.keys(this.parser.cart.selection)[0];

      if (type === 'dcc') {
        sessionStorage.setItem('directDebit', String(value));
        const storageCustomerDccOption = sessionStorage.getItem('customerDccOption');
        this.parser.cart.conditions.customerDccOption = !!storageCustomerDccOption ? storageCustomerDccOption : undefined;
      }

      this.parser.cart.conditions[type] = value;      

      const { ...combinacao } =
        this.parser.cart && this.parser.cart.selection;

      for (const key of Object.keys(combinacao)) {
        this.parser.cart.addProduct(key, combinacao[key].id);
      }

      this.carrinhoReload$.next(true);
      this.carrinhoRecalque$.next(true);      
    }
  }

  setAddressOnLocalStorage(): void {
    this.utils.storage.setItem('city', this.assine.jsonCity);
    this.utils.storage.setItem('uf', this.assine.jsonUF);
  }

  toggleCart(): void {
    this.mediaQuery = window.matchMedia('(max-width: 1199px)');
    this.statusCart = !this.statusCart;
    const toggleGtmLabel: string = this.statusCart ? '' : 'fechar-';
    this.cro.genericGtm('clique:link',`${toggleGtmLabel}carrinho`,'geral')
    if (this.statusCart === false) {
      this.fechouCarrinho$.next();
    }
    if (this.mediaQuery.matches) {
      const el: HTMLElement = document.getElementById('header');
      if (el) {
        el.scrollIntoView();
      }
    }
    let indexContainer = 0;
    const listItemContainer = document.getElementsByClassName('container');
    for (
      indexContainer = 0;
      indexContainer < listItemContainer.length;
      ++indexContainer
    ) {
      if (
        listItemContainer[indexContainer].className.indexOf('container-cart') >
        0
      ) {
        listItemContainer[indexContainer].classList.remove('container-cart');
      } else {
        listItemContainer[indexContainer].classList.add('container-cart');
      }
    }
  }

  receiverStatusCart(status): void {
    this.statusCart = status;
  }

  validateProduct(tipo: string): void {
    switch (tipo) {
      case 'fone':
        if (this.parser.cart.foneId === 0) {
          delete this.parser.cart.foneId;
          this.utils.storage.removeItem('foneId');
        }
        break;
      case 'tv':
        if (this.parser.cart.tvId === 0) {
          delete this.parser.cart.tvId;
          this.utils.storage.removeItem('tvId');
        }
        break;
      case 'celular':
        if (this.parser.cart.celularId === 0) {
          delete this.parser.cart.celularId;
          this.utils.storage.removeItem('celularId');
        }
        break;
      case 'internet':
        if (this.parser.cart.internetId === 0) {
          delete this.parser.cart.internetId;
          this.utils.storage.removeItem('internetId');
        }
        break;
    }
  }

  gravaCarrinho(tecnologia: string): void {
    const arrayProdutos = [];
    const produtos: { [k: string]: string } = {};
    const adicionais = this.utils.storage.getItem('additionalIds');
    const subprodutos = this.utils.storage.getItem('subprodutosIds');
    const dependentesCel = this.utils.storage.getItem(
      'cookieCelularDependentes'
    );

    arrayProdutos.push(['internetId', this.parser.cart.internetId]);
    arrayProdutos.push(['tvId', this.parser.cart.tvId]);
    arrayProdutos.push(['foneId', this.parser.cart.foneId]);
    arrayProdutos.push(['celularId', this.parser.cart.celularId]);

    arrayProdutos.forEach((produto) => {
      const nome = produto[0];
      const valor = produto[1];
      if (valor && nome !== 'adicionais' && nome !== 'subprodutos') {
        produtos[nome] = valor;
      }
    });

    if (adicionais) {
      produtos.adicionais = adicionais;
    }
    if (subprodutos) {
      produtos.subprodutos = subprodutos;
    }
    if (dependentesCel) {
      produtos.dependentesCelular = dependentesCel;
    }

    this.stateManagerService.setProdutosCarrinho(tecnologia, produtos);
  }

  setisSingle(): void {
    this.isSingle = this.parser.cart.selection?.celular && Object.keys(this.parser.cart.selection).length === 1;
  }

  getListFromToPlansByCities(city: string, useDefaultPromotions: boolean): Observable<PromoVitrineConfig> {
    return this.http.get(`${environment.apiPromotions}${city}&useDefaultPromotions=${useDefaultPromotions}`);
  }

  checkPlanUpdateRules(action: string, productCategory: string, productId?: number): void {
    const city = this.assine.jsonCity;
    const fidelity = this.utils.getLoyaltyStatusFromStorage();
    const useDefaultPromotions = this.storyBlokService.getUseDefaultPromotion();

    if (this.requestStatusListsFromToPlans === 'not requested' && !!city && fidelity) {
      this.requestStatusListsFromToPlans = 'requesting';
      this.getListFromToPlansByCities(city, useDefaultPromotions).subscribe(
        resp => {
          this.listFromToPlans = resp;

          this.resquestsListFromToPlans.push([action, productCategory, productId]);
          this.uniqArray(this.resquestsListFromToPlans).forEach(item => {
            action === 'add' ? this.checkProductUpdate() : this.checkRemovalOfUpdatedProducts(item);
          });

          this.requestStatusListsFromToPlans = 'requested';
        },
        err => console.log(err)
      );
    } else if (this.requestStatusListsFromToPlans === 'requested' && !!city && fidelity) {
      const item = [action, productCategory, productId];
      action === 'add' ? this.checkProductUpdate() : this.checkRemovalOfUpdatedProducts(item);
    } else if (!!city && fidelity) {
      this.resquestsListFromToPlans.push([action, productCategory, productId]);
    }
  }

  uniqArray(a): [][] {
    const seen = {};
    return a.filter((item) => {
      return seen.hasOwnProperty(item) ? false : (seen[item] = true);
    });
  }

  checkProductUpdate(): void {
    const selection = this.getCart().selection;
    for (const product in selection) {
      if (
        product && ['tv', 'internet', 'celular', 'fone'].find(prd => prd === product) &&
        !!this.listFromToPlans
      ) {
        this.utils.storageRemoveItem(`${product}UpdatedRule`);
        sessionStorage.removeItem(`${product}PromoApplied`);

        // Registra as adicionadas manualmentes, quando o cliente seleciona os planos
        const productRules = Object.values(this.listFromToPlans).filter(
          rule =>
            rule.tipo === product &&
            rule.de === selection[product].id.toString() &&
            selection[rule.combinacao]
        );
        
        productRules.forEach(rule => {
          this.utils.storageSetItem(
            `${product}UpdatedRule`,
            JSON.stringify({ tipo: rule.tipo, de: rule.de, para: rule.para, combinacao: rule.combinacao })
          );

          sessionStorage.setItem(`${product}PromoApplied`, 'true');

          if (rule['cidades'] === 'Default') {
            if ((rule['de'].includes(selection[rule['tipo']].id.toString())) &&
              (rule['ids']?.split(", ").map(id => id.trim()).includes(selection[rule['combinacao']].id.toString()))) {
                this.comprarProduto({
                  id: Number(rule.para),
                  tipo: rule.tipo,
                  cookie: false,
                  showAlert: false,
                  shouldEmitEvent: false,
                });
            }
          } else {
              this.comprarProduto({
                id: Number(rule.para),
                tipo: rule.tipo,
                cookie: false,
                showAlert: false,
                shouldEmitEvent: false,
              });
          }
        });

        // registra regras de combinações feitas automaticamentes através dos combos ou urls montadas
        const rulesAppliedAutomatically = Object.values(this.listFromToPlans).filter(
          rule =>
            rule.tipo === product &&
            rule.para === selection[product].id.toString() &&
            selection[rule.combinacao] &&
            selection[product].exibir === 0
        );
        rulesAppliedAutomatically.forEach(rule => {
          this.utils.storageSetItem(
            `${product}UpdatedRule`,
            JSON.stringify({ tipo: rule.tipo, de: rule.de, para: rule.para, combinacao: rule.combinacao })
          );
          sessionStorage.setItem(`${product}PromoApplied`, 'true');
        });
      }
    }
  }

  checkRemovalOfUpdatedProducts(item): void {
    const removedPlanCategory = item[1];
    this.utils.storageRemoveItem(`${removedPlanCategory}UpdatedRule`);
    sessionStorage.removeItem(`${removedPlanCategory}PromoApplied`);

    const categories = ['internet', 'tv', 'celular', 'fone'];

    categories.forEach(category => {
      if (category !== removedPlanCategory) {
        const storage = this.utils.storageGetItem(`${category}UpdatedRule`);
        const parseStorage = !!storage && JSON.parse(storage);

        if (!!parseStorage && parseStorage.combinacao === removedPlanCategory) {
          this.utils.storageRemoveItem(`${category}UpdatedRule`);
          sessionStorage.removeItem(`${category}PromoApplied`);

          this.comprarProduto({
            id: Number(parseStorage.de),
            tipo: parseStorage.tipo,
            cookie: false,
            showAlert: false,
            shouldEmitEvent: false,
          });
        }
      }
    });
  }

  RemovalAllOfUpdatedProducts(): void {
    const categories = ['internet', 'tv', 'celular', 'fone'];

    categories.forEach(category => {
      const storage = this.utils.storageGetItem(`${category}UpdatedRule`);
      const parseStorage = !!storage && JSON.parse(storage);

      if (!!parseStorage) {
        this.utils.storageRemoveItem(`${category}UpdatedRule`);
        sessionStorage.removeItem(`${category}PromoApplied`);
        this.comprarProduto({
          id: Number(parseStorage.de),
          tipo: parseStorage.tipo,
          cookie: false,
          showAlert: false,
          shouldEmitEvent: false,
        });
      }
    });
  }

  CheckPlanClaroMobileCustomeInLoyaltyChange(): void {
    const loyaltyStorage = this.utils.storageGetItem('loyalty');
    const clienteClaroMovel = this.utils.storageGetItem('cliente-claro-movel');
    const identificacaoClienteMovelDataStorage = this.utils.storageGetItem('identificacao-cliente-movel-data');
    const identificacaoClienteMovelData = identificacaoClienteMovelDataStorage && JSON.parse(identificacaoClienteMovelDataStorage);

    if (clienteClaroMovel === 'true' && identificacaoClienteMovelData) {
      const bannersInfo = this.bannerPromocionalService.getBannersInfo();

      if(!!bannersInfo) {
        bannersInfo.forEach(bannerInfo =>{
          const promoType = bannerInfo.promoCategory;
          const promoId = Number(bannerInfo.promoId);
          const noFidelity = Number(bannerInfo.noLoyalty);

          if (promoType && promoId && noFidelity) {
            const currentProductInCart = this.parser.cart.selection && this.parser.cart.selection[promoType];
            const internetUpdatedRuleStore = this.utils.storageGetItem('internetUpdatedRule');
            const internetUpdatedRule = internetUpdatedRuleStore && JSON.parse(internetUpdatedRuleStore);

            if (currentProductInCart) {
              if (loyaltyStorage === 'false' && currentProductInCart.id === promoId) {
                this.comprarProduto({ tipo: promoType, id: noFidelity });
              } else if (loyaltyStorage === 'true' && currentProductInCart.id === noFidelity) {
                this.comprarProduto({ tipo: promoType, id: promoId });
                this.utils.storageSetItem('claro-movel-promo-applied', 'true');
              } else if (
                loyaltyStorage === 'true' &&
                (internetUpdatedRule && internetUpdatedRule.de === noFidelity) &&
                internetUpdatedRule.tipo === promoType
              ) {
                this.comprarProduto({ tipo: promoType, id: promoId });
              } else if (
                loyaltyStorage === 'false' &&
                (internetUpdatedRule && internetUpdatedRule.de === promoId) &&
                internetUpdatedRule.tipo === promoType
              ) {
                this.comprarProduto({ tipo: promoType, id: noFidelity });
              }
            }
          }
        })
      } else {
        console.warn('CheckPlanClaroMobileCustomeInLoyaltyChange: Não foi possível encontrar informações do banner promocional')
      }
    }
  }

  getIsSingleAppClaroTvPlus(): boolean {
    const tvTag = this.parser.cart.selection?.tv?.tags;
    const qtdProducts = this.getProductNumber();
    return qtdProducts === 1 && Array.isArray(tvTag) && tvTag.indexOf('claroapp') >= 0;
  }

  getProductNumber(): number {
    let qtd = 0;
    const products = ['internetId', 'tvId', 'celularId', 'foneId'];
    products.forEach((product) => { if (this.assine.parser.cart[product] > 0) { qtd++; } });
    this.utils.storage.cookieService.set('quantidadeCarrinho', qtd);
    return qtd;
  }

  // Foi criada essa função para substituir a acima 
  // getProductNumber(), porém precisamos rever todo o fluxo
  setQuantidadeCarrinhoDCC() {
    this.quantidaCarrinhoDCC = 0;
    const products = ['internetId', 'tvId', 'celularId', 'foneId'];
    products.forEach((product) => { 
      if (this.assine.parser.cart[product] > 0) { 
        this.quantidaCarrinhoDCC++; 
      }
    });
    sessionStorage.setItem('quantidaCarrinhoDCC', String(this.quantidaCarrinhoDCC));
  }

  getQuantidadeCarrinhoDCC() {
    const qtd = (Number(sessionStorage.getItem('quantidaCarrinhoDCC')) || 0)
    return qtd; 
  }

  /**
   * This method verifies if the cart is empty and, if not, checks if the purchase is a single mobile phone.
   * If so, it retrieves the mobile phone ID from the cart, obtains the journey mobile URL with that ID,
   * and navigates to that URL. If the purchase is not a single mobile phone, it navigates to the next step.
   */
  continuePurchase(): void {
    const isCartEmpty = this.getProductNumber() < 1;
    const isSingleMovel = this.resolveIsSingleMovel();
    const isAppSingle = this.resolveAppSingle();

    if (isCartEmpty) { return }

    if (isSingleMovel) {
      const celularId = this.getCelularIdFromCart();
      const urlJornadaMovel = this.getJornadaMovelUrlById(celularId);
      if (!!urlJornadaMovel) {
        this.navigateTo(urlJornadaMovel);
        return;
      }
    }


    if (isAppSingle) {    
      this.getAppTvGroup()
      if (!sessionStorage.getItem('technology') || sessionStorage.getItem('technology') === 'dth') {
        sessionStorage.removeItem('city');
      }
      this.navigateTo(environment.jornadaNova + `checkout-tv`);
      return
    }

    this.cro.genericGtm('clique:botao','preencher-dados','carrinho');
    this.goTonext();
  }


  getAppTvGroup(){
    let tvIdGroup = sessionStorage.getItem('tvIdGroup');
      if (tvIdGroup) {
        try { 
          const tvIdGroups = JSON.parse(tvIdGroup);   
          const selectedTvId = this.parser.cart.selection?.tv?.id;
          if (selectedTvId) {
            const tvIds = tvIdGroups.find(group => group.includes(selectedTvId));

            if (tvIds) {
              sessionStorage.setItem('tvId', JSON.stringify(tvIds));
            } else {
              console.warn('ID selecionado não encontrado em nenhum grupo.');
            }
          } else {
            console.error('ID selecionado é inválido ou indefinido.');
          }
        } catch (error) {
          console.error('Erro ao processar tvIdGroup: ', error);
        }
      } else {
        console.error('tvIdGroup não encontrado no sessionStorage.');
      }
  }

  resolveAppSingle() {
    let appTvIds = this.storyBlokService.getAppIds();
    const isAppTv = appTvIds ? appTvIds.includes(this.parser.cart.selection?.tv?.id) : false;
    const isTvSingle = this.parser?.cart.selection?.tv && Object.keys(this.parser.cart.selection).length === 1;    

    return isAppTv && isTvSingle;
  }

  getCelularIdFromCart() {
    return this.assine.parser.cart.selection.celular.id;
  }

  getJornadaMovelUrlById(inCartCelularId: number): string {
    const jornadaMovel = this.movelProducts.find(product => product.id === inCartCelularId);
    const loyalty = this.utils.getLoyaltyStatusFromStorage();

    if (!loyalty) {
      jornadaMovel.url = jornadaMovel.url.replace('loyalty=true', 'loyalty=false');
    } else {
      jornadaMovel.url = jornadaMovel.url.replace('loyalty=false', 'loyalty=true');
    }

    return jornadaMovel ? jornadaMovel.url : "";
  }

  navigateTo(url: string) {
    window.location.href = url;
  }

  resolveIsSingleMovel(): boolean {
    const isSingleMovel = this.parser.cart.selection?.celular && Object.keys(this.parser.cart.selection).length === 1;
    return isSingleMovel;
  }

  goTonext(): void {
    this.cro.dimensaoPaginas();
    this.utils.lastRouteAdicionais = '';
    this.navigationService.sendToNext();
    this.statusCart = false;
  }

  public openCartOnMobile(): void {
    let qtd = 0;
    const products = ['internetId', 'tvId', 'celularId', 'foneId'];

    products.forEach((product) => { if (this.assine.parser?.cart[product] > 0) { qtd++; } });

    let hasProduct = qtd > 0 ? true : false;

    if (window.screen.width <= 1199 && hasProduct) {
      this.statusCart = false;
      this.toggleCart();
    }

    if (window.screen.width <= 1199 && !hasProduct) {
      this.statusCart = true;
      this.toggleCart();
    }
  }

  setProductIncentivo(value: any) {
    this.productIncentivo = value;
  }

  checkDirectDebit() {
    if (sessionStorage.getItem('directDebit') === 'false') {
      const directDebit = !(sessionStorage.getItem('directDebit') === 'false');      
      this.recalque('dcc', directDebit);
    }
  }

  checkDigitalInvoice() {
    if (this.utils.storageGetItem('digitalInvoice') === 'false') {
      this.recalque('digitalInvoice', false);
    }
  }

  hasPrePagoTagProductsInCart(): boolean {    
    return this.hasSomeTagProductsInCart(CarrinhoService.MULTI_OFERTA_PRE_PAGO);
  }

  hasMultiProductsInCart(): boolean {
    return this.hasSomeTagProductsInCart(CarrinhoService.MULTI_OFERTA);
  }

  hasSomeTagProductsInCart(tag: string): boolean {
    if (this.assine.parser.cart.selection) {
      const types = ['internet', 'tv', 'fone', 'celular'];
      for (const item of types) {
        if (this.assine.parser.cart.selection[item]) {
          if (this.assine.parser.cart.selection[item].tags && this.assine.parser.cart.selection[item].tags.some((x) => x.includes(tag))) {
            return true;
          }
        }
      }
    }
    return false;
  }

  hasMultiProductInCartByType(type: string): boolean {
    if (this.assine.parser.cart.selection) {
      const types = ['internet', 'tv', 'fone', 'celular'];
      for (const item of types) {
        if (this.assine.parser.cart.selection[item] && type === item) {
          if (this.assine.parser.cart.selection[item].tags && this.assine.parser.cart.selection[item].tags.some((x) => x.includes(CarrinhoService.MULTI_OFERTA))) {
            return true;
          }
        }
      }
    }
    return false;
  }
  removerProdutoMultiOferta(): void {
    if (this.hasMultiProductsInCart()) {
      const types = ['internet', 'tv', 'fone', 'celular'];
      for (const item of types) {
        if (this.assine.parser.cart.selection[item]) {
          if (this.assine.parser.cart.selection[item].tags && this.assine.parser.cart.selection[item].tags.some((x) => x.includes(CarrinhoService.MULTI_OFERTA))) {
            this.removerProduto({ tipo: item });
          }
        }
      }
    }
  }

  valorTotalProdutosMultiOferta(): number {
    let total = 0;
    if (this.hasMultiProductsInCart()) {
      const types = ['internet', 'tv', 'fone', 'celular'];
      for (const item of types) {
        if (this.assine.parser.cart.selection[item]) {
          if (this.assine.parser.cart.selection[item].tags && this.assine.parser.cart.selection[item].tags.some((x) => x.includes(CarrinhoService.MULTI_OFERTA))) {
            total += this.assine.parser.cart.selection[item].preco;
          }
        }
      }
    }
    return total;
  }
}
