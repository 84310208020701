import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LeadForScheduleInterface } from '../shared/interface';

@Injectable({
  providedIn: 'root'
})
export class DateService {
  static event = new EventEmitter<object>();
  public monthNames = [
    'january', 'february', 'march', 'april', 'may', 'june',
    'july', 'august', 'september', 'october', 'november', 'december'
  ];
  constructor(private http: HttpClient) { }
  
  recovery(lead: LeadForScheduleInterface, fingerprint_: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'x-fingerprint': fingerprint_
      }),
    };

    const apiURL = environment.coberturaSchedule;

    const newLead = {...lead}

    if (!newLead.homePass) {
      newLead.homePass = "0"
    }

    return this.http.post(apiURL, newLead, httpOptions).toPromise();
  }


  checkZero(today?: string | number){
    if(today.toString().length < 2) {
      return '0' + today;
    } else {
      return today;
    }
  }

  formatDayValue(date?: string): string | number{
    let year;
    let month;
    let day;

    if (date) {
      const dataNew = date.split('-');
      year  = Number(dataNew[0]);
      month = Number(dataNew[1]);
      day   = Number(dataNew[2]);
    }

    const tempDate = date ? new Date(year, month - 1, day) : new Date();
    const tempDay = this.checkZero(tempDate.getDate());

    return tempDay;
  }

  nameMonthValue(date?: string){
    let tempDate;

    if (date) {
      const splitDate = date.split('-');
      const tempYear = Number(splitDate[0]);
      const temp2Month = Number(splitDate[1]) - 1;
      const tempDay = Number(splitDate[2]);
      tempDate = new Date(tempYear, temp2Month, tempDay);
    } else {
      tempDate = new Date();
    }

    const tempMonth = this.monthNames[tempDate.getMonth()];

    return tempMonth;
  }

  formatYearValue(date?: string){
    const tempDate = date ? new Date(date) : new Date();
    const tempYear = this.checkZero(tempDate.getFullYear());
    return tempYear;
  }

  formatDateValue(date?: string | Date){
    const tempDate = date ? new Date(date) : new Date();
    const tempFullYear = tempDate.getFullYear();
    const tempMonth = this.checkZero(tempDate.getMonth() + 1);
    const tempDay = this.checkZero(tempDate.getDate());
    return tempFullYear + '-' + tempMonth + '-' + tempDay;
  }

  convertDateBarToDash(date?: string){
    try {
      if (date && date.indexOf('/') > 0) {
        const dateSplited = date.split('/');
        const dateConcated = dateSplited[2] + '-' + this.checkZero(dateSplited[1]) + '-' + this.checkZero(dateSplited[0]);
        return dateConcated;
      } else {
        return this.formatDateValue();
      }
    } catch (error) {
      return 'Invalid date';
    }
  }

  getDefaultDates() {
    const daysOfYear = [];
    const now = new Date();
    now.setDate(now.getDate() + 1);
    const dateAtFront = new Date();
    dateAtFront.setDate(dateAtFront.getDate() + 15);
    for (now; now <= dateAtFront; now.setDate(now.getDate() + 1)) {
      if (now.getDay() !== 0) {
       daysOfYear.push(this.formatDateValue(now));
      }
    }
    return daysOfYear;
  }

  getAvailableDatesToCalendar(date?: any, showPeriod?: any) {
    const calendarMorning: any = [];
    const calendarAfternoom: any = [];
    const calendarFull: any = [];
    let oldDate = '';
    date.dates.forEach(element => {
      switch (element.periodTypeId) {
        case 1:
          calendarMorning.push(this.formatDateValue(element.startDate));
          break;
        case 2:
          calendarAfternoom.push(this.formatDateValue(element.startDate));
          break;
      }
      if (element.startDate !== oldDate) {
        calendarFull.push(this.formatDateValue(element.startDate));
        oldDate = element.startDate;
      }
    });
    switch (showPeriod) {
      case 'manha':
        if (calendarMorning.length > 0) {
          return calendarMorning;
        } else {
          return this.getDefaultDates();
        }
      case 'tarde':
        if (calendarAfternoom.length > 0) {
          return calendarAfternoom;
        } else {
          return this.getDefaultDates();
        }
      default:
        return calendarFull;
    }
  }

}
