import { Injectable } from '@angular/core';
import { MobileClientValidationService, stepsNamesEnum } from '../service/mobile-client-validation/mobile-client-validation.service';
import { MobileInfoService } from '../service/apis/mobile-info/mobile-info.service';
import { LoadingService } from 'src/app/shared/loading/loading.service';
import { PushService } from './push.service';
import { UtilsService } from 'src/app/services/utils.service';
import { CarrinhoService } from 'src/app/services/carrinho.service';
import { PortabilidadeService } from 'src/app/services/portabilidade.service';
import { CroService } from 'src/app/services/cro.service';
import { MdnModalService } from 'src/app/shared/mondrian-components/mdn-modal/mdn-modal.service';
import { JaEClienteComponent } from 'src/app/modules/checkout/modals/ja-e-cliente/ja-e-cliente.component';
import { Router } from '@angular/router';
import { FingerprintService } from 'src/app/services/fingerprint.service';
import { IdentificationsService } from '../../../../services/identifications.service';

@Injectable({
  providedIn: 'root'
})
export class OriginIdService {
  

  constructor(
    private router: Router,
    private service: MobileClientValidationService,
    private mobileInfoService: MobileInfoService,
    private loader: LoadingService,
    private carrinho: CarrinhoService,
    private pushService: PushService,
    private utils: UtilsService,
    private portabilidadeService: PortabilidadeService,
    private cro: CroService,
    private modal: MdnModalService,
    public fingerprintService: FingerprintService,
    public identificationsService: IdentificationsService
  ) { }

  flowOriginId() {
    this.service.setMatch(false);

    const cpf    = this.service.getCpf();
    const msisdn = this.service.getMsisdn();
    const code   = this.service.getCode();
    const token  = this.service.getToken();

    // TODO ligar o loading do input

    this.getNewCustomerMobileInfo(cpf, msisdn, code, token)
  }

  getNewCustomerMobileInfo(cpf: string, msisdn: string, code: string, token: string) {

    this.mobileInfoService.getNewCustomerMobileInfo(cpf, msisdn, code, token).subscribe(
      resp => {
        // TODO desligar o loafing do input 

        const result: {isClienteMovel: boolean, match: boolean} = this.resolveCustomerForOriginId(resp.data);

        const isClienteMovel = result.isClienteMovel;
        const match          = result.match;

        if (isClienteMovel) {
          this.service.setMatch(match);
          this.service.isClienteMovelChanged$.next(true);
    
          this.loader.show({
            type: "default",
            title: "Aguarde...",
            description: "Verificando se você possui um plano de residencial..."
          });

          this.resolveBaseCustumer(cpf);

        } else {
          this.service.setIsClienteMovel(false);
          this.service.setMatch(false);
          this.service.isClienteMovelChanged$.next(false);
          this.service.changeStep$.next(stepsNamesEnum.NOT_MOBILE_CUSTOMER)
        }
      },
      error => {
        this.pushService.resolveClientMobileInfoError(error);
      }
    )
  }

  resolveBaseCustumer(cpf: string) {
    this.fingerprintService.getFingerprint().subscribe(
      (fingerprint_: string) => {
        this.identificationsService.getResidentialInfo(cpf, fingerprint_).subscribe(
          (customerResp) => {
            const userStatus = customerResp.data.data.data.status;
            const match = this.service.getMatch();

            if (match) {
              const celularCart = this.carrinho.getCart()?.selection?.celular;

              if (celularCart) {
                const celular = this.service.getMsisdn()
                const portabilidadeObj = {
                  area: celular.substring(0, 2),
                  carrier: 'claro',
                  number: celular.substring(2),
                  productId: celularCart.id,
                  valid: true
                };
  
                this.utils.storage.setItem('cookiePortabilityCelular', JSON.stringify(portabilidadeObj));
                this.portabilidadeService.portabilidadeReady$.next(true);
  
                this.service.setVerifiedMobileCustomerByOriginid(true);
                this.carrinho.carrinhoParserReady$.next();
              }
            } else {
              this.service.setVerifiedMobileCustomerByOriginid(false);
              this.carrinho.limparCarrinho()
            }

            this.loader.hide();
            this.service.closeDialog$.next();

            if (userStatus === 'CONECTADO') {
              this.loader.hide();
              this.service.closeDialog$.next();
              this.abrirDialogClienteConectadoMobileClient();
            }

          },
          (error) => {
            this.service.changeStep$.next(stepsNamesEnum.API_ERROR)
          }
        )
      },
      (error) => {
        this.service.changeStep$.next(stepsNamesEnum.API_ERROR)
      }
    );
  }

  abrirDialogClienteConectadoMobileClient(): void {
    this.cro.openModal(`ja-e-cliente`);
    const modalConfig = {
      data: {
        size: 'sm',
        titulo: 'Importante!',
        disableClose: true,
        origin: 'cliente-movel',
        flow: 'verified-mobile-customer-by-originid',
        modalAction: '',
        type: 'ja-e-cliente'
      }
    };
    this.modal.open(JaEClienteComponent, modalConfig);
  }

  resolveCustomerForOriginId(data: any): any {
    this.service.setCustomerPlanData(data);
    this.service.setCustumerName(data.customerName);

    const situation       = data.situation;
    const custumerType    = data.customerType;
    const isClienteMovel_ = situation === 'ATIVO' && custumerType === 'owner'

    this.service.setIsClienteMovel(isClienteMovel_);

    if (isClienteMovel_) {
      this.service.setShouldShowAlert(false)
      this.service.setVerifiedMobileCustomerByOriginid(true)
    } else {
      this.cro.setError(this.router.url, 'plano-inativo');
    }

    this.service.customerValidated$.next();

    return {
      isClienteMovel: isClienteMovel_,
      match: isClienteMovel_
    }
  }
}

