import { AfterViewInit, Component, Input, Output, EventEmitter } from '@angular/core';
import swiperFn from 'mondrian-vanilla/core/components/Swiper/swiper.js';
import { SwiperBannerConfig } from '../../../models/swiper-banner-config.model';

@Component({
  selector: 'app-swiper-banner',
  templateUrl: './swiper-banner.component.html',
  styleUrls: ['./swiper-banner.component.scss'],
})
export class SwiperBannerComponent implements AfterViewInit {
  @Input() swiperBannerConfig!: SwiperBannerConfig;
  @Output() onClickBanner = new EventEmitter<any>();

  constructor() {}

  ngAfterViewInit(): void {
    swiperFn(document.getElementById('swiper-banner'));
  }

  emitOnClickBanner(index: number): void{
    this.onClickBanner.emit(index);
  }
}
