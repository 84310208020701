import { Injectable } from '@angular/core';
import { element } from 'protractor';

@Injectable({
  providedIn: 'root'
})
export class IncentivoCelularService {
  public idIncentivoCelular: any;
  public internetSelecionadaId: any;

  constructor() { }

  public getTagIncentivo(produto: any) {

    const incentivoArray = produto && produto.tags && produto.tags.length > 0 ?
      produto.tags.filter((element) => element.includes('incentivo')) :
      [];
    this.idIncentivoCelular = incentivoArray.length > 0 ?
      parseInt(incentivoArray[incentivoArray.length - 1].replace('incentivo_', '')) :
      undefined;
    this.internetSelecionadaId = this.idIncentivoCelular ? produto.id : undefined;

  }

  public getIndividualPrice(produtos: any, id: any) {

    const productIndividual = produtos.filter((element) => element.id === id);
    return productIndividual.length > 0 ? productIndividual[0] : {};

  }

  setIdIncentivoCelular(value: any): void {
    this.idIncentivoCelular = value;
  }

  setInternetSelecionadaId(value: any): void {
    this.internetSelecionadaId = value;
  }

}
