import { Injectable } from '@angular/core';
import { importPKCS8, SignJWT } from 'jose';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})

export class JwtService {
  constructor() {}

  async objectToSignedJwt(object: any): Promise<string> {
    const secretKey = await importPKCS8(environment.jwtPrivateKey, 'RS256');
    const token = await new SignJWT(object)
        .setProtectedHeader({ alg: 'RS256' })
        .sign(secretKey);
    return token
  }
}