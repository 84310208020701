import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubprodutoSelecionadoService {

  public subprodutoSelecionadoAtualizado$: Subject<any> = new Subject<any>();

  constructor() { }

  subprodutosSeleciadosAtualizados(obj: any) {
    this.subprodutoSelecionadoAtualizado$.next(obj);
  }
}
