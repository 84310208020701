export class Address {
  constructor(
    public installationType?: string,
    public installationAddress?: string,
    public installationNumber?: string,
    public installationDistrict?: string,
    public installationComplement?: string,
    public installationComplementOther?: string,
    public installationReference?: string,
    public installationCity?: string,
    public installationState?: string,
    public installationZipcode?: string,
    public scheduleDate1?: any,
    public scheduleDate2?: any,
    public schedulePeriod1?: any,
    public schedulePeriod2?: any,
    public goToNext?: boolean,
    public origin?: string,
    public homePass?: number
  ) {
  }
}
