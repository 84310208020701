import { Injectable, EventEmitter } from '@angular/core';

import { DateService } from './date.service';

import { LeadInterface } from '../shared/interface';
import { UtilsService } from './utils.service';

import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CarrinhoService } from './carrinho.service';
import { AssineJaParserService } from './assine-ja-parser.service';
import { Subscription } from 'rxjs';
import { EnderecoService } from '../modules/checkout/services/endereco.service';
import { CroFormVersionsService} from './cro-form-versions.service';
import { JwtService } from './jwt.service';
import { FingerprintService } from './fingerprint.service';

class Option {
  id: number;
  name: string;
  price: number;
  categoryId?: string;
}

interface SubProduct {
  id: number;
  name: string;
  price: number;
  categoryId: string;
  deal?: SubProductDeal;
}

interface SubProductDeal {
  description: string;
  periods: object[];
}

@Injectable({
  providedIn: 'root',
})
export class LeadService {
  static changeMailEvent = new EventEmitter<object>();
  static event = new EventEmitter<object>();
  public dados: LeadInterface;
  private mediaChannel: string;
  public salesChannel: string;
  public catalog: string;
  public sameAddress = false;
  leadProducts: any;
  public checkedWhatsApp: any;
  public checkedTermoCelular: any;
  public ctrlCarrinhoParser: Subscription;
  public ctrlLimparCarrinho: Subscription;
  public dateNow = new Date();
  public hasLead: boolean = false;
  public pathContract: any;
  public originId: string;

  constructor(
    private http: HttpClient,
    public utils: UtilsService,
    public cart: CarrinhoService,
    public assine: AssineJaParserService,
    public dateService: DateService,
    public enderecoService: EnderecoService,
    public croFormVersionsService: CroFormVersionsService,
    public fingerprintService: FingerprintService,
    public jwtService: JwtService
  ) {
    this.verifyCatalog();
    this.mediaChannel = this.utils.isDesktop() ? 'desktop' : 'mobile';
    this.salesChannel =
      this.utils.storageGetItem('salesChannel') || environment.company;
      this.fingerprintService.getFingerprint().subscribe(
        (fingerprint_: string) => {
          this.recovery(fingerprint_);
        },
        (error => {
          console.error(error)
        })
      )
    
    this.subscribeClearCartEvent();

    this.ctrlCarrinhoParser = this.cart.carrinhoParserReady$.subscribe(
      (removed?) => {
        if (removed !== undefined) {
          this.verifyCatalog();
          // this.send({});
        }
      }
    );

    this.croFormVersionsService.sendLeadForCroTest$.subscribe(() => {
      this.fingerprintService.getFingerprint().subscribe(
        (fingerprint_: string) => {
          this.send({}, fingerprint_);
        },
        (error => {
          console.error(error)
        })
      )
    })
  }

  subscribeClearCartEvent(): void {
    this.ctrlLimparCarrinho = this.cart.limparCarrinho$.subscribe(() => {
      this.clearBundle();
    });
  }

  clearBundle(): void {
    if (this.dados.bundle) {
      this.dados.bundle = {};
    }

    if (this.dados.products) {
      this.dados.products = [];
    }
  }

  verifyCatalog() {
    this.catalog = this.utils.storage.getItem('catalog');
    this.originId = this.utils.storage.getItem('originId');
  }

  isBoxTv() {
    const products = this.getProducts();
    const cartIsEmpty = products.length === 0;
    const isSingle = products.length === 1;

    if (cartIsEmpty) { return false }

    if (isSingle) {
      if (products[0].type === 'celular') {
        return true;
      };

      const { tags } = products[0];
      if (!tags) { return false }

      const hasClaroBoxInCart = tags.indexOf('clarobox') >= 0;
      if (hasClaroBoxInCart) { return true }
    }

    const filteredProducts = products.filter(product => {
      if (product.type !== 'celular') {
        const { tags } = product;
        if (!tags) {
          return 'NoTagDefined';
        }
      }

      const isClaroBox = product.tags?.indexOf('clarobox') >= 0;
      const isCelular = product.type === 'celular';
      if (!(isClaroBox || isCelular)) {
        return product;
      }
    });

    const thereIsNoTagDefined = filteredProducts.indexOf('NoTagDefined') >= 0;
    if (thereIsNoTagDefined) { return false; }

    const productInvalidateBoxTv = filteredProducts.length > 0;
    if (productInvalidateBoxTv) { return false; }

    return true;
  }

  isMobileSingle(): boolean {
    const products = this.getProducts();

    if (products.length === 1 && products[0].type === 'celular') {
      return true;
    }

    return false;
  }

  isSingle() {
    const products = this.getProducts();
    let isCalendarVisible =
      products && products.length === 1 && products[0].type === 'celular';
    isCalendarVisible =
      products &&
        products.length === 1 &&
        (products[0].type === 'tv' || products[0].type === 'internet' || products[0].type === 'fone') &&
        products[0].tags &&
        products[0].tags.indexOf('inibicaoagenda') >= 0
        ? true
        : isCalendarVisible;
    const inibProducts = products.filter((produto) => {
      if (
        produto.type === 'celular' ||
        ((produto.type === 'tv' || produto.type === 'internet') &&
          produto.tags &&
          produto.tags.indexOf('inibicaoagenda') >= 0)
      ) {
        return produto;
      }
    });

    isCalendarVisible =
      products && products.length === 2 && inibProducts.length === 2
        ? true
        : isCalendarVisible;

    return isCalendarVisible;
  }

  compareArrays(array1, array2) {
    if (
      array1.length === array2.length &&
      array1.sort().every(function (value, index) {
        return value === array2.sort()[index];
      })
    ) {
      return true;
    } else {
      return false;
    }
  }

  send(dataLead: any, fingerprint: string) {
    return new Promise(async (resolve, reject) => {
      const affiliateId = this.utils.storage.getItem('affiliateId');
      const affiliateUserId = this.utils.storage.getItem('affiliateUserId');
      const regionId = this.utils.storage.getItem('regionId');

      if (affiliateId !== '') {
        this.dados.affiliateId = affiliateId;
      }
      if (affiliateUserId !== '') {
        this.dados.affiliateUserId = affiliateUserId;
      }
      this.dados.regionId = regionId ? regionId : '';

      this.updateBundleOrProductsOnLead();

      this.salesChannel =
        this.utils.storageGetItem('salesChannel') || environment.company;

      if (
        this.utils.storageGetItem('salesChannel') &&
        this.utils.storageGetItem('salesChannel') === 'landingrural' &&
        this.utils.storageGetItem('landingrural')
      ) {
        const pordutoLandingRural = JSON.parse(
          this.utils.storageGetItem('landingrural')
        );
        const array1 = [];
        const array2 = [];

        pordutoLandingRural.forEach((element) => {
          array1.push(JSON.stringify(element));
        });

        this.dados.products.forEach((element) => {
          array2.push(
            JSON.stringify({ [element.type + 'Id']: element.id.toString() })
          );
        });

        const isLandingRural = this.compareArrays(array1, array2);

        this.salesChannel = isLandingRural
          ? 'landingrural'
          : environment.company;
      }

      this.dados.mediaChannel = this.mediaChannel;
      this.dados.salesChannel = this.salesChannel;

      this.verifyCatalog();
      this.dados.catalog = this.assine.getLeadCatalog();
      if (this.originId !== '') {
        this.dados.originId = this.originId;
      }

      if (!this.dados.payment) {
        this.dados.payment = {};
      }
      if (!this.dados.schedule) {
        this.dados.schedule = [];
      }
      if (!this.dados.installationAddress) {
        this.dados.installationAddress = {
          zipcode: this.utils.storage.getItem('cep'),
          number: this.utils.storage.getItem('number'),
          city: this.utils.storage.getItem('city'),
          state: this.utils.storage.getItem('uf'),
          type: dataLead.installationType || 'R',
          address: dataLead.installationAddress,
          complement: dataLead.billingComplement,
          district: dataLead.installationDistrict,
          reference: dataLead.installationReference || '',
        };
      }
      if (this.dados.installationAddress.state === '') {
        delete this.dados.installationAddress.state;
      }

      if (this.dados.bundle) {
        this.pathContract = this.dados.bundle.products;
      } else if (this.dados.products) {
        this.pathContract = this.dados.products;
      }

      const cart = this.cart.getCart();
      if (cart) {
        Object.keys(this.pathContract).forEach((product) => {
          const type = this.pathContract[product].type;
          const payments = cart.selection[type].adesaoParcelas;
          const value = cart.selection[type].adesao;

          this.pathContract[product].hasContract = !!this.cart.assine?.parser.cart.conditions.fidelity;
          this.pathContract[product].registrationFee = {
            value: value || 0,
            inAdvance: !!cart.selection[type].adesaoPrePaga,
            payments: value === 0 ? 0 : payments < Infinity ? payments : 1
          };
        });
      }

      this.dados.installationFee = parseInt(
        this.cart.getCart().installationFee,
        10
      );

      if (dataLead.origin === 'dados-pessoais') {
        LeadService.changeMailEvent.emit(dataLead.email);

        const LANDLINE_LENGTH = 10;
        const PHONE_OBJECT = {
          area: dataLead.phone && dataLead.phone.substr(0, 2),
          number: dataLead.phone && dataLead.phone.substr(2, 10),
        }

        if (dataLead.phone.length > LANDLINE_LENGTH) {
          this.dados.user = {
            name: dataLead.name,
            email: dataLead.email,
            other: dataLead.other || {},
            phone: PHONE_OBJECT,
            additionalPhone: PHONE_OBJECT,
          };
        } else {
          this.dados.user = {
            name: dataLead.name,
            email: dataLead.email,
            other: dataLead.other || {},
            phone: PHONE_OBJECT
          };
        }

        if (
          dataLead.cpf ||
          (dataLead.cpf_cnpj && dataLead.cpf_cnpj.length > 1)
        ) {
          this.dados.user.cpf = this.utils.formatNumbers(dataLead.cpf || dataLead.cpf_cnpj);


          if (dataLead.birthdate && dataLead.birthdate.indexOf('/') > 0) {
            this.dados.user.birthdate = this.dateService.convertDateBarToDash(
              dataLead.birthdate
            );
          } else if (dataLead.birthdate) {
            this.dados.user.birthdate = dataLead.birthdate;
          }

          this.dados.user.rg = dataLead.rg;
          this.dados.user.motherName = dataLead.motherName;
        }

        if (dataLead.other) {
          this.dados.user.other = dataLead.other;
        }
        if (!!this.utils.storageGetItem('cepNoTech')) {
          if (!!this.dados.user.other) {
            this.dados.user.other.cepNoTech =
              this.utils.storageGetItem('cepNoTech');
          } else {
            this.dados.user.other = {
              cepNoTech: this.utils.storageGetItem('cepNoTech'),
            };
          }
        }
        this.utils.storageSetItem('user',JSON.stringify(this.dados.user))
      } else if (dataLead.origin === 'endereco') {
        this.dados.installationAddress = {
          type: dataLead.installationType || 'R',
          address: dataLead.installationAddress,
          number: dataLead.installationNumber,
          district: dataLead.installationDistrict,
          city: dataLead.installationCity,
          state: dataLead.installationState,
          complement: dataLead.installationComplement,
          reference: dataLead.installationReference || '',
          zipcode: dataLead.installationZipcode
            ? dataLead.installationZipcode.replace('-', '')
            : '',
        };
        if (dataLead.homePass) {
          this.dados.homePass = dataLead.homePass;
        }
        if (dataLead.homePass === '') {
          delete this.dados.homePass;
        }
        if (this.isSingle()) {
          dataLead.scheduleDate1 = this.dateService.convertDateBarToDash();
          dataLead.schedulePeriod1 = '1';
        }
        if (dataLead.scheduleDate1 && dataLead.schedulePeriod1) {
          this.dados.schedule[0] = {
            date:
              dataLead.scheduleDate1.indexOf('-') === -1
                ? this.dateService.convertDateBarToDash(dataLead.scheduleDate1)
                : dataLead.scheduleDate1,
            period: dataLead.schedulePeriod1,
          };
        } else {
          delete this.dados.schedule[0];
        }
        if (dataLead.scheduleDate2 && dataLead.schedulePeriod2) {
          this.dados.schedule[1] = {
            date:
              dataLead.scheduleDate2.indexOf('-') === -1
                ? this.dateService.convertDateBarToDash(dataLead.scheduleDate2)
                : dataLead.scheduleDate2,
            period: dataLead.schedulePeriod2,
          };
        } else {
          if (this.dados.schedule.length > 1) {
            this.dados.schedule.splice(1);
          }
        }
      } else if (dataLead.origin === 'pagamento') {
        if (dataLead.paymentDay) {
          this.dados.payment.paymentDay = parseInt(dataLead.paymentDay);
        }
        this.dados.payment.directDebit = dataLead.directDebit;
        if (
          dataLead.directDebit &&
          dataLead.accountNumber &&
          dataLead.accountDigit
        ) {
          this.dados.payment.debitDetails = {
            bankNumber: dataLead.bankNumber,
            agencyNumber: dataLead.agencyNumber,
            accountNumber: dataLead.accountNumber,
            accountDigit: dataLead.accountDigit,
          };
        } else {
          delete this.dados.payment.debitDetails;
        }
        this.dados.payment.electronicInvoicing =
          dataLead.invoiceType === 'email';
        if (!this.sameAddress) {
          if (
            dataLead.billingType &&
            dataLead.billingNumber &&
            dataLead.billingState &&
            dataLead.billingZipcode
          ) {
            this.dados.payment.billingAddress = {
              type: dataLead.billingType || '',
              address: dataLead.billingAddress || '',
              number: dataLead.billingNumber || '',
              district: dataLead.billingDistrict || '',
              city: dataLead.billingCity || '',
              state: dataLead.billingState || '',
              complement: dataLead.billingComplement || '',
              reference: dataLead.billingReference || '',
              zipcode: dataLead.billingZipcode
                ? dataLead.billingZipcode.replace('-', '')
                : '',
            };
          }
        } else {
          delete this.dados.payment.billingAddress;
        }

        if (dataLead.invoiceType === 'email') {
          this.dados.payment.invoiceEmail = dataLead.invoiceEmail;
        }
        if (!!this.checkedWhatsApp) {
          if (!!this.dados.user.other) {
            this.dados.user.other.WhatsApp = 'Sim';
          } else {
            this.dados.user.other = { WhatsApp: 'Sim' };
          }
        } else {
          if (!!this.dados.user.other) {
            this.dados.user.other.WhatsApp = 'Não';
          } else {
            this.dados.user.other = { WhatsApp: 'Não' };
          }
        }

        if (this.utils.storageGetItem('wifiplus360')) {
          if (!!this.dados.user.other) {
            this.dados.user.other.wifiplus360 = 'Sim';
          } else {
            this.dados.user.other = { wifiplus360: 'Sim' };
          }
        } else {
          if (!!this.dados.user.other) {
            this.dados.user.other.wifiplus360 = 'Não';
          } else {
            this.dados.user.other = { wifiplus360: 'Não' };
          }
        }

        if (!!dataLead.other) {
          this.dados.user.other = Object.assign(this.dados.user.other, dataLead.other);
        }

        if (!!this.checkedTermoCelular) {
          if (this.dados && this.dados.products) {
            for (let x = 0; x < this.dados.products.length; x++) {
              if (this.dados.products[x].type === 'celular') {
                const termAccepted = this.checkedTermoCelular
                  ? this.checkedTermoCelular
                  : false;
                this.dados.products[x].termAccepted = termAccepted;
              }
            }
          } else if (this.dados && this.dados.bundle) {
            for (let x = 0; x < this.dados.bundle.length; x++) {
              if (this.dados.bundle[x].type === 'celular') {
                const termAccepted = this.checkedTermoCelular
                  ? this.checkedTermoCelular
                  : false;
                this.dados.bundle[x].termAccepted = termAccepted;
              }
            }
          }
        }
      }
      if (!!dataLead.creditAnalysis) {
        this.dados.creditAnalysis = dataLead.creditAnalysis;
      }
      let { user } = this.dados;
      let productData = !!(
        (this.dados && this.dados.products && this.dados.products.length > 0) ||
        (this.dados && this.dados.bundle)
      );

      const isCroTest = this.croFormVersionsService.isACroFormVersion();

      if (isCroTest) {
        const userStorage = JSON.parse(sessionStorage.getItem('user'));
        if (!!userStorage && !!this.dados.user) {
          user = userStorage;
          this.dados.user = userStorage;
        }

        if (productData === false) {
          const mock = this.croFormVersionsService.getMockProduct();
          if (!!mock) {
            this.dados.products.push(mock)
            productData = true
          }
        }
      }

      const emailValido = user ? this.validateEmail(user.email) : null;

      if(this.utils.isRedeMovel()){
        this.dados.customerOrigin = "base-movel"
      }

      // console.log('user.name', user.name)
      // console.log('user.email', user.email)
      // console.log('user.phone', user.phone)
      // console.log('user.phone.area', user.phone.area)
      // console.log('user.phone.number', user.phone.number)
      // console.log('productData', productData)
      // console.log('emailValido', emailValido)
      // console.log('this.dados.catalog', this.dados.catalog)
      // console.log('!this.cart.isConfirmacaoLead', !this.cart.isConfirmacaoLead)

      if (user &&
        user.name &&
        user.email &&
        user.phone &&
        user.phone.area &&
        user.phone.number &&
        productData &&
        emailValido &&
        this.dados.catalog &&
        !this.cart.isConfirmacaoLead
      ) {
        
        delete this.dados['__v']

        if (!this.dados?.installationAddress?.type) {
          this.dados.installationAddress.type = 'R'
        }

        const httpOptions = {
          headers: new HttpHeaders({
            'x-fingerprint': fingerprint
          }),
        }

        const dadosJwt = await this.jwtService.objectToSignedJwt(this.dados)
        const payload = {
          token: dadosJwt
        }
        this.http
          .post(environment.coberturaLeads, payload, httpOptions)
          .toPromise()
          .then((res: any) => {
            // Success

            res = res.data

            const { meta, data } =
              res.json && typeof res.json === 'function' ? res.json() : res;
            this.utils.storage.setItem('leadId', data.id);
            this.hasLead = true;
            return resolve({ meta, data });
          })
          .catch((err) => {
            // Error
            const { error } =
              err.json && typeof err.json === 'function' ? err.json() : err;
            return reject(error);
          });
      }
    });
  }

  updateBundleOrProductsOnLead() {
    const products = this.getProducts();
    if (
      this.cart.getCart() &&
      this.cart.getCart().selection &&
      (this.cart.getCart().selection.combo ||
        this.cart.getCart().selection.selecoes)
    ) {
      if (this.dados.products) {
        delete this.dados.products;
      }
      this.dados.bundle = this.getBundle(products);
    } else {
      if (this.dados) {
        delete this.dados.bundle;
        this.dados.products = products;
      }
    }
  }

  get() {
    if (this.sameAddress) {
      delete this.dados.payment.billingAddress;
    }
    return this.dados;
  }

  recovery(fingerprint: string): Promise<any> {
    const leadId =
      this.utils.getUrlParameter('leadId') ||
      this.utils.storage.getItem('leadId');
    const apiURL = `${environment.apiNewPath}leads/${leadId}`;

    this.verifyCatalog();
    const dadosDefault = {
      user: {
        name: '',
        email: '',
        phone: {
          area: undefined,
          number: undefined,
        },
      },
      catalog: this.catalog,
      originId: this.originId,
      mediaChannel: this.mediaChannel,
      salesChannel:
        this.utils.storageGetItem('salesChannel') || environment.company,
    };
    return new Promise(async (resolve, reject) => {
      if (this.dados) {
        
        const endereco = this.enderecoService.getEndereco();

        if (!!endereco?.installationZipcode && this.dados?.installationAddress) {

          this.dados.installationAddress.zipcode   = endereco.installationZipcode,
          this.dados.installationAddress.number    = Number(endereco.installationNumber),
          this.dados.installationAddress.city      = endereco.installationCity,
          this.dados.installationAddress.state     = endereco.installationState,
          this.dados.installationAddress.type      = endereco.installationType,
          this.dados.installationAddress.reference = ""
          this.dados.installationAddress.address   = endereco.installationAddress

        }

        return resolve(this.dados);
      }

      this.utils.storage.setItem('leadId', leadId);

      if (!!leadId) {

        const httpOptions = {
          headers: new HttpHeaders({
            'lead_id': leadId,
            'x-channel': 'ecommerce',
            'x-segment': 'pf',
            'x-partner': '',
            'x-customer-journey': 'prospect',
            'x-fingerprint': fingerprint
          }),
        };
        
        await this.http
          .get(environment.coberturaLeads, httpOptions)
          .toPromise()
          .then((res: any) => {

            const endereco = this.enderecoService.getEndereco();

            if (!!endereco?.installationZipcode && res?.data?.installationAddress && endereco.installationZipcode !== res?.data?.installationAddress.zipcode) {
              res.data.installationAddress.zipcode = endereco.installationZipcode,
              res.data.installationAddress.number  = endereco.installationNumber.toString(),
              res.data.installationAddress.city    = endereco.installationCity,
              res.data.installationAddress.state   = endereco.installationState,
              res.data.installationAddress.type    = endereco.installationType,
              res.data.installationAddress.address = endereco.installationAddress
            }

            const isCroTest = this.croFormVersionsService.isACroFormVersion();
            if (isCroTest) {
              const leadProducts = res.data.bundle?.products || res.data.products
              const noMockPlans = this.croFormVersionsService.getNoMockProducts(leadProducts);

              if (noMockPlans) {
                if (res.data.bundle) {
                  res.data.bundle.products = noMockPlans;
                } else {
                  res.data.products = noMockPlans;
                }
              }
            }

            const data = res;
            const dados = data as any;
            this.dados = dados.data;

            delete this.dados._id;
            delete this.dados.statuses;

            this.setDados(dados.data);

            LeadService.event.emit(this.dados);
            return resolve(this.dados);
          })
          .catch((error) => {       
            console.log(error)   
            this.dados = dadosDefault;
            LeadService.event.emit(this.dados);
            return resolve(this.dados);
          });
      } else {
        this.dados = dadosDefault;
      }
    });
  }

  setDados(dados: any) {
    const storageUser = this.utils.storage.getItem('user') === ''? '{}': this.utils.storage.getItem('user')
    this.dados.user = JSON.parse(storageUser);        
    this.dados.installationAddress = dados.installationAddress;
    this.dados.payment = dados.payment;
    this.dados.schedule = dados.schedule;

    if (dados.installationAddress && dados.installationAddress.city) {
      dados.installationAddress.city = dados.installationAddress.city
        .replace('-', ' ')
        .toUpperCase();
    }

    if (
      dados.payment &&
      dados.payment.billingAddress &&
      dados.payment.billingAddress.city
    ) {
      dados.payment.billingAddress.city = dados.payment.billingAddress.city
        .replace('-', ' ')
        .toUpperCase();
    }

    this.utils.setAccordionCatalog(dados.catalog);
  }

  clearDados(){
    let cleanData: LeadInterface
    this.dados = cleanData
  }

  getProducts() {
    const cart = this.cart.getCart();
    const products = [];
    const productsType = ['tv', 'internet', 'fone', 'celular'];

    for (const productType of productsType) {
      const productCarrinho = cart.selection && cart.selection[productType];
      const extrasSubprodutos = productType === 'tv' && this.getSubprodutos();
      const extrasAdicionais =
        productCarrinho && this.getAdicionais(productCarrinho);

      if (productCarrinho) {
        const product: any = {
          id: productCarrinho.id,
          type: productType,
          name: productCarrinho.nome,
          price: productCarrinho.preco,
          extras: [],
          deal: null,
          tags: null,
        };

        // TODO incluir adicionais
        if (productCarrinho.oferta) {
          const deal = cart.getPeriodos(
            {
              [productType]: productCarrinho
            },
            {
              ignoreDependents: true,
              ignoreAdditionals: true,
            }
          );
          product.deal = {
            description: this.utils.stripTags(productCarrinho.oferta),
            periods: deal.map(function (periodo) {
              return {
                price: parseInt(periodo.atual, 10),
                validity: periodo.ultimoMes
                  ? (parseInt(periodo.ultimoMes, 10) -
                    parseInt(periodo.mes, 10) +
                    1) *
                  30
                  : 0,
              };
            }),
          };
          if (
            !!product &&
            !!product.price &&
            !!product.deal &&
            !!(product.deal.periods.length > 0) &&
            !!product.deal.periods[product.deal.periods.length - 1].price
          ) {
            product.price =
              product.deal.periods[product.deal.periods.length - 1].price;
            if (product.deal.periods.length > 1) {
              product.deal.periods.pop();
            }
          }
        }

        if (productCarrinho.tags) {
          product.tags = productCarrinho.tags;
        }

        if (extrasAdicionais.length) {
          product.extras = extrasAdicionais;
        }

        if (extrasSubprodutos.length) {
          product.extras.push({
            id: 9999,
            name: 'TV Adicional',
            options: extrasSubprodutos,
          });
        }

        if (productCarrinho.recursos) {
          const options: Option[] = [];

          productCarrinho.recursos.forEach((recurso) => {
            if (recurso.id && recurso.nome) {
              options.push({
                id: recurso.id,
                name: recurso.descricao,
                price: 0,
                categoryId: '',
              });
            }
          });

          if (options.length > 0) {
            product.extras.push({
              id: 9998,
              name: 'Recursos',
              options,
            });
          }
        }

        if (product.extras.length === 0) {
          delete product.extras;
        }

        if (productType === 'fone' || productType === 'celular') {
          const cookiePortabilityName = `cookiePortability${productType
            .charAt(0)
            .toUpperCase()}${productType.substr(1).toLowerCase()}`;
          const cookiePortability = this.utils.storage.getItem(
            cookiePortabilityName
          );
          if (cookiePortability) {
            const dataPortability = JSON.parse(cookiePortability);
            if (
              dataPortability &&
              dataPortability.area &&
              dataPortability.number &&
              dataPortability.carrier
            ) {
              product.portability = {
                area: dataPortability.area,
                number: dataPortability.number,
                carrier: dataPortability.carrier,
              };
            }
          }
        }

        if (productType === 'celular') {
          const termAccepted = this.checkedTermoCelular
            ? this.checkedTermoCelular
            : false;

          product.dependentLines = this.cart
            .getCart()
            .dependentes.values.map((dependente) => {
              return {
                share: dependente.tipo.toLowerCase(),
                price: dependente.preco,
                portability: dependente.portabilidade,
              };
            });
          product.termAccepted = termAccepted;

          if (product.dependentLines && product.dependentLines.length === 0) {
            delete product.dependentLines;
          }
        }

        products.push(product);
      }
    }

    return products;
  }

  getAdicionais(product: any) {
    const todosAdicionaisIds = this.cart.getCart().adicionais.keys;

    const adicionaisProduto = product.adicionais;
    const adicionais = [];

    if (adicionaisProduto) {
      Object.values(adicionaisProduto).forEach(({ id, nome, opcoes }) => {
        const opcoesSelecionados = opcoes
          .filter((opt) => {
            return todosAdicionaisIds.indexOf(`${opt.id}`) >= 0;
          })
          .map((opt) => {
            if (opt.preco === 0 && opt.taxaInstalacao > 0) {
              return {
                id: opt.id,
                name: opt.nome + ' parcelado em 3x de ',
                price: opt.taxaInstalacao,
                categoryId: opt.hasOwnProperty('categoria')
                  ? opt.categoria.id.toString()
                  : '',
                payments: 3,
                sva: true,
              };
            } else {
              return {
                id: opt.id,
                name: opt.nome,
                price: opt.preco,
                categoryId: opt.hasOwnProperty('categoria')
                  ? opt.categoria.id.toString()
                  : '',
              };
            }
          });
        if (opcoesSelecionados.length) {
          adicionais.push({
            id,
            name: nome,
            options: opcoesSelecionados,
          });
        }
      });
    }
    return adicionais.length ? adicionais : [];
  }

  getSubprodutos(): SubProduct[] {
    const subprodutos = this.cart.getCart().subprodutos && this.cart.getCart().subprodutos.values;

    return Object.values(subprodutos || {}).map((subproduto: any) => {
      const subproduct: SubProduct = {
        id: subproduto.id,
        name: subproduto.nome,
        price: subproduto.periodos[subproduto.periodos.length - 1],
        categoryId: subproduto.hasOwnProperty('categoria') ? subproduto.categoria.id.toString() : '',
      };

      if (subproduto.prazo) {
        let countMonth = 0;
        let currentPrice = null;
        let firstMonth = 0;

        const deal = [];
        const periods = subproduto.periodos;

        periods.forEach((mes: number) => {
          countMonth++;
          if (currentPrice === null) {
            firstMonth = countMonth;
            deal.push({
              price: mes
            });
          } else if (mes !== currentPrice) {
            const lastPeriod = deal[deal.length - 1];
            lastPeriod.validity = countMonth - firstMonth;
            lastPeriod.nextPrice = mes;
            firstMonth = countMonth;
            deal.push({
              price: mes
            });
          }
          currentPrice = mes;
        });

        const newPeriods = deal.filter((period) => {
          if (period.validity) {
            return period;
          }
        }).map((period) => {
          return {
            price: period.price,
            validity: period.validity * 30,
          };
        });

        let newDescription = '';

        deal.forEach((perido, index) => {
          if (perido.validity) {
            const text = index === 0 ? `pague ${this.convertToLocaleString(perido.price / 100)} por ${perido.validity} ${perido.validity === 1 ? 'mês' : 'meses'}, após ${this.convertToLocaleString(perido.nextPrice / 100)}` : `por ${perido.validity} ${perido.validity === 1 ? 'mês' : 'meses'} após ${this.convertToLocaleString(perido.nextPrice / 100)}`;
            newDescription = `${newDescription === '' ? '' : `${newDescription}`} ${text}`;
          }
        });

        subproduct.deal = {
          description: `${subproduto.nome}:${newDescription}`,
          periods: newPeriods
        };
      }

      return subproduct;
    });
  }

  private convertToLocaleString(price: number): string {
    return price.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
  }

  getBundle(products: any) {
    const bundle =
      this.cart.getCart().selection.combo ||
      this.cart.getCart().selection.selecoes;
    const bundleType = this.cart.getCart().selection.combo
      ? 'combo'
      : 'selection';
    const retornoBundle: any = {
      id: 0,
      type: bundleType,
      promo: this.utils.stripTags(bundle.oferta),
      products: products,
      extras: [],
    };

    if (bundle.recursos) {
      const options: Option[] = [];

      bundle.recursos.forEach((recursoDoCombo) => {
        if (recursoDoCombo.id && recursoDoCombo.nome) {
          options.push({
            id: recursoDoCombo.id,
            name: recursoDoCombo.descricao,
            price: 0,
            categoryId: '',
          });
        }
      });

      if (options.length > 0) {
        retornoBundle.extras.push({
          id: 9998,
          name: 'Recursos',
          options,
        });
      }
    }

    if (retornoBundle.extras.length === 0) {
      delete retornoBundle.extras;
    }

    return !!bundle && retornoBundle;
  }

  validateEmail(email) {
    const er = new RegExp(
      /^[A-Za-z0-9_\-\.]+@[A-Za-z0-9_\-\.]{2,}\.[A-Za-z0-9]{2,}(\.[A-Za-z0-9])?/
    );
    const whiteSpaces = /\s/;
    if (!er.test(email) || whiteSpaces.test(email)) {
      return false;
    } else {
      return true;
    }
  }

  setCart(leadData: any) {
    const additionalIds = [];
    const subprodutosIds = [];

    this.leadProducts =
      (leadData.bundle && leadData.bundle.products) || leadData.products;

    if (this.leadProducts) {
      // Id dos produtos
      for (const key of this.leadProducts) {
        if (
          this.utils.storageGetItem(key.type + 'Id') !== key.id &&
          this.utils.storageGetItem(key.type + 'Id') !== ''
        ) {
          this.utils.storageSetItem(
            key.type + 'Id',
            this.utils.storageGetItem(key.type + 'Id')
          );
        } else {
          this.utils.storageSetItem(key.type + 'Id', key.id);
        }
        // Adicionais e Subprodutos
        if (key.extras) {
          for (const extra of key.extras) {
            if (extra.name !== 'Recursos') {
              for (const opt of extra.options) {
                if (extra.name !== 'TV Adicional') {
                  additionalIds.push(opt.id);
                } else {
                  subprodutosIds.push(opt.id);
                }
              }
              this.utils.storageSetItem(
                'additionalIds',
                additionalIds.toString()
              );
              this.utils.storageSetItem(
                'subprodutosIds',
                subprodutosIds.toString()
              );
            }
          }
        }
      }
    }
  }

  clearAddressData(): void {
    const endereco = this.enderecoService.getEndereco();
    endereco.homePass = '';
    endereco.installationAddress = '';
    endereco.installationCity = '';
    endereco.installationComplement = '';
    endereco.installationComplementOther = '';
    endereco.installationDistrict = '';
    endereco.installationLatitude = '';
    endereco.installationLongitude = '';
    endereco.installationNumber = '';
    endereco.installationReference = '';
    endereco.installationState = '';
    endereco.installationType = '';
    endereco.installationZipcode = '';
    this.fingerprintService.getFingerprint().subscribe(
      (fingerprint_: string) => {
        this.send(endereco, fingerprint_);
      },
      (error => {
        console.error(error)
      })
    )
  }
}
