import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { ReguaTransacionalOfertaPlanoMovelModel } from './regua-transacional-oferta-plano-movel.component.model';

@Injectable({
  providedIn: 'root'
})
export class ReguaTransacionalOfertaPlanoMovelService {

  constructor(
    public http: HttpClient
  ) { }

  getReguaTransacionalOfertaPlanoMovelProduct(): Observable<ReguaTransacionalOfertaPlanoMovelModel> {
    return this.http.get<ReguaTransacionalOfertaPlanoMovelModel>(
      environment.ecommerceResponsivo + 'products/reguaTransacionalOfertaPlanoMovel.json',
      { responseType: 'json' }
    );
  }
}
