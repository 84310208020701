import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Loader } from './Loader';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  showLoader: Subject<Loader> = new Subject<Loader>();
  hideLoader: Subject<Loader> = new Subject<Loader>();

  constructor() { }

  show(loader: Loader): void {
    const shouldDisplayModalLoading = loader.type === "modal-loading";
    const hasDescription = loader.description;

    if (shouldDisplayModalLoading && !hasDescription) {
      const error = "'description' must be provided in order to use modal-loading";
      this.throwAnError(error);
    }

    this.showLoader.next({
      type: loader.type,
      title: loader.title,
      description: loader.description
    });
  }

  hide(): void {
    this.hideLoader.next();
  }

  throwAnError(msg: string): void {
    throw new Error(msg);
  }
}
