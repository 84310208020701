import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { UtilsService } from 'src/app/services/utils.service';
import { MdnModalService } from '../../mondrian-components/mdn-modal/mdn-modal.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ConfiraOQueGanhouService {
  additionalComboPlanInfo: any;

  constructor(
    private http: HttpClient,
    public modal: MdnModalService,
    public utils: UtilsService,
  ) { }

  getAdditionalComboPlanInfo(): Observable<any> {
    if (this.additionalComboPlanInfo === undefined) {
      return this.http.get<any>(
        environment.ecommerceResponsivo + 'mobile-customer-identification/additional-combo-plan-info.json',
        { responseType: 'json' }
      );
    } else {
      return of(this.additionalComboPlanInfo);
    }
  }
}
