<header class="mdn-Menu mdn-Menu--public mdn-Menu--ecommerce" role="banner"
        [ngClass]="{'header-not-fixed': isWidthLessThan768}">
  <div id="skip-link" class="skip-link"
    [class.show-skip-link]="skipLinkContent">
    <div class="mdn-Container">
      <ul>
        <li>
          <a class="mdn-LinkList-anchor is-skip-link gtm-link-event"
            href="#main-content"
            onclick="event.preventDefault(); location.hash='main-content';"
            data-gtm-event-action="clique:link"
            [attr.data-gtm-event-category]="gtmCategory+':geral'"
            data-gtm-event-label="ir-para-o-corpo-do-site" tabindex="1"
            (focus)="showSkipLink()" (blur)="hideSkipLink()">Ir para o corpo do
            site</a>
        </li>
        <li>
          <a class="mdn-LinkList-anchor is-skip-link gtm-link-event"
            href="#main-header"
            onclick="event.preventDefault(); location.hash='main-header';"
            data-gtm-event-action="clique:link"
            [attr.data-gtm-event-category]="gtmCategory+':geral'"
            data-gtm-event-label="ir-para-o-cabecalho" tabindex="1"
            (focus)="showSkipLink()" (blur)="hideSkipLink()">Ir para o
            Cabeçalho</a>
        </li>
        <li>
          <a class="mdn-LinkList-anchor is-skip-link gtm-link-event"
            href="#main-footer"
            onclick="event.preventDefault(); location.hash='main-footer';"
            data-gtm-event-action="clique:link"
            [attr.data-gtm-event-category]="gtmCategory+':geral'"
            data-gtm-event-label="ir-para-o-rodape" tabindex="1"
            (focus)="showSkipLink()" (blur)="hideSkipLink()">Ir para o
            Rodapé</a>
        </li>
      </ul>
    </div>
  </div>

  <div class="mdn-Menu-top">
    <div class="mdn-Container">
      <nav class="mdn-Menu-top-content">
        <ul class="mdn-Menu-top-list mdn-Menu-top-list-left">
          <li class="mdn-Menu-top-list-item mdn-Menu-top-list-item-location" *ngIf="showAlterarEnderecoBtn"
          [class.hide]="(cepInfo | json) === '{}'">
            <button class="mdn-Menu-top-event"
                    (click)="mudarCep()"
                    title="Os preços e promoções referem-se a cidade de {{cepInfo.cidade | titlecase}}/{{cepInfo.uf}}. Se desejar click para alterar a cidade"
                    >
              <span class="mdn-Icon-localizacao mdn-Icon--md" aria-hidden="true"></span>
              <span class="mdn-Text" *ngIf="isWidthLessThan992 else textAlterarEndereco">
                {{cepInfo.cidade | titlecase}} / {{cepInfo.uf}} <span id="break-here"></span> Cep: {{cepInfo.cep}}
                <span class="mdn-Icon-seta-baixo-preenchida mdn-Icon--sm" aria-hidden="true"></span>
              </span>
              <ng-template #textAlterarEndereco>
                <span class="mdn-Text">
                  {{cepInfo.cidade | titlecase}} / {{cepInfo.uf}} - Cep: {{cepInfo.cep}} - Alterar endereço
                </span>
              </ng-template>
            </button>
          </li>
        </ul>
        <ul class="mdn-Menu-top-list mdn-Menu-top-list-right">
          <li class="mdn-Menu-top-list-item mdn-Menu-top-list-item-accessibility">
            <a href="https://claro.com.br/institucional/regulatorio/acessibilidade"
              target="_blank"
              class="mdn-Text mdn-Menu-top-action gtm-link-event"
              [attr.data-gtm-event-category]="gtmCategory+':geral'"
              data-gtm-event-action="clique:link"
              data-gtm-event-label="acessibilidade" tabindex="1"
              title="Conheça as iniciativas de Acessibilidade da Claro">
              <span class="mdn-Icon-pcd mdn-Icon--sm" aria-hidden="true"></span>
            </a>
          </li>
          <li class="mdn-Menu-top-list-item mdn-Menu-top-list-item-accessibility">
            <button type="button" class="mdn-Menu-top-event gtm-link-event" tabindex="1"
              (click)="showHandTalkAndOpenLibrasContainer(); cro.clickButtonNew('geral', 'libras', '', ':')"
              aria-label="libras" title="Assistente de Libras">
              <span class="mdn-Icon-libras mdn-Icon--sm" aria-hidden="true"></span>
            </button>
          </li>
          <li class="mdn-Menu-top-list-item mdn-Menu-top-list-item-accessibility">
            <button class="mdn-Menu-top-event mdn-Menu-top-contrast-action"
                  (click)="themeChanger(); cro.clickButtonNew('geral', 'contraste', '', ':')"
                  title="Opção de acessibilidade. Clique para alterar o contraste do Portal">
              <span class="mdn-Icon-alto-contraste mdn-Icon--sm" aria-hidden="true"></span>
            </button>
          </li>    
          <li class="mdn-Menu-top-list-item mdn-Menu-top-list-item-accessibility">
            <button class="mdn-Menu-top-event mdn-Modal--handler"
                    tabindex="1"
                    (click)="cro.openModal('acessibilidade-foco');mdnModalFontSize.open();"
                    aria-label="A mais e A menos para dar foco ou diminuir o foco"
                    title="Modal Zoom texto"
                    data-mdn-modal-id="modal-accessibility-zoom">
              <span class="mdn-Icon-diminuir-tamanho mdn-Icon--sm  mdn-Menu-text-icon" aria-hidden="true"></span>
              <span class="mdn-Icon-aumentar-tamanho mdn-Icon--sm  mdn-Menu-text-icon" aria-hidden="true"></span>
            </button>
          </li>
        </ul>
      </nav>
    </div>
  </div>

  <div class="mdn-Menu-container mdn-Container mobile">
    <nav class="mdn-Menu-content" id="main-header">
      <div class="mdn-Menu-content-left">
        <button id="logo-home" (click)="logoRedirect()"
          (keydown.enter)="logoRedirect()" title="Logo Claro"
          class="gtm-link-event logo-home" tabindex="1">
          <img class="mdn-Menu-logo"
            src="https://mondrian.claro.com.br/brands/inverse/claro.svg"
            alt="A marca Claro é escrita com letras na cor vermelha. Na letra “o” há representação de um sol, com três traços retangulares na parte superior direita, simbolizando seus raios"
            title="A marca Claro é escrita com letras na cor vermelha. Na letra “o” há representação de um sol, com três traços retangulares na parte superior direita, simbolizando seus raios"
            [attr.data-gtm-event-category]="gtmCategory+':geral'"
            data-gtm-event-action="clique:link"
            data-gtm-event-label="logo-claro">
        </button>
      </div>
      <div *ngIf="showCarrinho" class="mdn-Menu-content-right d-xl-none"
        id='esconde-Carrinho-{{utils.esconderContainerTextoLegal}}'>
        <div class="order-2">
          <div class="carrinho">
            <a href="javascript:void(0)" title="Carrinho"
              (click)="carrinho.toggleCart()"
              (keydown.enter)="carrinho.toggleCart()">
              <span class="icon icon-carrinho">
                <p class="cartText mdn-Text mdn-Text--body"
                   [class.cartTextFechar]="carrinho.statusCart"
                >
                  {{ carrinho.statusCart ? 'Fechar\n' : '' }}
                  Carrinho
                </p>
              </span>
              <span class="qtd">{{ quantidadeCarrinho }}</span>
            </a>
          </div>
        </div>
      </div>
    </nav>
  </div>

  <div id="modal-font-size" class="mdn-Modal mdn-Modal--lg">
    <div class="mdn-Modal-content">
      <div class="mdn-Modal-header">
        <button
          class="mdn-Button-header-close mdn-Button mdn-Button--icon mdn-Button--secondary gtm-link-event"
          (click)="cro.clickButtonNew(':modal-acessibilidade-foco', 'fechar')"
          aria-label="Fechar modal">
          <em class="mdn-Icon-fechar mdn-Icon--md"></em>
        </button>
      </div>
      <div class="mdn-Modal-body">
        <p class="mdn-Subtitle mdn-Subtitle--sm">
          Para aumentar ou reduzir a fonte, utilize os atalhos nativos do seu
          navegador.
        </p>
        <br />
        <p class="mdn-Subtitle mdn-Subtitle--sm">
          - No windows <b>Control+</b> (para aumentar) e <b>Control-</b> (para
          diminuir)
        </p>
        <p class="mdn-Subtitle mdn-Subtitle--sm">
          - No macOS (safari) <b>cmd+</b> (para aumentar) e <b>cmd-</b> (para
          diminuir)
        </p>
      </div>
      <div class="mdn-Modal-footer">
        <div class="mdn-Modal-footer-end">
          <button
            class="mdn-Button mdn-Button--primary mdn-Button-close mdn-Button-close gtm-link-event"
            (click)="cro.clickButtonNew(':modal-acessibilidade-foco', 'ok')">Ok</button>
        </div>
      </div>
    </div>
  </div>

  <div id="modal-target-blank-cliente"
    class="mdn-Modal mdn-Modal--sm modal-target-blank">
    <div class="mdn-Modal-content">
      <div class="mdn-Modal-header">
        <button
          class="mdn-Button-header-close mdn-Button mdn-Button--icon mdn-Button--secondary gtm-link-event"
          aria-label="Fechar modal"
          data-gtm-event-category="planos-claro-res:modal-ja-sou-cliente"
          data-gtm-event-action="clique:botao" data-gtm-event-label="fechar">
          <em class="mdn-Icon-fechar mdn-Icon--md"
            data-gtm-event-category="'planos-claro-res:modal-ja-sou-cliente'"
            data-gtm-event-action="clique:botao"
            data-gtm-event-label="fechar"></em>
        </button>
      </div>
      <div class="mdn-Modal-body">
        <p class="mdn-Subtitle mdn-Subtitle--sm">
          Você será redirecionado para {{ linkBlankTitle }}.
        </p>
      </div>
      <div class="mdn-Modal-footer">
        <div class="mdn-Modal-footer-end">
          <a [attr.href]="linkBlankHref" target="_blank"
            class="mdn-Button mdn-Button--primary mdn-Button-close gtm-link-event"
            data-gtm-event-category="planos-claro-res:modal-ja-sou-cliente"
            data-gtm-event-action="clique:botao"
            data-gtm-event-label="ok">Ok</a>
        </div>
      </div>
    </div>
  </div>

</header>
