import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { FooterComponent } from './footer.component';
import localePt from '@angular/common/locales/pt';

registerLocaleData(localePt);


@NgModule({
  declarations: [
    FooterComponent
  ],
  imports: [
    CommonModule
  ],
  providers: [{provide: LOCALE_ID, useValue: 'pt-BR' }],
  exports: [
    FooterComponent
  ]
})
export class FooterModule { }
