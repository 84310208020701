<div id="alert-root"></div>
<app-loading></app-loading>
<app-menu [ngClass]="{'black-Friday': isBlackFriday}" [showJaSouClienteBtn]="showJaSouClienteBtn"
  [showAlterarEnderecoBtn]="showAlterarEnderecoBtn" [showCarrinho]="showCarrinho"></app-menu>

<!-- Banner Vitrine -->
<div *ngIf="router.url.includes('/monte-sua-combinacao')">
  <ng-container *ngIf="this.bannersBytechnology">
    <div id="bannersBytechnology" class="mdn-Swiper mdn-Swiper--banner" data-mdn-slider-delay="5000"
      data-mdn-slider-per-view-mobile="1" data-mdn-slider-per-view-tablet="1" data-mdn-slider-per-view-small-desktop="1"
      data-mdn-slider-per-view="1" data-mdn-auto-play="true">
      <div id="swiperWrapper" class="mdn-Swiper-wrapper" [ngClass]="{
                'errorBanner': errorBanner
              }">
        <div *ngFor="let banner of this.bannersBytechnology"
          class="mdn-Banner mdn-Banner--slide mdn-Swiper-slide mdn-Banner--left mdn-Banner--transparent">
          <div class="mdn-Banner-wrapper">
            <div class="mdn-Banner-item">
              <button class="banner-button" (click)="clickBanner(banner)">
                <img id="bannerId" [src]="banner.image">
              </button>
            </div>
          </div>
        </div>
      </div>
        <!-- Bullets -->
        <div id="swiperPagination" class="mdn-Swiper-pagination"></div>
    </div>
  </ng-container>
</div>
<!-- Fim Banner Vitrine -->

<main>
  <div id="app-container" class="mdn-Container">
    <router-outlet></router-outlet>
  </div>

  <div class="mdn-Container btn-next-area area-btn-cobertura d-xl-none"
    *ngIf="router.url.includes('/monte-sua-combinacao')">
    <div id="preencher-dados" class="btn-next d-sm-block d-md-none d-lg-none d-xl-none no-padding" [ngClass]="{
        active: this.quantidadeCarrinho > 0,
        disabled: this.quantidadeCarrinho === 0,
        stickyy: isSticky,
        buttonDynamicPosition: hasCookiesHtml
      }">
      <div>
        <a (click)="continuaCompra()" (keydown.enter)="continuaCompra()" title="Próximo" class="gtm-link-event"
          [attr.data-gtm-event-category]="
            gtmCategory + ':geral:preencher-dados'
          " data-gtm-event-action="clique:finalizar" data-gtm-event-label="preencher-dados">Preencher Dados</a>
      </div>
    </div>
  </div>

  <!-- <ng-container *ngIf="shouldDisplayComponent()">
    <div class="c-push-claro-movel" id="pushClaroMovel" [style.display]="showButton ? 'none' : 'block'">
      <div class="c-push-claro-movel__wrapper" (click)="onCloseClaroMovel()">
        <span class="c-push-claro-movel__wrapper--close mdn-Icon-fechar mdn-Icon--sm"></span>
      </div>
      <img class="c-push-claro-movel__animation" src="assets\gifs\push-claro-movel.gif" alt="Claro móvel, clique aqui"
        (click)="onOpenDialog()" />
    </div>
    <button *ngIf="showButton" (click)="onOpenClaroMovel()" class="mdn-Button mdn-Button--iconText mdn-Button--primary mdn-Button--lg">
      <em class="mdn-Icon-direita mdn-Icon--md" aria-hidden="true"></em>
      <span>É cliente Claro?</span>
    </button>
  </ng-container> -->
</main>
<div id="template-footer">
  <div *ngIf="
      router.url.includes('/checkout/confirmacao') &&
      showReguaTransacionalOfertaPlanoMovel
    " id="regua-transacional-oferta-plano-movel">
    <div class="mdn-Container">
      <h2 class="mdn-Heading mdn-Heading--sm">
        Confira essa oferta especial para você!
      </h2>
      <app-regua-transacional-oferta-plano-movel></app-regua-transacional-oferta-plano-movel>
    </div>
  </div>

  <div class="mdn-Container" *ngIf="
      !router.url.includes('/checkout/confirmacao')
    ">
    <div class="mdn-Row">
      <div class="mdn-Col-xs-12">
        <app-accordion *ngIf="showDisclaimer && !isRural" [list]="accordionList" [id]="'disclaimer'"></app-accordion>
        <app-accordion *ngIf="showDisclaimer && isRural" [list]="accordionList" [id]="'disclaimer'"></app-accordion>
      </div>
    </div>
  </div>

  <app-footer [ngClass]="{'black-Friday': isBlackFriday}"></app-footer>
</div>

