import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CheckoutService } from 'src/app/modules/checkout/services/checkout.service';
import { AssineJaParserService } from 'src/app/services/assine-ja-parser.service';
import { BucketS3Service } from 'src/app/services/bucket-s3.service';
import { CroService } from 'src/app/services/cro.service';

import { ReguaTransacionalOfertaPlanoMovelModel } from './regua-transacional-oferta-plano-movel.component.model';
import { ReguaTransacionalOfertaPlanoMovelService } from './regua-transacional-oferta-plano-movel.service';

@Component({
  selector: 'app-regua-transacional-oferta-plano-movel',
  templateUrl: './regua-transacional-oferta-plano-movel.component.html',
  styleUrls: ['./regua-transacional-oferta-plano-movel.component.scss']
})
export class ReguaTransacionalOfertaPlanoMovelComponent implements OnInit, OnDestroy {

  reguaTransacionalOfertaPlanoMovel: ReguaTransacionalOfertaPlanoMovelModel = new ReguaTransacionalOfertaPlanoMovelModel();
  ctrlGetReguaTransacionalOfertaPlanoMovelProduct: Subscription;
  show: boolean = false;

  constructor(
    public assine: AssineJaParserService,
    public service: ReguaTransacionalOfertaPlanoMovelService,
    public bucketSevice: BucketS3Service,
    public checkoutService: CheckoutService,
    public cro: CroService
  ) { }

  ngOnInit(): void {
    this.ctrlGetReguaTransacionalOfertaPlanoMovelProduct = this.service.getReguaTransacionalOfertaPlanoMovelProduct().subscribe(
      (data: ReguaTransacionalOfertaPlanoMovelModel) => {
        this.reguaTransacionalOfertaPlanoMovel = data;
        this.show = true;

      },
      (error) => {
        this.show = false;
        this.checkoutService.showReguaTransacionalOfertaPlanoMovel$.next(false);
        console.error(error);
      }
    );
  }

  ngOnDestroy(): void {
    if (this.ctrlGetReguaTransacionalOfertaPlanoMovelProduct) {
      this.ctrlGetReguaTransacionalOfertaPlanoMovelProduct.unsubscribe();
    }
  }

  goToClaroMovel(): void {
    const redirectUrl = this.reguaTransacionalOfertaPlanoMovel.link;
    if (!!redirectUrl) {
      window.location.href = redirectUrl;
    }
    this.registerEvent(this.reguaTransacionalOfertaPlanoMovel.name);
  }

  registerEvent(product: string): void {
    this.cro.postDataLayer({
      event: 'event',
      eventCategory: 'planos-claro-res:confirmacao',
      eventAction: 'clique:contratar',
      eventLabel: `banner-movel:${product}`,
    })
  }

}
