<ng-container *ngIf="loadingType === 'modal-loading' && isLoaderActive">
    <div class="mdn-Modal mdn-Modal--sm mdn-isOpen">
        <div class="mdn-Modal-content">
            <div class="logo-container">
                <app-claro-loading></app-claro-loading>
            </div>
            <p class="mdn-Text mdn-Text--body">{{loadingDescription}}</p>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="loadingType === 'default' && isLoaderActive">
    <div class="loading-modal">
        <div class="loading-modal-content">
            <app-claro-loading></app-claro-loading>
            <h1 class="mdn-Heading mdn-Heading--xs">{{loadingTitle}}</h1>
            <p class="mdn-Text mdn-Text--body">{{loadingDescription}}</p>
        </div>
    </div>
</ng-container>