import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingComponent } from './loading.component';
import { ClaroLoadingComponent } from './claro-loading/claro-loading.component';

@NgModule({
  declarations: [
    LoadingComponent,
    ClaroLoadingComponent,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    LoadingComponent,
  ]
})
export class LoadingModule { }
