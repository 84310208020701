import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BucketS3Service {
  constructor(private http: HttpClient) { }

  getDisclaimer(): Observable<any> {
    return this.http.get(environment.bucketS3Disclaimer, { responseType: 'json' });
  }

  getMovelProducts(): Observable<any> {
    return this.http.get(
      environment.ecommerceResponsivo + 'products/movelProducts.json',
      { responseType: 'json' }
    );
  }

  getOttProducts(): Observable<any> {
    return this.http.get(
      environment.ecommerceResponsivo + 'products/ottProducts.json',
      { responseType: 'json' }
    );
  }

  modalAbandonoConfig(): Observable<any> {
    return this.http.get(
      environment.ecommerceResponsivo + 'feature-flag/modal-abandono.json',
      { responseType: 'json' }
    );
  }

  getPromoVitrineConfig(): Observable<object> {
    return this.http.get(
      environment.ecommerceResponsivo + 'feature-flag/promo-vitrine.json',
      { responseType: 'json' }
    );
  }

  getFeatureFlag(): Observable<object> {
    return this.http.get(
      environment.ecommerceResponsivo + 'feature-flag/featureFlag.json',
      { responseType: 'json' }
    );
  }

  getAdditionalConfig(): Observable<any> {
    return this.http.get(
      environment.ecommerceConfig + 'jornada-adicionais/configs.json'
    );
  }

  getFidelityConfig(): Observable<any> {
    return this.http.get(
      environment.ecommerceResponsivo + 'products/fidelity.json',
      { responseType: 'json' })
  }

  getLoyaltyConfig(): Observable<any> {
    return this.http.get(
      environment.ecommerceResponsivo + 'products/fidelity.json',
      { responseType: 'json' })
  }

  getPortabilityDDD(): Observable<any> {
    return this.http.get(
      environment.ecommerceResponsivo + 'products/portability_ddd.json',
      { responseType: 'json' })
  }
}
