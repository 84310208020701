import { MdnModalService } from 'src/app/shared/mondrian-components/mdn-modal/mdn-modal.service';
import { Component, HostListener, Inject, OnInit, Renderer2 } from '@angular/core';
import { CroService } from './services/cro.service';
import { BucketS3Service } from './services/bucket-s3.service';
import { NetworkService } from './services/network.service';
import { NavigationEnd, Router } from '@angular/router';
import { CarrinhoService } from './services/carrinho.service';
import { AffiliateService } from './services/affiliate-id.service';
import { CommonNavigationService } from './services/navigation.service';
import { environment } from '../environments/environment';
import { AssineJaParserService } from './services/assine-ja-parser.service';
import { DOCUMENT } from '@angular/common';
import { Subscription } from 'rxjs';
import { Disclaimer } from './models/disclaimer.model';
import { CheckoutService } from './modules/checkout/services/checkout.service';
import { filter } from 'rxjs/operators';
import { UtilsService } from './services/utils.service';
import { StoryBlockService } from './services/storyblock.service';
import { CookieService } from 'ngx-cookie-service';
import { ViabilityService } from './services/viability.service';
import swiperFn from 'mondrian-vanilla/core/components/Swiper/swiper.js';
import { MobileClientAuthenticatorComponent } from './shared/dialog/mobile-client-authenticator/mobile-client-authenticator.component';
import { MobileClientValidationService, storageNamesEnum } from './shared/dialog/mobile-client-authenticator/service/mobile-client-validation/mobile-client-validation.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title: string = 'ecommerce-responsivo';
  quantidadeCarrinho: number = 0;
  gtmCategory: string;
  mostraTextoLegal: boolean = false;
  showTextoLegalEmpresas: boolean = false;
  showButton = false;
  env: object = environment;
  public movelProducts: any = [];
  public ctrlParserPronto: Subscription;
  // ctrlShowReguaTransacionalOfertaPlanoMovel: Subscription; // TODO: Código comentado devido a história EUNCP-7090, até o momento é indeterminado o retorno dessa funcionalidade.
  public claroMovelPromoApplied: boolean = false;
  public clientAlreadyAuthenticated: boolean = false;
  accordionList: Disclaimer[];
  accordionListRural: Disclaimer[];
  showDisclaimer: boolean = false;
  showReguaTransacionalOfertaPlanoMovel: boolean = false; // TODO: Valor padrão alterado de true para false devido a história EUNCP-7090, até o momento é indeterminado o retorno dessa funcionalidade.
  public isRural: boolean = false;
  public isBlackFriday: boolean = false;
  public bannersBytechnology: any = [];
  public isBannerShowed: boolean = false;
  public errorBanner: boolean = false;

  showJaSouClienteBtn: boolean;
  showAlterarEnderecoBtn: boolean;
  showCarrinho: boolean;
  public hasCookiesHtml: boolean = false;
  public cookiesHtmlElement: any;
  public preencherDados: any;
  public pushClaroMovel: any;
  public bannerHeight: any;
  public bannerButton: any;
  public preencherDadosAltura: any;
  public swiperWrapper: any;
  public mutationObserver: MutationObserver;

  constructor(
    public assine: AssineJaParserService,
    public cro: CroService,
    public utils: UtilsService,
    public carrinho: CarrinhoService,
    public affiliateService: AffiliateService,
    public navigationService: CommonNavigationService,
    public router: Router,
    public bucketS3Service: BucketS3Service,
    public networkService: NetworkService,
    private renderer2: Renderer2,
    public modal: MdnModalService,
    public checkoutService: CheckoutService,
    public storyBlockService: StoryBlockService,
    public cookieService: CookieService,
    public mobileClientValidationService: MobileClientValidationService,
    public viabilityService: ViabilityService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.subscribeToNavigationEndEvent();
    sessionStorage.setItem('gtmCategory', environment.gtmAmbiente);
  }

  @HostListener('window:load')
  handleAfterReload() {
    this.adjustButtonPosition('reloaded');
  }

  ngOnInit(): void {
    const lastAction = sessionStorage.getItem('lastAction');
    if (lastAction === 'image') {
      this.onCloseClaroMovel();
    } else if (lastAction === 'button') {
      this.onOpenClaroMovel();
    }
    sessionStorage.setItem(
      'origemClaro',
      this.originValidate(document.referrer)
    );
    // TODO - resover essa lógica que foi mantida com estava. Não tem efetividade caso não tenha evento no view (condicional está como função no template)
    this.mobileClientValidationService.isClienteMovelChanged$.subscribe((isClienteMovel) => {
      this.clientAlreadyAuthenticated = isClienteMovel;
    });
    this.claroMovelPromoApplied = sessionStorage.getItem('claro-movel-promo-applied') === 'true';
    this.clientAlreadyAuthenticated = sessionStorage.getItem('cliente-claro-movel') === 'true';
    sessionStorage.setItem('origemClaro', this.originValidate(document.referrer))
    this.gtmCategory = sessionStorage.getItem('gtmCategory');
    this.getNetType();
    this.getFeatureFlag();
    this.getStoryBlokRegrasGerais();
    this.carrinho.atualizouCarrinho$.subscribe(() => {
      this.quantidadeCarrinho = this.getQtdCarrinho();
      this.claroMovelPromoApplied = sessionStorage.getItem('claro-movel-promo-applied') === 'true';

      if (sessionStorage.getItem('verified-mobile-customer-by-originid') === 'true') {
        this.claroMovelPromoApplied = true;
      }
    });

    // TODO: Código comentado devido a história EUNCP-7090, até o momento é indeterminado o retorno dessa funcionalidade.
    // this.ctrlShowReguaTransacionalOfertaPlanoMovel =
    //   this.checkoutService.showReguaTransacionalOfertaPlanoMovel$.subscribe(
    //     (showRegua) => {
    //       this.showReguaTransacionalOfertaPlanoMovel = showRegua;
    //     }
    //   );

    this.storyBlockService.storyBlokServiceReady$.subscribe(() => {
      const groupRulesData = this.storyBlockService.getGroupRules();

      if (groupRulesData.stories && groupRulesData.stories.length > 0 && !this.isBannerShowed) {
        this.getBannerBytechnology(groupRulesData.stories);
        this.errorBanner = false;
      } else if (!groupRulesData.stories) {
        this.errorBanner = true;
      }
      if (window.location.href?.includes('monte-sua-combinacao')) {
        swiperFn(document.getElementById('bannersBytechnology'));
      }
      this.bannerMutationController();
    })
  }

  ngAfterViewInit() {
    this.adjustButtonPosition();
  }

  bannerMutationController() {
    this.swiperWrapper = this.document.getElementById('swiperWrapper') as HTMLElement;

    this.mutationObserver = new MutationObserver((mutations) => {
      for (const mutation of mutations) {
        if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
          const targetElement = mutation.target as HTMLElement;
          if (targetElement.classList.contains('mdn-Swiper-slide-active')) {
            this.handleActiveSlideChange(targetElement);
          }
        }
      }
    });

    this.mutationObserver.observe(this.swiperWrapper, {
      attributes: true,
      childList: true,
      subtree: true,
    });
  }

  handleActiveSlideChange(activeElement: HTMLElement) {
    const slides = this.swiperWrapper.querySelectorAll('.mdn-Swiper-slide');
    slides.forEach((slide, index) => {
      if (slide === activeElement) {
        this.setPagination(index);
      }
    });
  }

  setPagination(paginationIndex: number) {
    if (window.location.href?.includes('monte-sua-combinacao')) {
      const bullets = document.getElementById('swiperPagination').querySelectorAll('.mdn-Swiper-pagination-bullet');
      bullets.forEach((bullet, bulletIndex) => {
        if (bulletIndex == paginationIndex) {
          bullet.classList.add('mdn-Swiper-pagination-bullet-active');
        } else {
          bullet.classList.remove('mdn-Swiper-pagination-bullet-active');
        }
      });
    }
  }

  adjustButtonPosition(status?: string): void {
    Promise.all([
      this.utils.checkIfDomElementExists('#onetrust-banner-sdk'),
      this.utils.checkIfDomElementExists('#preencher-dados'),
    ]).then(() => {
      this.cookiesHtmlElement = document?.querySelector('#onetrust-banner-sdk') as HTMLElement;
      this.preencherDados = document?.querySelector('#preencher-dados') as HTMLElement;
      this.pushClaroMovel = document?.querySelector('#pushClaroMovel') as HTMLElement;
      this.bannerHeight = this.cookiesHtmlElement?.getBoundingClientRect().top;
      this.preencherDadosAltura = this.preencherDados?.getBoundingClientRect().height;
      this.bannerButton = this.cookiesHtmlElement?.querySelector('#onetrust-accept-btn-handler') as HTMLElement;

      this.applyButtonPosition(status);
      this.resetButtonPosition();
    }).catch((err) => {
      console.error('Erro em Promise CTA Preencher Dados', err);
    });
  }

  applyButtonPosition(status?: string) {
    if (!!this.cookiesHtmlElement && !!this.preencherDados) {
      this.hasCookiesHtml = true;
      this.preencherDados.style.bottom = `calc(${window.innerHeight}px - ${this.bannerHeight}px)`;

      if (!!this.pushClaroMovel) {
        this.pushClaroMovel.style.bottom = `calc(${window.innerHeight}px - ${this.bannerHeight}px + ${this.preencherDadosAltura}px)`;
      }


      if (this.bannerButton) {
        this.bannerButton.addEventListener('click', () => {
          this.hasCookiesHtml = false;
          this.preencherDados.style.bottom = `0px`;

          if (!!this.pushClaroMovel) {
            this.pushClaroMovel.style.bottom = `${this.preencherDadosAltura}px`;
          }
        });
      }

      if (status === 'reloaded' || status === 'returned') {
        this.preencherDados.style.bottom = `calc(${window.innerHeight}px - ${this.bannerHeight}px)`;
      }
    }
  }

  resetButtonPosition() {
    if (this.bannerButton?.offsetParent === null) {
      this.preencherDados.style.bottom = `0px`;
      this.pushClaroMovel.style.bottom = `${this.preencherDadosAltura}px`;
    }
  }

  shouldDisplayComponent(): boolean {
    return this.router.url.includes('/monte-sua-combinacao') && !this.clientAlreadyAuthenticated && sessionStorage.getItem('cliente-claro-movel') !== 'true' && sessionStorage.getItem('outro-endereco@JaSouCliente') !== 'true';
  }

  getQtdCarrinho(): number {
    let qtd = 0;
    const products = ['internetId', 'tvId', 'celularId', 'foneId'];

    products.forEach((product) => { if (this.assine.parser?.cart[product] > 0) { qtd++; } });

    return qtd;
  }

  continuaCompra(): void {
    if (
      window.innerWidth <= 768 ||
      navigator.userAgent.toLowerCase() !== 'desktop'
    ) {
      this.carrinho.continuePurchase();
    } else {
      this.carrinho.continuePurchase();
    }
  }

  onCloseClaroMovel(): void {
    this.showButton = true;
    sessionStorage.setItem('lastAction', 'image');
  }

  onOpenClaroMovel(): void {
    this.showButton = false;
    sessionStorage.setItem('lastAction', 'button');
  }
  getNetType() {
    let cookieValue = null;
    cookieValue = this.cookieService.get('clr_hcms_asn');
    if (cookieValue) {
      cookieValue = atob(cookieValue);
      cookieValue = JSON.parse(cookieValue);
      if (cookieValue.asn && cookieValue.asn.autonomous_system_organization) {
        this.setStorageAndTagAutonomousSystemOrganization(cookieValue.asn.autonomous_system_organization)
      }
    } else {
      this.networkService.getUserIP().subscribe(
        ({ ip }: any) => {
          this.networkService.getASOFromApi(ip).subscribe(
            ({ asn }: any) => {
              this.setStorageAndTagAutonomousSystemOrganization(asn.autonomous_system_organization)
            },
            (error) => {
              console.error(error);
            }
          );
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }

  setStorageAndTagAutonomousSystemOrganization(asn: string): void {
    this.utils.storageSetItem(
      'autonomous_system_organization',
      asn
    );
    this.cro.genericGtm(
      'rede-do-cliente',
      asn,
      'planos-claro-res'
    );
  }

  onOpenDialog(): void {
    this.cro.clickButton(
      'monte-sua-combinacao',
      'push-cliente-movel',
      'clique-aqui'
    );
    const ref = this.modal.open(MobileClientAuthenticatorComponent, {
      data: {
        size: 'lg',
        disableClose: false,
        flow: 'push',
        type: 'IdentificacaoMovelComponent',
      },
    });
  }

  getTech(isRural: boolean): string {
    if (isRural) {
      return 'isRural';
    }
    const techObj = this.assine.technologiesAvailable.find((obj) => obj.name === 'Cable');
    if (techObj) {
      return techObj.gpon ? 'gpon' : 'hfc';
    }
    return 'Satellite';
  }

  getFeatureFlag() {
    this.bucketS3Service.getFeatureFlag().subscribe(
      (results: any) => {
        const featureFlag = {
          useCreditAnalysis: { isActive: results['useCreditAnalysis'] },
          useAddressTestEnvironment: {
            isActive: results['useAddressTestEnvironment'],
          },
          useAddress: { isActive: results['useAddress'] },
          useAddressHomepassed: { isActive: results['useAddressHomepassed'] },
          blockSecondMonthInFirstCalendar: {
            isActive: results['blockSecondMonthInFirstCalendar'],
          },
          showAdditionalAtCheckout: {
            isActive: results['showAdditionalAtCheckout'],
          },
          showCableCity: { isActive: results['showCableCity'] && results['showCableCity']['isEnable'] },
          showCableExpansionCity: { isActive: results['showCableExpansionCity'] },
          useTotp: { isActive: results['useTotp'] }
        };

        Object.keys(featureFlag).forEach((key) => {
          const feature = featureFlag[key];
          sessionStorage.setItem(key, feature.isActive || false);
        });
        sessionStorage.setItem(
          'showModalWhatsapp',
          JSON.stringify(results['showModalWhatsapp'])
        );
        sessionStorage.setItem(
          'topazConfig',
          JSON.stringify(results['topazConfig'])
        );
        if (featureFlag.showCableCity?.isActive) {
          this.assine.showCableCityList = results.showCableCity.list;
        }
      },
      (error) => {
        this.showDisclaimer = false;
        console.error(error);
      }
    );
  }

  public originValidate(origin: any) {
    const internet =
      /.*(net|claro).com.br\/(internet.*|wifi-plus|wifi360|ponto-ultra)/;
    const tv =
      /.*(net|claro)\.com\.br\/(tv-por-assinatura.*|now|netflix|pip-tv)/;
    const fone = /.*(net|claro)\.com\.br\/(telefone-fixo.*|fone)/;
    const celular = /.*(net|claro)\.com\.br\/(celular.*|mapa-de-cobertura)/;

    if (origin.match(internet) !== null) {
      return 'internet';
    }
    if (origin.match(tv) !== null) {
      return 'tv';
    }
    if (origin.match(fone) !== null) {
      return 'fone';
    }
    if (origin.match(celular) !== null) {
      return 'celular';
    }
  }

  subscribeToNavigationEndEvent() {
    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.showJaSouClienteBtn = this.utils.resolveShowJaSouClienteBtn(
          event.url
        );
        this.showAlterarEnderecoBtn = this.utils.resolveShowAlterarEndereco(
          event.url
        );
        this.showCarrinho = this.utils.resolveShowCarrinho(event.url);

        this.adjustButtonPosition('returned');
      });
  }

  getStoryBlokRegrasGerais(): any {
    let isRural = false;
    if (window.location.href?.includes('landingrural') || sessionStorage.getItem('salesChannel')?.includes('landingrural')) {
      isRural = true;
    }

    sessionStorage.setItem('isRural', isRural.toString());
    this.storyBlockService.getStoryBlokRegrasGerais().subscribe(
      (data: any) => {
        let redirectToApp
        // Recover App Ids
        if (data?.stories[0].content.porteira) {
          this.storyBlockService.setAppIds(data?.stories[0].content.porteira[0].app_tv_ids);
          this.storyBlockService.setTvIdGroup(data?.stories[0].content.porteira[0].app_tv_ids_group);
          redirectToApp = this.utils.checkIfTvAppSingle(data?.stories[0].content.porteira[0].app_tv_ids);
        } 

        if (!redirectToApp) {
          this.utils.redirectIfZipCodeOrNumberIsEmpty();
        }

        // Recover AffiliateId
        if (data?.stories[0].content.affiliateId) {
          this.affiliateService.affiliateIdsToHidePortability = data?.stories[0].content.affiliateId;
          this.affiliateService.affiliateIdsToHideTrash = data?.stories[0].content.affiliateId;
        }

        // Recover and Set BlackFriday
        this.isBlackFriday = data?.stories[0].content.blackfriday[0].activated;
        this.storyBlockService.setBlackFriday(this.isBlackFriday);

        // Recover Disclaimer Texts
        if (data?.stories[0].content) {
          this.showDisclaimer = true;
          const tech = this.getTech(isRural);
          const techToLegalTextMap = {
            'hfc': data.stories[0].content.textoLegalHfc[0],
            'gpon': data.stories[0].content.textoLegalGpon[0],
            'Satellite': data.stories[0].content.textoLegalSatelite[0],
            'isRural': data.stories[0].content.textoLegalRural[0],
          };
          this.accordionList = Object.keys(techToLegalTextMap[tech])
            .filter(key => key.startsWith('text_'))
            .map((key) => {
              const titleKey = `title_${key.split('_')[1]}`;
              return {
                title: techToLegalTextMap[tech][titleKey],
                text: techToLegalTextMap[tech][key]
              };
            });
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  getBannerBytechnology(groupsRules: any) {
    const currentTechnology = this.viabilityService.getAddressTechnology();
    const data = groupsRules[0].content[currentTechnology];
    this.isBannerShowed = true;

    if (!data) {
      return
    }

    if (window.innerWidth <= 819) {
      data.forEach(banner => {
        this.bannersBytechnology.push({
          link: banner.link_promocao,
          image: banner.imagem_mobile.filename,
          promocao: banner.promocao,
          authRequired: banner.authRequired // if true renderiza o modal de auth do push
        });
      });
    } else {
      data.forEach(banner => {
        this.bannersBytechnology.push({
          link: banner.link_promocao,
          image: banner.imagem_desktop.filename,
          promocao: banner.promocao,
          authRequired: banner.authRequired
        });
      });
    }
  }

  clickBanner(banner: any) {
    const currentTechnology = this.viabilityService.getAddressTechnology();
    const eventLabel = 'banner:' + currentTechnology + ':' + banner.promocao;
    this.cro.genericGtm('clique-botao', eventLabel, 'monte-sua-combinacao');
    const storageKeys = ['internetId', 'tvId', 'foneId', 'celularId'];


    if (!banner.authRequired) {
      storageKeys.forEach(key => {
        this.utils.storageRemoveItem(key)
      });
      window.location.href = banner.link;
    } else {
      this.onOpenDialog()
    }
  }
}
