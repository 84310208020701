import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Drawer2Component } from './drawer2.component';
import { Drawer2Directive } from './drawer2.directive';
import { Drawer2Service } from './drawer2.service';


@NgModule({
  declarations: [
    Drawer2Component,
    Drawer2Directive
  ],
  imports: [
    CommonModule
  ],
  exports: [
    Drawer2Component
  ],
  providers: [
    Drawer2Service
  ]
})

export class Drawer2Module { }
